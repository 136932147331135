// outlined.js
import chroma from 'chroma-js';
import { brushDefaults } from './helpers/brushLoader';
import { drawPressureStroke } from './helpers/pressure';
import { drawStrokeWithGradient } from './marker';

const defaultBrushSettings = brushDefaults.outlined || {};

export async function drawOutlinedStroke(stroke, context, params) {
  const {
    points,
    lineWidth,
    color,
    gradientColor,
    sets = {},
  } = stroke;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  const {
    outlineSize = 0,
    outlineColor = 'black',
    outlineOpacity = 1,
  } = brushSettings;

  // Создаем буферный канвас
  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  bufferCtx.globalAlpha = outlineOpacity;

  // Рисуем обводку увеличенного размера на буферном канвасе
  drawPressureStroke({
    points,
    lineWidth: lineWidth,
    increaseLineWidth: outlineSize * 2,
    color: outlineColor,
    gradientColor: null,
  }, bufferCtx, brushSettings);

  bufferCtx.globalAlpha = 1;

  // Устанавливаем режим композиции для вычитания внутренней части
  bufferCtx.globalCompositeOperation = 'destination-out';
  // Рисуем внутреннюю часть, чтобы вычесть ее из обводки
  drawPressureStroke({
    points,
    lineWidth: lineWidth - 0.7,
    color: 'black',
    gradientColor,
  }, bufferCtx, brushSettings);

  // Сбрасываем режим композиции
  bufferCtx.globalCompositeOperation = 'source-over';

  // Копируем буферный канвас на основной контекст
  context.drawImage(bufferCanvas, 0, 0);

  // Рисуем основной штрих с нужным цветом или градиентом
  drawStrokeWithGradient(stroke, context, brushSettings);
}
