
// saveDrawing.js

const threshold = 2000;
let sendTimer = null;
let lastCallTime = 0;

export const saveDrawing = async ({
  socket, 
  gameId, 
  telegramUserId,
  drawingMode,
  userId, 
  moderId,
  strokes, 
  colors, 
  side,
  dimensions,
  orientation,
  connectionStatusRef,
  forced,
}) => {

  if (!socket) {return;}

  clearTimeout(sendTimer);
  const needToWait = lastCallTime + threshold - Date.now();

  if (!forced && needToWait > 0) { await new Promise(resolve=>{sendTimer = setTimeout(resolve, needToWait)}); }
  lastCallTime = Date.now();

  try {

    const initTime = Date.now();
    const strokesToSend = strokes.slice(-100);

    socket.emit('strokes', { gameId, drawingMode, userId: telegramUserId, }, { 
      side, 
      dimensions, 
      orientation, 
      strokes: strokesToSend, 
      length: strokes.length, 
      userId, 
      moderId,
      colors,
      }, (result)=>{
      connectionStatusRef.current.lastSaveTime = Date.now();
    });

    setTimeout(() => {
      if (initTime > connectionStatusRef.current.lastSaveTime) {
        connectionStatusRef.current.lastConnectionErrorTime = Date.now();
      }
    }, 2000);

    console.log("Sending strokes: ", strokes.length); // Для отладки

  } catch (e) {

    connectionStatusRef.current.lastConnectionErrorTime = Date.now();
    console.error("Failed to send canvas data to server:", strokes); // Для отладки
    
  }
};