// filler.js
import { brushDefaults } from './helpers/brushLoader';
const defaultBrushSettings = brushDefaults.filler;

// ScanFill
export function createFill(
  stroke,
  context, 
  imageCache,
) {

  const { 
    sets = {}
  } = stroke;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  const { tolerance = 0, antialiasing = 0.5, eatEdges } = brushSettings;

  let startX = stroke.x;
  let startY = stroke.y;
  let fillColor = stroke.color;

  startX = Math.round(startX);
  startY = Math.round(startY);

  const canvas = context.canvas;
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  const width = imageData.width;
  const height = imageData.height;

  const fillColorRgb = hexToRGBA(fillColor);
  let edgeFillColor = makeTransparentColor(fillColorRgb, 0.95);

  const targetColor = getColorAtPixel(imageData, startX, startY);
  let newColor = blendColors(targetColor, fillColorRgb); ;

  if (fillColorRgb.a === 0) { 
    newColor = fillColorRgb;
    edgeFillColor = fillColorRgb;
  }

  if (colorsMatch(targetColor, newColor, 0)) {
    return;
  }

  const fillHistory = new Set();
  const edgeHistory = new Set();

  function scanlineFill(x, y) {
    let leftX = x;
    let rightX = x;
    const currentY = y;

    // Сканируем влево
    while (leftX >= 0 && colorsMatch(getColorAtPixel(imageData, leftX, currentY), targetColor, tolerance)) {
      leftX--;
    }
    leftX++;

    // Сканируем вправо
    while (rightX < width && colorsMatch(getColorAtPixel(imageData, rightX, currentY), targetColor, tolerance)) {
      rightX++;
    }
    rightX--;

    // Заполняем линию
    for (let i = leftX; i <= rightX; i++) {
      const currentPos = (currentY * width + i) * 4;
      if (!fillHistory.has(currentPos)) {
        colorPixel(imageData, currentPos, newColor);
        fillHistory.add(currentPos);
      }
    }

    // Проверяем и обрабатываем границы
    function checkAndFillEdge(x, y) {
      if (x < 0 || x >= width || y < 0 || y >= height) return;
      const pos = (y * width + x) * 4;
      if (!fillHistory.has(pos) && !edgeHistory.has(pos)) {
        const pixelColor = getColorAtPixel(imageData, x, y);
        if (!colorsMatch(pixelColor, targetColor, tolerance)) {

          if (eatEdges) {

            const newEdgeColor = blendColors(pixelColor, edgeFillColor);
            colorPixel(imageData, pos, newEdgeColor);
            edgeHistory.add(pos);

          } else {

            const difference = colorDifference(pixelColor, targetColor);

            // Вычисляем коэффициент смешивания
            const blendFactor = (255 - difference / antialiasing) / 255;
            const clampedBlendFactor = Math.max(0, Math.min(1, blendFactor));
    
            // Смешиваем цвет заливки с цветом пикселя
            const newEdgeColor = blendColorsWithFactor(pixelColor, fillColorRgb, clampedBlendFactor);
            colorPixel(imageData, pos, newEdgeColor);
            edgeHistory.add(pos);

          }




        }
      }
    }

    // Проверяем границы текущей линии
    checkAndFillEdge(leftX - 1, currentY);
    checkAndFillEdge(rightX + 1, currentY);

    // Проверяем строки выше и ниже
    function checkAdjacentLine(adjacentY) {
      if (adjacentY < 0 || adjacentY >= height) return;

      for (let i = leftX; i <= rightX; i++) {
        const adjacentPos = (adjacentY * width + i) * 4;
        if (!fillHistory.has(adjacentPos) && !edgeHistory.has(adjacentPos)) {
          const pixelColor = getColorAtPixel(imageData, i, adjacentY);
          if (colorsMatch(pixelColor, targetColor, tolerance)) {
            scanlineFill(i, adjacentY);
          } else {
            checkAndFillEdge(i, adjacentY);
          }
        }
      }
    }

    checkAdjacentLine(currentY - 1);
    checkAdjacentLine(currentY + 1);
  }

  scanlineFill(startX, startY);
  imageCache.current.set(stroke.time, imageData);
}


function getColorAtPixel(imageData, x, y) {
  const offset = (y * imageData.width + x) * 4;
  return {
    r: imageData.data[offset],
    g: imageData.data[offset + 1],
    b: imageData.data[offset + 2],
    a: imageData.data[offset + 3]
  };
}

function colorDifference(color1, color2) {
  const rDiff = Math.abs(color1.r - color2.r);
  const gDiff = Math.abs(color1.g - color2.g);
  const bDiff = Math.abs(color1.b - color2.b);
  const aDiff = Math.abs(color1.a - color2.a);
  
  return Math.max(rDiff, gDiff, bDiff, aDiff);
}

function colorsMatch(a, b, tolerance = 0) {
  const maxDifference = 255 * (tolerance / 100) * 0.5;
  return colorDifference(a, b) <= maxDifference;
}

// function colorsMatch(a, b) {
//   return a.r === b.r 
//   && a.g === b.g 
//   && a.b === b.b 
//   && a.a === b.a;
// }

function hexToRGBA(hex) {
  let r = 0, g = 0, b = 0, a = 255;
  if (hex.length === 7) {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
  } else if (hex.length === 9) {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
    a = parseInt(hex.slice(7, 9), 16);
  }
  return { r, g, b, a };
}


function colorPixel(imageData, offset, color) {
  imageData.data[offset] = color.r;
  imageData.data[offset + 1] = color.g;
  imageData.data[offset + 2] = color.b;
  imageData.data[offset + 3] = color.a !== undefined ? color.a : 255;
  // imageData.data[offset + 3] = 255;
}


function blendColors(baseColor, overlayColor) {
  // Преобразуем альфа-каналы из диапазона 0-255 в 0-1
  const baseA = (baseColor.a !== undefined ? baseColor.a : 255) / 255;
  const overlayA = (overlayColor.a !== undefined ? overlayColor.a : 255) / 255;

  // Вычисляем результирующую альфу
  const outA = overlayA + baseA * (1 - overlayA);

  // Проверяем, чтобы не было деления на ноль
  if (outA === 0) {
    return { r: 0, g: 0, b: 0, a: 0 };
  }

  // Вычисляем новые значения RGB с учетом альфа-канала
  const outR = (overlayColor.r * overlayA + baseColor.r * baseA * (1 - overlayA)) / outA;
  const outG = (overlayColor.g * overlayA + baseColor.g * baseA * (1 - overlayA)) / outA;
  const outB = (overlayColor.b * overlayA + baseColor.b * baseA * (1 - overlayA)) / outA;

  // Возвращаем итоговый цвет
  return {
    r: Math.round(outR),
    g: Math.round(outG),
    b: Math.round(outB),
    a: Math.round(outA * 255),
  };
}


function makeTransparentColor(baseColor, opacity = 0.5) {
  return {
    r: baseColor.r,
    g: baseColor.g,
    b: baseColor.b,
    a: Math.round(baseColor.a * opacity)  
  };
}



function blendColorsWithFactor (baseColor, overlayColor, blendFactor = 1) {
  const baseA = (baseColor.a !== undefined ? baseColor.a : 255) / 255;
  const overlayA = ((overlayColor.a !== undefined ? overlayColor.a : 255) / 255) * blendFactor;

  const outA = overlayA + baseA * (1 - overlayA);

  if (outA === 0) {
    return { r: 0, g: 0, b: 0, a: 0 };
  }

  const outR = (overlayColor.r * overlayA + baseColor.r * baseA * (1 - overlayA)) / outA;
  const outG = (overlayColor.g * overlayA + baseColor.g * baseA * (1 - overlayA)) / outA;
  const outB = (overlayColor.b * overlayA + baseColor.b * baseA * (1 - overlayA)) / outA;

  return {
    r: Math.round(outR),
    g: Math.round(outG),
    b: Math.round(outB),
    a: Math.round(outA * 255),
  };
}
