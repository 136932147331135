// index.js (пример использования)
import { ShapeRecognizer } from './shapeRecognizer.js';
import { ShapeSimplifier } from './shapeSimplifier.js';
import { MathUtils } from './mathUtils.js';

export function processPoints(points) {
  // 1) Распознаем фигуру
  const recognizer = new ShapeRecognizer(points);
  const recognizedShape = recognizer.recognize();

  // 2) Упрощаем (выравниваем) фигуру
  const simplifiedPoints = ShapeSimplifier.simplify(recognizedShape);

  if (simplifiedPoints?.[0].pressure && recognizedShape.type !== 'none') {
    simplifiedPoints.forEach(point=>{
      delete point.pressure;
      // point.pressure = simplifiedPoints?.[0].pressure
    });
  }

  const figureCenter = recognizedShape.type === 'line' ? points[0] : MathUtils.boundingCenter(simplifiedPoints);

  return {
    points: simplifiedPoints,
    center: figureCenter,
  };
}
