// OpacityControl.js
import React, { useState, useRef, useEffect } from 'react';
import chroma from 'chroma-js';
import CircleSwitch from "./CircleSwitch/CircleSwitch.js";
import ColorIndicator from './ColorIndicator'; 
import { filterPosition } from './Throttler/filterPosition.js'; // путь к вашему хуку

const OpacityControl = ({ 
  canvasContext,
  brushContext,
  drawMethods,

  icon,
  available,
  onContextMenu,
 }) => {

    const {
      color, 
      gradientColor,
      gradientPaletteRef,

      setTheColorFun, 
      activeTool, 
      isChangingOpacity, setIsChangingOpacity,

    } = brushContext;

    const {
      changeTool, 
    } = drawMethods;

    const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 }); 
    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 }); 
    const hasMoved = useRef(false);

    function ensure8DigitHex(inputColor) {
      if (typeof inputColor !== 'string') {
        return '#000000FF'; // fallback
      }
    
      const color = inputColor.trim();
    
      // Если начинается с '#', проверим длину сразу
      if (color.startsWith('#')) {
        const hexStr = color.slice(1);
        if (/^[0-9A-Fa-f]{8}$/.test(hexStr)) {
          return '#' + hexStr.toUpperCase(); 
        }
        if (/^[0-9A-Fa-f]{6}$/.test(hexStr)) {
          return '#' + hexStr.toUpperCase() + 'FF';
        }
      }
    
      try {
        const c = chroma(color);
        // alpha => целое 0..255
        const alpha8 = Math.round(c.alpha() * 255)
          .toString(16)
          .padStart(2, '0')
          .toUpperCase();
        const hexNoAlpha = c.hex().slice(1).toUpperCase();
        return `#${hexNoAlpha}${alpha8}`;
    
      } catch (err) {
        // Некорректный цвет => fallback
        return '#000000FF';
      }
    }

    const startChangingOpacity = (event) => {

        if (event?.button === 2) { return; }

        setIsChangingOpacity(true);

        const x = event.clientX || event.touches[0]?.clientX;
        const y = event.clientY || event.touches[0]?.clientY;

        setClickPosition({ x, y }); // Обновление позиции кружка
        setPointerPosition({ x, y }); // Обновление позиции кружка

        hasMoved.startTime = Date.now();
        hasMoved.startTouchPoint = event?.touches?.[0] || event;
    };

    useEffect(() => {


        const handlePointerMove = (event) => {
          const workColorRaw  = gradientPaletteRef.current ? gradientColor : color;
          
          if (event?.button === 2) { return; }
          if (!isChangingOpacity) return;
      
          let position = {
              x: event.clientX || event.touches[0]?.clientX,
              y: event.clientY || event.touches[0]?.clientY,
          }
      
          const filteredPosition = filterPosition(position, 'pointerPosition', 100)
          let diffY = pointerPosition.y - filteredPosition.y;
          setPointerPosition(filteredPosition);
          
          if (diffY === 0) return;

          let currentHexColor = ensure8DigitHex(workColorRaw);
      
          // Извлекаем альфа канал напрямую из hex строки
          const alphaHex = currentHexColor.slice(-2);  // Берём последние 2 символа hex строки
          const currentAlphaHex = parseInt(alphaHex, 16);  // Преобразуем в число
          
          // Изменяем hex-значение на основе движения
          let newAlphaHex = currentAlphaHex - Math.round(diffY);
          
          // Ограничиваем значения от 0 до 255
          newAlphaHex = Math.max(0, Math.min(255, newAlphaHex));
          
          // Формируем новый цвет, сохраняя оригинальный RGB и заменяя только альфу
          const baseColor = currentHexColor.slice(0, -2);  // Берём цвет без альфа канала
          const newAlphaHexStr = newAlphaHex.toString(16).padStart(2, '0');  // Преобразуем число обратно в hex
          const newColor = baseColor + newAlphaHexStr;
      
          setTheColorFun(newColor);
          hasMoved.current = true;
        };


        const handlePointerUp = (event) => {

            if (event?.button === 2) { return; }

            setIsChangingOpacity(false);
            if (hasMoved.startTouchPoint && Date.now() - hasMoved.startTime < 350) {
              changeTool("effect")
            }
        };

        if (isChangingOpacity) {
            document.addEventListener('mousemove', handlePointerMove);
            document.addEventListener('mouseup', handlePointerUp);
            document.addEventListener('touchmove', handlePointerMove);
            document.addEventListener('touchend', handlePointerUp);
        }

        return () => {
            document.removeEventListener('mousemove', handlePointerMove);
            document.removeEventListener('mouseup', handlePointerUp);
            document.removeEventListener('touchmove', handlePointerMove);
            document.removeEventListener('touchend', handlePointerUp);
        };
    }, [isChangingOpacity, color, gradientColor, setTheColorFun, changeTool]);

    const theAlpha = chroma(color).alpha();
    let alpha = `${Math.round(theAlpha * 100)}%`
    if (theAlpha <= 0.1) {alpha += ` (${ensure8DigitHex(color).slice(-2)})`}

    return (
      <div>
        <div
            onContextMenu={onContextMenu}
            onMouseDown={startChangingOpacity}
            onTouchStart={startChangingOpacity}
            style={{
                cursor: 'pointer', 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '50px',
                height: '50px',
            }}
        >
            <CircleSwitch
									icon={icon}
                  available={available}
									isActive={activeTool === "effect"}
                  menu={true}
								/>
        </div>
        {isChangingOpacity && (
               <ColorIndicator
                canvasContext={canvasContext}
                brushContext={brushContext}
                position={pointerPosition}
                clickPosition={clickPosition}
                alpha={alpha}
             />
            )}
      </div>
    );
};

export default OpacityControl;