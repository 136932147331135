// blur.js
import { seededRandom } from './pencil'; // Reusing the seededRandom function
import { applyPixiBlur } from './pixi/pixiBlur'; // Reusing the seededRandom function

export async function drawBlurStroke({
  lineWidth,
  points,
  time,
}, context, {
  isApple,
}) {


  if (points.length === 0) return;

  const levels = isApple ? 1 : 4;
  for (let level = 1; level <= levels; level++)  {
    // Create a mask for the stroke
    const maskCanvas = document.createElement('canvas');
    maskCanvas.width = context.canvas.width;
    maskCanvas.height = context.canvas.height;
    const maskCtx = maskCanvas.getContext('2d');

    // Draw the stroke path
    // const currentLineWidth = lineWidth/levels * level 
    const currentLineWidth = lineWidth * (4/5) + (lineWidth/levels * level) * (1/5) 
    drawStroke(maskCtx, points, currentLineWidth);

    const bufferCanvas = document.createElement('canvas');
    bufferCanvas.width = context.canvas.width;
    bufferCanvas.height = context.canvas.height;
    const bufferCtx = bufferCanvas.getContext('2d');
  
    // Copy the original canvas content to the buffer
    bufferCtx.drawImage(context.canvas, 0, 0);
    await applyBlurAlongPath(bufferCtx, maskCanvas, lineWidth, level, isApple);

    // Draw the result on the main canvas
    context.drawImage(bufferCanvas, 0, 0);
  }
}


function drawStroke (context, points, lineWidth) {

  lineWidth = lineWidth * 0.8;

  context.lineCap = 'round';
  context.lineJoin = 'round';
  context.lineWidth = lineWidth;

  const blurSize = lineWidth * 0.5;
  context.filter = `blur(${blurSize}px)`;

  if (points.length === 1) {

    let point = points[0];
    if (Array.isArray(point)) {} else {point = [point.x, point.y]}
        
    // Для одиночной точки рисуем круг
    context.beginPath();
    context.arc(
      point.x, 
      point.y, 
      lineWidth / 2, 
      0, 
      Math.PI * 2
      );
    context.fill();

  } else {
  
    context.beginPath();
    context.moveTo(points[0].x, points[0].y);

    for (let i = 1; i < points.length; i++) {
      const nextPoint = points[i - 1];
      const currentPoint = points[i];
      const midPoint = [
        (nextPoint.x + currentPoint.x) / 2,
        (nextPoint.y + currentPoint.y) / 2
      ];
      context.quadraticCurveTo(nextPoint.x, nextPoint.y, midPoint.x, midPoint.y);
    }
    const lastPoint = points[points.length - 1]
    context.lineTo(
      lastPoint.x, 
      lastPoint.y,
      );
    context.stroke();

  }
  
}


async function applyBlurAlongPath(context, maskCanvas, lineWidth, level, isApple) {

  const maxBlurSize = Math.min(2, Math.max(0.5, lineWidth / 30));
  const blurSize = maxBlurSize / level;

  const bluredCanvas = document.createElement('canvas');
  bluredCanvas.width = context.canvas.width;
  bluredCanvas.height = context.canvas.height;
  const bluredCtx = bluredCanvas.getContext('2d');

  if (isApple) {
    bluredCtx.drawImage(context.canvas, 0, 0);
    await applyPixiBlur(bluredCtx.canvas, blurSize * 2)
  } else {
    bluredCtx.save();
    bluredCtx.filter = `blur(${blurSize}px)`;
    bluredCtx.globalAlpha = 1;
    bluredCtx.drawImage(context.canvas, 0, 0);
    bluredCtx.restore();
  }

  // Apply the blur using the mask
  context.save();
  context.globalCompositeOperation = 'source-over';
  context.drawImage(bluredCanvas, 0, 0);
  context.globalCompositeOperation = 'destination-in';
  context.drawImage(maskCanvas, 0, 0);
  context.restore();
}


