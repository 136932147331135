// ColorIndicator.js
import React from 'react';
import chroma from 'chroma-js';


const ColorIndicator = ({ 
  canvasContext,
  brushContext,

  position, 
  clickPosition = {x: 1000, y: 1000}, 
  maxVisibleLineWidth = 60,
  minVisibleLineWidth = 60,

  alpha,
  brightness,
 }) => {

    const {
      controlPanelOffsetRef,
    } = canvasContext;


    const {
      actualSoftnessFun,
      color, 
      visibleLineWidth,

      brushIndicatorFrame,
    } = brushContext;

    if (!position) {
        return null; // Не отображаем компонент, если позиция не задана
    }

    const indicatorSideWidth = Math.min(Math.max(visibleLineWidth, minVisibleLineWidth), maxVisibleLineWidth);
    const blurSize = `${actualSoftnessFun() * indicatorSideWidth}px`; // Пример коэффициента умножения на 3

    const maxBottomPosition = (controlPanelOffsetRef.current || 430) + 45;

    const indicatorText = alpha ?? brightness ?? '';

    return (
        <div
            style={{
                position: 'fixed',
                left: `${position.x - indicatorSideWidth/2}px`,
                top: `${Math.min(maxBottomPosition, position.y) - 110}px`,
                width: indicatorSideWidth,
                height: indicatorSideWidth,
                zIndex: 200,
            }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  background: color,
                  pointerEvents: 'none', // Исключает элемент из событий указателя
                  boxShadow: actualSoftnessFun() ? 0 : brushIndicatorFrame,
                  filter: `blur(${blurSize})`, 
              }}/>
              <div style={{
                  zIndex: 55, 
                  color: 'grey',
                  fontSize: '14px',
                  opacity: 0.9,
                  transform: `translate(${indicatorSideWidth + 3}px, ${- indicatorSideWidth/2 - 10}px)`
              }}>
               {indicatorText}
              </div>
        </div>
    );
};

export default ColorIndicator;