

const interfaceOffset = ({
  canvasContext,
})=>{

  const {
    // Refs
    canvasRef,
    zoomFactor,
    canvasRectRef,
    currentCanvasRectRef,
    controlPanelOffsetRef,
    controlPanelStuckRef,

    visibleSizeRef,
    

  } = canvasContext;

  let controlButtonsStyle = { bottom: 0, opacity: 0 };
  let drawingAreaStyle = { 
    width: `${visibleSizeRef.current}px`,
    height: `${Math.max(visibleSizeRef.current * 1.35, window.innerHeight)}px`,
    marginTop: 0,
   };


  if (canvasRef.current && canvasRectRef.current) {

    const isHorizontal = window.innerWidth > window.innerHeight;
    const isFullscreen = window.Telegram?.WebApp?.isFullscreen;

    const horizontalFullscreen = isFullscreen && isHorizontal; 
    const verticalFullscreen = isFullscreen && !isHorizontal; 

    const smallHeight = window.innerHeight < 600
    const distanceFromBottom = smallHeight ? 145 : 
    verticalFullscreen ? 260 : 180;

    // const fullScreenRectCorrection = 0;
    const distanceFromRect = 
    verticalFullscreen ? - window.Telegram?.WebApp?.contentSafeAreaInset?.top - 30 :
    horizontalFullscreen ? - window.Telegram?.WebApp?.contentSafeAreaInset?.top + 25 : 15

    const rect = canvasRef.current.getBoundingClientRect();
    currentCanvasRectRef.current = rect;

    // PANEL STYLE
    if (zoomFactor <= 1) {
      controlPanelStuckRef.current = false;
    } else if (window.innerHeight - rect.bottom < 220) {
      controlPanelStuckRef.current = true;
    }
    

    if (controlPanelStuckRef.current) {
      controlButtonsStyle = {
        top: `${window.innerHeight - distanceFromBottom}px`,
      }
    } else {
      controlPanelOffsetRef.current = Math.min(rect.bottom + distanceFromRect, window.innerHeight - distanceFromBottom);
      controlButtonsStyle = {
        top: `${controlPanelOffsetRef.current}px`,
      }
    }

    // DRAWING ARIA STYLE
    if (isFullscreen) {
      if (isHorizontal) {
        drawingAreaStyle.marginTop = window.Telegram?.WebApp?.contentSafeAreaInset.top / 2
      } else {
        drawingAreaStyle.marginTop = window.Telegram?.WebApp?.contentSafeAreaInset.top + 40
      }

    } else {
      drawingAreaStyle.marginTop = 0
    }


  }

  return { controlButtonsStyle, drawingAreaStyle };

} 

export default interfaceOffset