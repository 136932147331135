import chroma from 'chroma-js';
import { calculateGradientPoints } from './gradient'; 

export function createFade (stroke, context, brushSettings) {

  context.globalCompositeOperation = 'source-in';

  const { 
    color,
    gradientColor,
    points,
  } = stroke;

  const {
    opacityOnStart,
    opacityOnEnd,
    fadeStartPoint,
    fadeEndPoint,
  } = brushSettings;

  const smoothPoints = points;
  const totalPoints = smoothPoints.length;

  const chromaStartColor = chroma(color);
  const chromaEndColor = chroma(gradientColor || color);

  const originalStartColorlAlpha = chromaStartColor.alpha();
  const originalEndColorlAlpha = chromaEndColor.alpha();

  const colorOnStart = chromaStartColor.alpha(opacityOnStart * originalStartColorlAlpha).hex();
  const colorOnEnd = chromaEndColor.alpha(opacityOnEnd * originalEndColorlAlpha).hex();

  const gradientPoints = calculateGradientPoints(smoothPoints[0], smoothPoints[totalPoints-1]);

  const gradient = context.createLinearGradient(
    gradientPoints.start.x, gradientPoints.start.y,
    gradientPoints.end.x, gradientPoints.end.y
    );
    
  gradient.addColorStop(0, colorOnStart);
  gradient.addColorStop(fadeStartPoint, color);
  gradient.addColorStop(fadeEndPoint, gradientColor || color);
  gradient.addColorStop(1, colorOnEnd);
  context.fillStyle = gradient;
  context.fillRect(0, 0, context.canvas.width, context.canvas.height);
  
}