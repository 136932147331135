// useZoomPC
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDrawingContext  } from '../../contexts/DrawingContext';

export const useZoomPc = ({
  getCanvasPoint,
}) => {

  const canvasContext = useDrawingContext();
  const {
    canvasSideRef,
    canvasDimensionsRef,
    canvasScaleRef,

    zoomFactor, setZoomFactor,
    panOffset, setPanOffset,

    zoomFactorRef,
    panOffsetRef,

    maxZoomFactorRef,
  
    mousePositionRef,
    mouseIsPressedRef,


  } = canvasContext;

  const initialOffsetRef = useRef({x: 0, y: 0});
  const movingStartPointRef = useRef(null);


  const changeZoomPc = useCallback((delta, newZoomFactor) => {

    // const newZoomFactor = 2; 

    let coefficient = zoomFactorRef.current * 0.1 + 0.25;
    const changeAmount = (delta > 0 ? -coefficient : coefficient);

    newZoomFactor = newZoomFactor || zoomFactorRef.current + changeAmount; 
    newZoomFactor = Math.max(newZoomFactor, 1); 
    newZoomFactor = Math.min(newZoomFactor, maxZoomFactorRef.current);

    const canvasPoint = getCanvasPoint(mousePositionRef.current) ; // Текущая позиция мыши

    let newZoomOffset = {
      x: (canvasPoint.x * canvasScaleRef.current * (zoomFactorRef.current - newZoomFactor)) + panOffsetRef.current.x,
      y: (canvasPoint.y * canvasScaleRef.current * (zoomFactorRef.current - newZoomFactor)) + panOffsetRef.current.y,
    }

    if (newZoomFactor === 1) {
      newZoomOffset = {x: 0, y: 0}
    }
   
    // Ограничение panOffset
    setZoomFactor(newZoomFactor);

    const max = {
      x: 95,
      y: 95,
    }
    const min = {
      x: -95 -((canvasDimensionsRef.current.width) * (zoomFactorRef.current - 1)) * canvasScaleRef.current * 1,
      y: -95 -((canvasDimensionsRef.current.height) * (zoomFactorRef.current - 1)) * canvasScaleRef.current * 1,
    }

    newZoomOffset.x = Math.max(min.x, newZoomOffset.x)
    newZoomOffset.x = Math.min(max.x, newZoomOffset.x)
    newZoomOffset.y = Math.max(min.y, newZoomOffset.y)
    newZoomOffset.y = Math.min(max.y, newZoomOffset.y)

    setPanOffset(newZoomOffset);

  }, [setZoomFactor, setPanOffset, mousePositionRef]);


  const changeViewStart = useCallback(({nativeEvent}) => {
    
    movingStartPointRef.current = { x: nativeEvent.clientX, y: nativeEvent.clientY };
    initialOffsetRef.current = panOffsetRef.current || {x: 0, y: 0};

  }, []);

  const ifViewWasChanged = useCallback(()=>{

    if (!movingStartPointRef.current) { return false; }
    
    const changeX = Math.abs(movingStartPointRef.current.x - mousePositionRef.current.x)
    const changeY= Math.abs(movingStartPointRef.current.y - mousePositionRef.current.y)

    if ( changeX < 4 && changeY < 4 ) {
      return false;
    } else {
      return true;
    }

  }, [])


  const changeViewMove = useCallback(({nativeEvent}) => {

    if (!nativeEvent.buttons) {return;}
    if (!movingStartPointRef.current) {return;}
    if (nativeEvent.target.closest('.allow-context-menu')) { return; }

    if (!ifViewWasChanged()) { return; }

    const newPointOffset = {
      x: (mousePositionRef.current.x - movingStartPointRef.current.x) + initialOffsetRef.current.x,
      y: (mousePositionRef.current.y - movingStartPointRef.current.y) + initialOffsetRef.current.y,
    }

    const maxOffset = zoomFactorRef.current < 1 ? 200 : 150;

    const max = {
      x: maxOffset,
      y: maxOffset,
    }
    const min = {
      x: -maxOffset -((canvasDimensionsRef.current.width) * (zoomFactorRef.current - 1)) * canvasScaleRef.current * 1,
      y: -maxOffset -((canvasDimensionsRef.current.height) * (zoomFactorRef.current - 1)) * canvasScaleRef.current * 1,
    }

    newPointOffset.x = Math.max(min.x, newPointOffset.x)
    newPointOffset.x = Math.min(max.x, newPointOffset.x)

    newPointOffset.y = Math.max(min.y, newPointOffset.y)
    newPointOffset.y = Math.min(max.y, newPointOffset.y)

    setPanOffset(newPointOffset);

  }, [setPanOffset, zoomFactorRef, mouseIsPressedRef.current]);


  const resetZoom = useCallback(() => {

    setZoomFactor(1);
    setPanOffset({
      x: 0,
      y: 0
    });

  }, [setZoomFactor, setPanOffset]);

  const simpleZoom = useCallback(({ nativeEvent }) => {

    if (!movingStartPointRef.current) {return;}

    if (
      !ifViewWasChanged()
      // movingStartPointRef.current.x === mousePositionRef.current.x
      // && movingStartPointRef.current.y === mousePositionRef.current.y
      ) {

        let newZoomFactor =  zoomFactorRef.current;
        if (nativeEvent.button === 2) {
          newZoomFactor *= 0.5;
        } else if (nativeEvent.button === 1) {
          newZoomFactor = 1;
        } else {
          newZoomFactor *= 2;
        }

        newZoomFactor = Math.max(newZoomFactor, 1);
        changeZoomPc(null, newZoomFactor)
      }

  }, []);

  return {
    resetZoom,
    simpleZoom,
    ifViewWasChanged,
    changeZoomPc,
    changeViewStart,
    changeViewMove,
  };
};

