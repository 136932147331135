import { useRef } from 'react';

import { useGameContext  } from '../../../contexts/GameContext';
import { useDrawingContext  } from '../../../contexts/DrawingContext';
import { useBrushContext  } from '../../../contexts/BrushContext';
import { useHistoryContext  } from '../../../contexts/HistoryContext';

class ShapeTransformer {
  /**
   * @param {Array<{x:number,y:number}>} points - исходные точки (в глобальных координатах)
   * @param {{x:number,y:number}} center - координаты центра
   * @param {{x:number,y:number}} initialPointer - начальное положение курсора (в глобальных координатах)
   */
  constructor({
    points, 
    center, 
    initialPointer, 
    startTime,
  }) {

    this.center = { ...center };
    this.startTime = startTime;
    // Сохраняем "оригинальные" точки
    this.originalPoints = [...points];

    // Переводим точки в "локальные" координаты (относительно центра)
    this.localPoints = points.map((p) => ({
      x: p.x - center.x,
      y: p.y - center.y,
      pressure: p.pressure,
    }));

    // Начальное расстояние от центра до указателя
    this.initialPointerDist = this.distance(center, initialPointer);

    // Начальный угол
    this.initialPointerAngle = Math.atan2(
      initialPointer.y - center.y,
      initialPointer.x - center.x
    );
  }

  /**
   * Вызывается при каждом движении указателя
   * @param {{x: number, y: number}} newPointerPos - новое положение указателя (глобальные координаты)
   * @returns {Array<{x:number,y:number}>} - новые координаты всех точек фигуры
   */

  update(newPointerPos) {
    const distNow = this.distance(this.center, newPointerPos);
    // Если начальное расстояние = 0, чтобы избежать NaN, берём масштаб 1
    const scale =
      this.initialPointerDist === 0
        ? 1
        : distNow / this.initialPointerDist;

    // Вычисляем угол
    const angleNow = Math.atan2(
      newPointerPos.y - this.center.y,
      newPointerPos.x - this.center.x
    );
    const angleDiff = angleNow - this.initialPointerAngle;

    // Трансформируем каждую локальную точку
    const cosA = Math.cos(angleDiff);
    const sinA = Math.sin(angleDiff);

    const transformed = this.localPoints.map((lp) => {
      // Сначала масштаб
      let x = lp.x * scale;
      let y = lp.y * scale;

      // Потом поворот
      const rx = x * cosA - y * sinA;
      const ry = x * sinA + y * cosA;

      // Переводим обратно в глобальные координаты
      return {
        x: this.center.x + rx,
        y: this.center.y + ry,
        pressure: lp.pressure,
      };
    });

    return transformed;
  }

  /**
   * "Финализировать" - если нужно сохранить результат
   */

  finalize(newPointerPos) {
    const finalPoints = this.update(newPointerPos);
    // Можно переписать originalPoints
    this.originalPoints = finalPoints;
    return finalPoints;
  }

  distance(p1, p2) {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    return Math.sqrt(dx * dx + dy * dy);
  }
}



export const useShapeTransform = ({
  getEventPos,       // функция, возвращающая {x,y} из события
  wipeTempCanvas,    // очищаем холст-превью
  drawPreview,       // функция для отрисовки превью (например, stroke)
  addStroke,         // если хотим сохранить в историю
}) => {

  const gameContext = useGameContext();
  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();
  const HistoryContext = useHistoryContext();

  const {
    serverTimeFun,
  } = gameContext;

  const {
    shapeTransformerRef,
    lineWidthRef,
    brushTypeRef,
    visibleColorFun,
    gradientColorFun,
    combinedSetsFun,
  } = brushContext;

  const handleTransformStart = ({
    nativeEvent, points, center
  }) => {

    const pointerPos = getEventPos(nativeEvent);

    // Создаём объект трансформера
    shapeTransformerRef.current = new ShapeTransformer({
      points, 
      center, 
      initialPointer: pointerPos, 
      startTime: serverTimeFun(),
    });

    wipeTempCanvas();
    // Можно нарисовать превью (stroke)
    const combinedSets = combinedSetsFun()
    const stroke = {
      points,
      color: visibleColorFun(),
      gradientColor: gradientColorFun(),
      lineWidth: lineWidthRef.current,
      sets: combinedSets,
      time: shapeTransformerRef.current.startTime,
    };
    drawPreview(stroke);

  };

  const handleTransformMove = async ({ nativeEvent }) => {
    if (!shapeTransformerRef.current) return;

    const pointerPos = getEventPos(nativeEvent);
    wipeTempCanvas();

    const newPoints = shapeTransformerRef.current.update(pointerPos);
    const combinedSets = combinedSetsFun()

    // Можно нарисовать превью (stroke)
    const stroke = {
      points: newPoints,
      color: visibleColorFun(),
      gradientColor: gradientColorFun(),
      lineWidth: lineWidthRef.current,
      sets: combinedSets,
      time: shapeTransformerRef.current.startTime,
      disableSmoothing: true,
    };
    await drawPreview(stroke);
  };

  /**
   * При завершении (отпускании указателя):
   * - Получаем финальные точки
   * - Если нужно, добавляем в историю
   */
  const handleTransformEnd = ({ nativeEvent }) => {
    if (!shapeTransformerRef.current) return;

    const pointerPos = getEventPos(nativeEvent);
    const finalPoints = shapeTransformerRef.current.finalize(pointerPos);

    wipeTempCanvas();

    const serverTime = serverTimeFun();
    addStroke(finalPoints, { 
      time: serverTime,
      disableSmoothing: true,
     });

    // Сброс
    shapeTransformerRef.current = null;
  };

  return {
    handleTransformStart,
    handleTransformMove,
    handleTransformEnd,
  };
};
