// Файл: cropCanvas.js
export function cropCanvas(originalCanvas) {
  const ctx = originalCanvas.getContext('2d');
  const width = originalCanvas.width;
  const height = originalCanvas.height;
  const imageData = ctx.getImageData(0, 0, width, height);
  const data = imageData.data;

  let top = null;
  let bottom = null;
  let left = null;
  let right = null;

  // Находим верхнюю границу
  for (let y = 0; y < height; y++) {
    let stop = false;
    for (let x = 0; x < width; x++) {
      if (data[(y * width + x) * 4 + 3] > 0) {
        top = y;
        stop = true;
        break;
      }
    }
    if (stop) break;
  }

  // Если все пиксели прозрачные
  if (top === null) {
    return {
      croppedCanvas: originalCanvas,
      cropCoordinates: null,
    };
  }

  // Находим нижнюю границу
  for (let y = height - 1; y >= 0; y--) {
    let stop = false;
    for (let x = 0; x < width; x++) {
      if (data[(y * width + x) * 4 + 3] > 0) {
        bottom = y;
        stop = true;
        break;
      }
    }
    if (stop) break;
  }

  // Находим левую границу
  for (let x = 0; x < width; x++) {
    let stop = false;
    for (let y = top; y <= bottom; y++) {
      if (data[(y * width + x) * 4 + 3] > 0) {
        left = x;
        stop = true;
        break;
      }
    }
    if (stop) break;
  }

  // Находим правую границу
  for (let x = width - 1; x >= 0; x--) {
    let stop = false;
    for (let y = top; y <= bottom; y++) {
      if (data[(y * width + x) * 4 + 3] > 0) {
        right = x;
        stop = true;
        break;
      }
    }
    if (stop) break;
  }

  const croppedWidth = right - left + 1;
  const croppedHeight = bottom - top + 1;

  // Создаем новый canvas для обрезанного изображения
  const croppedCanvas = document.createElement('canvas');
  croppedCanvas.width = croppedWidth;
  croppedCanvas.height = croppedHeight;
  const croppedCtx = croppedCanvas.getContext('2d');

  // Копируем обрезанную часть изображения на новый canvas
  const croppedImageData = ctx.getImageData(left, top, croppedWidth, croppedHeight);
  croppedCtx.putImageData(croppedImageData, 0, 0);

  return {
    croppedCanvas,
    cropCoordinates: {
      x: left,
      y: top,
      width: croppedWidth,
      height: croppedHeight,
    },
  };
}