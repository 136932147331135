import "./Spinner.css";

function Spinner({
  gameContext,
}) {
    return (
      <>
      <svg className="spinner" viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        ></circle>
      </svg>
      {/* <span className="spinner-debug-text">{gameContext.FULL_SERVER_URL}</span> */}
      </>
	);
}

export default Spinner;
