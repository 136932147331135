// sparkle.js
import chroma from 'chroma-js';
import { brushDefaults } from './helpers/brushLoader';

const defaultBrushSettings = brushDefaults.sparkle || {};

export async function drawSparkleStroke(stroke, context, params) {
  const {
    sets = {},
  } = stroke;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  
  // Создаем буферный канвас
  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  // Добавляем искры
  addSparkles(stroke, bufferCtx, brushSettings);
  
  // Копируем результат на основной холст
  context.drawImage(bufferCanvas, 0, 0);
}

function addSparkles(stroke, context, brushSettings) {
  let {
    points, 
    color, 
    lineWidth,
  } = stroke;

  const {
    sparkleDensity = 0.5,          // Плотность искр (0-1)
    sparkleSize = 1,               // Размер отдельной искры
    sparkleDistributionPower = 0.8,// Степень распределения искр
    sparkleShape,       // Форма искры
    variableSize, 
    rotate,
    opacity,
    pressureOn = true,
  } = brushSettings;

  if (opacity && opacity !== 1) {
    const chromaColor = chroma(color);
    color = chromaColor.alpha(opacity).hex();
  }

  context.fillStyle = color;
  context.globalAlpha = 1;

  let basicDensity = Math.floor(lineWidth * sparkleDensity / sparkleSize );
  basicDensity = Math.max(basicDensity, 1)

  // Проходим по точкам штриха с шагом, зависящим от плотности
  const step = Math.max(1, Math.round(1 / Math.sqrt(sparkleDensity/2)));
  
  for (let i = 0; i < points.length; i += step) {
    const point = points[i];
    let density = basicDensity;
    if (point.pressure && pressureOn) {
      const pressureCoef = 5;
      density = (basicDensity + 0.2) * point.pressure * pressureCoef;
    }
    
    // Создаем искры вокруг каждой точки
    for (let j = 0; j < density; j++) {
      const seed1 = stroke.time + point.x + point.y + j + i;
      const seed2 = stroke.time + point.x * point.y + j - 1;

      const angle = seededRandom(seed1) * 2 * Math.PI;
      const randomValue = seededRandom(seed2);
      const radius = Math.pow(randomValue, sparkleDistributionPower) * lineWidth * 0.5;
      
      const sparkleX = point.x + radius * Math.cos(angle);
      const sparkleY = point.y + radius * Math.sin(angle);

      // Выбираем форму искры
      let shape = sparkleShape;
      if (sparkleShape === 'mix') {
        shape = getPseudoRandomShape(stroke, point, i, j);
      }

      let adjustedSize = sparkleSize;
      if (variableSize) {
        adjustedSize = getPseudoRandomSize(stroke, point, sparkleSize, i, j);
      }

      if (sparkleSize <= 2 && !variableSize) {
        shape = 'square';
      }

      // Вычисляем псевдослучайный угол вращения
      let rotationAngleRadians = 0;
      if (rotate > 0) {
        const seedRotation = stroke.time + point.x * point.y + i + j + 1000; // Используем другой seed
        const randomRotationValue = seededRandom(seedRotation);
        const rotationAngleDegrees = -rotate + randomRotationValue * (2 * rotate);
        rotationAngleRadians = rotationAngleDegrees * (Math.PI / 180);
      }

      context.strokeStyle = color; // Установите цвет обводки

      // Рисуем отдельную искру
      drawSparkleShape(context, sparkleX, sparkleY, adjustedSize, shape, rotationAngleRadians, stroke.time);
    }
  }
  
  context.globalAlpha = 1;
}

// Функция для получения псевдослучайной формы искры
function getPseudoRandomShape(stroke, point, i, j) {
  const shapes = [
    'round', 
    'star', 
    'spikes', 
    'diamond', 
    'cross',
    'sparkle',
    'horizontalLine',
    'verticalLine',
  ];
  const seed = stroke.time + point.x + point.y + i + j * j;
  const randomValue = seededRandom(seed);
  const index = Math.floor(randomValue * shapes.length);
  return shapes[index];
}

// Функция для получения псевдослучайного размера искры
function getPseudoRandomSize(stroke, point, baseSize, i, j) {
  const seed = stroke.time + point.x * point.y + i + j;
  const randomValue = seededRandom(seed);
  const minSize = baseSize / 3;
  const maxSize = baseSize * 3;
  return minSize + randomValue * (maxSize - minSize);
}

// Функция для рисования отдельной искры заданной формы
function drawSparkleShape(context, x, y, size, shape, rotation, time) {

  const seed = time + x + y;

  context.save();
  context.translate(x, y);
  if (rotation) { context.rotate(rotation);}

  switch(shape) {
    case 'square':
      context.fillRect(-size / 2, -size / 2, size, size);
      break;
    case 'round':
      context.beginPath();
      context.arc(0, 0, size / 2, 0, 2 * Math.PI);
      context.fill();
      break;
    case 'star':
      drawStar(context, 0, 0, size / 1.3, 5, 0.5);
      break;
    case 'spikes':
      const spikesCount = 9
      // const spikesCount = 6 + Math.floor(seededRandom(x + y + rotation) * 7)
      drawStar(context, 0, 0, size / 1.3, spikesCount, 0.23);
      break;
    case 'light':
      drawStar(context, 0, 0, size / 1.3, 17, 0.05);
      break;
    case 'sparkle':
      drawStar(context, 0, 0, size / 1.3, 4, 0.23);
      break;
    case 'triangle':
      context.beginPath();
      context.moveTo(0, -size / 2);
      context.lineTo(size / 2, size / 2);
      context.lineTo(-size / 2, size / 2);
      context.closePath();
      context.fill();
      break;
    case 'diamond':
      context.beginPath();
      context.moveTo(0, -size / 2);
      context.lineTo(size / 2, 0);
      context.lineTo(0, size / 2);
      context.lineTo(-size / 2, 0);
      context.closePath();
      context.fill();
      break;
    case 'cross':
      context.fillRect(-size / 2, -size / 6, size, size / 3);
      context.fillRect(-size / 6, -size / 2, size / 3, size);
      break;
    case 'circle':
      context.lineWidth = Math.max(size / 20, 0.5);
      context.beginPath();
      context.arc(0, 0, size / 2, 0, 2 * Math.PI);
      context.stroke();
      break;
    case 'perimeter':
      context.lineWidth = Math.max(size / 20, 0.5);
      context.beginPath();
      context.rect(-size / 2, -size / 2, size, size);
      context.stroke();
      break;
    case 'horizontalLine':
      context.lineWidth = Math.max(size / 20, 0.5);
      context.beginPath();
      context.moveTo(-size / 2, 0);
      context.lineTo(size / 2, 0);
      context.stroke();
      break;
    case 'verticalLine':
      context.lineWidth = Math.max(size / 20, 0.5);
      context.beginPath();
      context.moveTo(0, -size / 2);
      context.lineTo(0, size / 2);
      context.stroke();
      break;
    case 'heart':
      drawHeart(context, 0, 0, size);
      break;
    case 'flower':
      drawFlower(context, 0, 0, size);
      break;

    case 'moon':
      drawMoon(context, size);
      break;
    case 'cloud':
      drawCloud(context, size);
      break;
    case 'raindrop':
      drawRaindrop(context, size);
      break;
    case 'snowflake':
      drawSnowflake(context, size);
      break;
    case 'paw':
      drawPaw(context, size);
      break;
    case 'musicNote':
      drawMusicNote(context, size);
      break;
    case 'matrix':
      seededRandom(x + y + rotation) < 0.5 ? drawZero(context, size) : drawOne(context, size);
      break;
    case 'stain':
      drawStain(context, size, seed);
      break;
    case 'klyaksa':
      drawKlyaksa(context, size, seed);
      break;
    case 'klyaksa2':
      drawKlyaksa2(context, size, seed);
      break;

    case 'shards':
      const shardsSeed = time + x + y;
      drawShards(context, size, shardsSeed);
      break;
    default:
      context.fillRect(-size / 2, -size / 2, size, size);
  }

  context.restore();
}

// Функция для рисования звезды
function drawStar(ctx, cx, cy, outerRadius, points, inset) {
  ctx.save();
  ctx.beginPath();
  ctx.translate(cx, cy);
  ctx.moveTo(0, -outerRadius);
  for (let i = 0; i < points; i++) {
    ctx.rotate(Math.PI / points);
    ctx.lineTo(0, -outerRadius * inset);
    ctx.rotate(Math.PI / points);
    ctx.lineTo(0, -outerRadius);
  }
  ctx.closePath();
  ctx.fill();
  ctx.restore();
}

// Простая функция для генерации псевдослучайных чисел
function seededRandom(seed) {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}


// Функция для рисования сердца
function drawHeart(context, x, y, size) {
  context.save();
  context.beginPath();
  const topCurveHeight = size * 0.3;

  // Смещение по вертикали для центрирования сердца
  const yOffset = y - size / 2;

  context.moveTo(x, yOffset + topCurveHeight);
  // Левая сторона сердца
  context.bezierCurveTo(
    x, yOffset,
    x - size / 2, yOffset,
    x - size / 2, yOffset + topCurveHeight
  );
  // Левая нижняя часть
  context.bezierCurveTo(
    x - size / 2, yOffset + (size + topCurveHeight) / 2,
    x, yOffset + (size + topCurveHeight) / 1.5,
    x, yOffset + size
  );
  // Правая нижняя часть
  context.bezierCurveTo(
    x, yOffset + (size + topCurveHeight) / 1.5,
    x + size / 2, yOffset + (size + topCurveHeight) / 2,
    x + size / 2, yOffset + topCurveHeight
  );
  // Правая сторона сердца
  context.bezierCurveTo(
    x + size / 2, yOffset,
    x, yOffset,
    x, yOffset + topCurveHeight
  );
  context.closePath();
  context.fill();
  context.restore();
}

// Функция для рисования цветка
function drawFlower(context, x, y, size) {
  context.save();
  context.translate(x, y);
  context.beginPath();
  const petalCount = 6;
  const petalRadius = size / 2;
  for (let i = 0; i < petalCount; i++) {
    const angle = (i * 2 * Math.PI) / petalCount;
    context.save();
    context.rotate(angle);
    context.moveTo(0, 0);
    context.quadraticCurveTo(petalRadius / 2, -petalRadius / 2, 0, -petalRadius);
    context.quadraticCurveTo(-petalRadius / 2, -petalRadius / 2, 0, 0);
    context.restore();
  }
  context.fill();
  context.restore();
}


function drawMoon(context, size) {
  context.beginPath();
  context.arc(0, 0, size / 2, 0.5 * Math.PI, 1.5 * Math.PI, false);
  context.arc(-size / 5, 0, size / 2, 1.5 * Math.PI, 0.5 * Math.PI, true);
  context.closePath();
  context.fill();
}


function drawCloud(context, size) {
  const radius = size / 4;
  context.beginPath();
  context.arc(-radius, 0, radius, Math.PI * 0.5, Math.PI * 1.5);
  context.arc(0, -radius, radius, Math.PI, 0);
  context.arc(radius, 0, radius, Math.PI * 1.5, Math.PI * 0.5);
  context.closePath();
  context.fill();
}


function drawRaindrop(context, size) {
  context.save();
  context.beginPath();
  
  const topY = -size * 0.9;
  context.moveTo(0, topY);
  
  // Правая сторона капли - важные изменения здесь
  context.bezierCurveTo(
    size/32, topY,         // Первая точка ещё ближе к центру
    size/2, -size/2.5,     // Вторая точка поднята выше
    size/2, size/4
  );
  
  context.arc(0, size/4, size/2, 0, Math.PI, false);
  
  context.bezierCurveTo(
    -size/2, -size/2.5,    
    -size/32, topY,        
    0, topY
  );
  
  context.closePath();
  context.fill();
  context.restore();
}

function drawSnowflake(context, size) {
  context.lineWidth = size / 15;
  const lines = 6;
  for (let i = 0; i < lines; i++) {
    const angle = (i * Math.PI) / (lines / 2);
    context.beginPath();
    context.moveTo(0, 0);
    context.lineTo((size / 2) * Math.cos(angle), (size / 2) * Math.sin(angle));
    context.stroke();
  }
}



function drawPaw(context, size) {
  // Рисуем основную подушечку
  context.beginPath();
  context.arc(0, size / 6, size / 4, 0, 2 * Math.PI);
  context.fill();

  const toeOffsets = [
    [-size / 4, -size / 6],
    [0, -size / 4],
    [size / 4, -size / 6],

  ];

  toeOffsets.forEach(offset => {
    context.beginPath();
    context.arc(offset[0], offset[1], size / 8, 0, 2 * Math.PI);
    context.fill();
  });
}



function drawMusicNote(context, size) {
  context.lineWidth = size / 15;

  // Рисуем головку нотки (наклоненный овал)
  context.beginPath();
  context.ellipse(0, 0, size / 5, size / 4, -Math.PI / 4, 0, 2 * Math.PI);
  context.fill();

  // Рисуем штиль
  const stemHeight = size / 2;
  const stemOffsetX = size / 5 * Math.cos(-Math.PI / 4);
  const stemOffsetY = size / 5 * Math.sin(-Math.PI / 4);

  context.beginPath();
  context.moveTo(stemOffsetX, stemOffsetY);
  context.lineTo(stemOffsetX, stemOffsetY - stemHeight);
  context.stroke();

  // Рисуем флажок
  context.beginPath();
  context.moveTo(stemOffsetX, stemOffsetY - stemHeight);
  context.quadraticCurveTo(
    stemOffsetX + size / 4, // Контрольная точка по X
    stemOffsetY - stemHeight + size / 6, // Контрольная точка по Y
    stemOffsetX, // Конечная точка по X
    stemOffsetY - stemHeight + size / 3 // Конечная точка по Y
  );
  context.stroke();
}

// Function to draw the '0' symbol
function drawZero(context, size) {
  context.save();
  context.lineWidth = size / 8;

  // Draw the outer ellipse
  context.beginPath();
  context.ellipse(0, 0, size / 3.3, size / 2, 0, 0, 2 * Math.PI);
  context.stroke();

  context.restore();
}

// Function to draw the '1' symbol
function drawOne(context, size) {
  context.save();
  context.lineWidth = size / 8;

  context.beginPath();
  // Начинаем с наклонной верхней части '1'
  context.moveTo(-size / 4, -size / 2 + size / 5);
  context.lineTo(0, -size / 2);
  // Спускаемся вниз по вертикальной линии
  context.lineTo(0, size / 2);
  // Рисуем базовую линию
  context.lineTo(size / 6, size / 2);
  context.moveTo(0, size / 2); // Перемещаемся обратно к центральной нижней точке
  context.lineTo(-size / 6, size / 2);
  // Не вызываем closePath(), чтобы избежать лишней линии
  // context.closePath();
  context.stroke();

  context.restore();
}


function drawStain(context, size, seed) {
  const numPoints = 27 + Math.floor(seededRandom(seed) * 5); // Количество точек 
  const baseRadius = size / 2;
  const points = [];

  // Генерируем точки с переменным радиусом
  for (let i = 0; i < numPoints; i++) {
    const angle = (i / numPoints) * 2 * Math.PI;
    const randomSeed = seed + i * 1000;
    const randomFactor = 0.7 + seededRandom(randomSeed) * 0.6; // Фактор от 0.7 до 1.3
    const radius = baseRadius * randomFactor;
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    points.push({ x, y });
  }

  // Рисуем плавную замкнутую фигуру
  context.beginPath();
  context.moveTo(points[0].x, points[0].y);

  for (let i = 0; i < points.length; i++) {
    const curr = points[i];
    const next = points[(i + 1) % points.length];
    const ctrlX = (curr.x + next.x) / 2;
    const ctrlY = (curr.y + next.y) / 2;
    context.quadraticCurveTo(curr.x, curr.y, ctrlX, ctrlY);
  }

  context.closePath();
  context.fill();
}

function drawKlyaksa(context, size, seed) {
  const numPoints = 27 + Math.floor(seededRandom(seed) * 5); // Количество точек
  const baseRadius = size / 2;
  const points = [];

  // Генерируем точки с переменным радиусом, включая длинные отростки
  for (let i = 0; i < numPoints; i++) {
    const angle = (i / numPoints) * 2 * Math.PI;
    const randomSeed = seed + i * 1000;
    let randomFactor = 0.7 + seededRandom(randomSeed) * 0.6; // Фактор от 0.7 до 1.3

    // Вводим длинные отростки
    const protrusionChance = 0.2; // 20% шанс создать длинный луч
    if (seededRandom(randomSeed + 5000) < protrusionChance) {
      randomFactor *= 1.5 + seededRandom(randomSeed + 7000) * 1.5; // Увеличиваем радиус до 3х
    }

    const radius = baseRadius * randomFactor;
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    points.push({ x, y });
  }

  // Рисуем плавную замкнутую фигуру
  context.beginPath();
  context.moveTo(points[0].x, points[0].y);

  for (let i = 0; i < points.length; i++) {
    const curr = points[i];
    const next = points[(i + 1) % points.length];
    // Вычисляем контрольные точки для плавных кривых
    const ctrlX = (curr.x + next.x) / 2;
    const ctrlY = (curr.y + next.y) / 2;
    context.quadraticCurveTo(curr.x, curr.y, ctrlX, ctrlY);
  }

  context.closePath();
  context.fill();
}


function drawKlyaksa2(context, size, seed) {
  const numPoints = 20 + Math.floor(seededRandom(seed) * 15);
  const baseRadius = size / 2;
  const points = [];

  for (let i = 0; i < numPoints; i++) {
    const angle = (i / numPoints) * 2 * Math.PI;
    const randomSeed = seed + i * 1000;

    let randomFactor = 0.7 + seededRandom(randomSeed) * 0.6;

    // Создаем длинные отростки на каждых N точках
    if (i % Math.floor(numPoints / 5) === 0) {
      randomFactor *= 2 + seededRandom(randomSeed + 7000) * 1.5;
    }

    const radius = baseRadius * randomFactor;
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    points.push({ x, y });
  }

  // Рисуем фигуру как ранее
  context.beginPath();
  context.moveTo(points[0].x, points[0].y);

  for (let i = 0; i < points.length; i++) {
    const curr = points[i];
    const next = points[(i + 1) % points.length];
    const ctrlX = (curr.x + next.x) / 2;
    const ctrlY = (curr.y + next.y) / 2;
    context.quadraticCurveTo(curr.x, curr.y, ctrlX, ctrlY);
  }

  context.closePath();
  context.fill();
}


function drawShards(context, size, seed, options = {}) {
  // Параметры по умолчанию
  const {
    numPoints = 12,
    minRadiusFactor = 0.6,
    maxRadiusFactor = 0.9,
    protrusionChance = 0.4,
    protrusionLengthFactor = 2.5,
  } = options;

  const baseRadius = size / 2;
  const points = [];

  // Генерируем точки с переменным радиусом, включая длинные выступы
  for (let i = 0; i < numPoints; i++) {
    const angle = (i / numPoints) * 2 * Math.PI;
    const randomSeed = seed + i * 1000;
    let randomFactor = minRadiusFactor + seededRandom(randomSeed) * (maxRadiusFactor - minRadiusFactor);

    // Вводим длинные выступы
    if (seededRandom(randomSeed + 5000) < protrusionChance) {
      randomFactor *= protrusionLengthFactor + seededRandom(randomSeed + 7000) * (protrusionLengthFactor - 1);
    }

    const radius = baseRadius * randomFactor;
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    points.push({ x, y });
  }

  // Рисуем замкнутую ломаную линию
  context.beginPath();
  context.moveTo(points[0].x, points[0].y);

  for (let i = 1; i < points.length; i++) {
    context.lineTo(points[i].x, points[i].y);
  }

  context.closePath();
  context.fill();
}




