// CircleSwitch.js
import React from 'react';
// import { ReactSVG } from "react-svg";
import "./CircleSwitch.css";
import { createBetterClick } from '../../../../utils/createBetterClick'

function CircleSwitch({
    id,
    className,
    text,

    icon,
    available = true,

    onClick,
    onTouchEnd,
    onContextMenu,
    isActive = false,
    cursor = 'pointer',
    color,
    gradientColor,
    svgStyle = {},

    path,
    menu = false,
}: any) {

    const clickHandlers = createBetterClick({ onClick, onContextMenu });

    const gradientId = `gradient-${id}`;

    svgStyle = {
      height: "24px", 
      width: "24px",
      color,
      // fill: isActive ? color : 'initial', // Управление заливкой цветом
      ...svgStyle,
    }

    if (gradientColor) {
      svgStyle.fill = `url(#${gradientId})`;
    }
    
    let buttonStyle = available ? 'circle-switch' : 'circle-switch-n-a'
    let menuIncidatorIcon = available ? 'circle-switch-menu-indicator' : 'circle-switch-menu-indicator-n-a'

    return (
        <div
            id={id}
            className={
              `${className || ""} ${buttonStyle} ${isActive ? "active" : ""}`
            }
            style={{cursor}}
            // onClick={onClick}
            // onTouchEnd={onTouchEnd}
            {...clickHandlers}
        >
          {calculateGradient(gradientId, color, gradientColor)}
          {menu && <div className={`${menuIncidatorIcon} ${isActive ? "active" : ""}`} />}

            { 
              icon ? (
                (
                  <span
                    className="svg"
                    style={svgStyle}
                  >{React.cloneElement(icon, { style: svgStyle })}</span>
                ) 
              ) : (
                <div className="svg empty-circle" style={{ height: "25px", width: "25px", borderRadius: "50%" }}></div>
              )
            }
            {/* <span>{text}</span> */}
        </div>
    );
}


// Новая функция для расчетов градиента
function calculateGradient(id, color, gradientColor) {
  if (!gradientColor) return null;

  return (
    <svg width="0" height="0">
      <defs>
        <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={color} />
          <stop offset="100%" stopColor={gradientColor || color} />
        </linearGradient>
      </defs>
    </svg>
  );
}


export default CircleSwitch;
