export const renderCheckerboardBackground = ({
  gameContext,
  canvasContext,
}) => {

  const { 
    transparentBgRef,
   } = gameContext;

   const {
    canvasStyleDimensionsRef,
    zoomFactor,
    panOffset,
  } = canvasContext;

  if (!transparentBgRef.current) return null;
  return (
    <div
    className="bg-canvas"
    style={{
      width: canvasStyleDimensionsRef.current.width,
      height: canvasStyleDimensionsRef.current.height,
      transformOrigin: '0 0',
      transform: `translate(${panOffset.x - canvasStyleDimensionsRef.current.width / 2}px, ${panOffset.y}px) scale(${zoomFactor})`,
      backgroundImage: `
        linear-gradient(45deg, rgba(204, 204, 204, 1) 25%, transparent 25%),
        linear-gradient(-45deg, rgba(204, 204, 204, 1) 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, rgba(204, 204, 204, 1) 75%),
        linear-gradient(-45deg, transparent 75%, rgba(204, 204, 204, 1) 75%)
      `,
      backgroundColor: 'white',
      backgroundSize: '10px 10px',
      backgroundPosition: '0 0, 0 15px, 5px -5px, -5px 0px',
      pointerEvents: 'none',
      zIndex: 0,
      boxShadow: '0',
    }}
  />
  );
};


export const renderAccessoryBackground = ({
  gameContext,
  canvasContext,
}) => {

  const { 
    gameInfoRef,
   } = gameContext;

   const {
    canvasStyleDimensionsRef,
    zoomFactor,
    panOffset,
    bgCanvasRef,
  } = canvasContext;

  const game = gameInfoRef.current;

  if (!game?.accessory) return null;

  return (
      <canvas className='bg-canvas'
        ref={bgCanvasRef}
        style={{
          transformOrigin: '0 0',
          transform: `translate(${panOffset.x - canvasStyleDimensionsRef.current.width / 2}px, ${panOffset.y}px) scale(${zoomFactor})`
        }}
      />
  );
};