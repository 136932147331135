import React, { useRef, useState, useEffect, } from 'react';
import { 
  FaPencilRuler, 
  FaCog,
 } from 'react-icons/fa';
import { 
  FaClone,
  FaFloppyDisk,
  FaTrash,
 } from 'react-icons/fa6';
import { HexAlphaColorPicker } from "react-colorful";
import './CustomizeBrush.css';
import { useTranslation } from 'react-i18next';
import BrushSizeControl from '../tools/BrushSizeControl'; 
import { createBetterClick } from '../../../utils/createBetterClick'


function CustomizeBrush({ 
  gameContext, 
  brushContext, 
  drawingContext,
 }) {

  const { t } = useTranslation();

  const { 
    userSetsRef, 
    saveUserSets,

    menuParamRef,
    openMenu,
    closeMenu,

    drawMethodsRef,

    popupMessage,
    showPopupMessage,
    forceRender,

    showTemporaryHint,
    isAppleDevice,

  } = gameContext;
  const { 
    lastActionTimeRef,
    lineWidth,
    color, setTheColorFun,
    softness,
    setBrushType,
    brushSetsFun,

    getTexture, 
    sources, 
    brushDefaults,

    filterClosePoints,
    gradientColorFun,
  } = brushContext;
  const { 
    canvasScaleRef,
  } = drawingContext;

  const [colorPickerState, setColorPickerState] = useState({
    isOpen: false,
    key: null,
  });

  const brushViews = {
    dashed: {
      lineWidth: 5,
      singleStroke: true,
    },
    feather: {
      lineWidth: 25,
      singleStroke: true,
    },
    ink: {
      lineWidth: 25,
      singleStroke: true,
    },
    filler: {
      removeBrushPreview: true,
      removeCustomSave: true,
    },
    texture: {
    },
  }

  const compositionMethods = [
    { value: 'source-over', label: t('customize.composition.source-over') },
    { value: 'multiply', label: t('customize.composition.multiply') },
    { value: 'darken', label: t('customize.composition.darken') },
    { value: 'color-burn', label: t('customize.composition.color-burn') },

    { value: 'screen', label: t('customize.composition.screen') },
    { value: 'lighten', label: t('customize.composition.lighten') },
    { value: 'color-dodge', label: t('customize.composition.color-dodge') },

    { value: 'overlay', label: t('customize.composition.overlay') },
    { value: 'exclusion', label: t('customize.composition.exclusion') },

    { value: 'hard-light', label: t('customize.composition.hard-light') },
    { value: 'soft-light', label: t('customize.composition.soft-light') },
    { value: 'difference', label: t('customize.composition.difference') },

    { value: 'hue', label: t('customize.composition.hue') },
    { value: 'saturation', label: t('customize.composition.saturation') },
    { value: 'color', label: t('customize.composition.color') },
    { value: 'luminosity', label: t('customize.composition.luminosity') },
  ];

  const textureCompositionMethods = [
    { value: 'luminosity', label: t('customize.composition.luminosity') },
    { value: 'source-over', label: t('customize.composition.source-over') },
    { value: 'darken', label: t('customize.composition.darken') },

    { value: 'lighten', label: t('customize.composition.lighten') },

    { value: 'hard-light', label: t('customize.composition.hard-light') },
    { value: 'soft-light', label: t('customize.composition.soft-light') },
  ];

  const sparkleShapes = [
    { value: 'square', label: t('customize.shape.square') },
    { value: 'mix', label: t('customize.shape.mix') },
    { value: 'round', label: t('customize.shape.round') },
    { value: 'star', label: t('customize.shape.star') },
    { value: 'spikes', label: t('customize.shape.spikes') },
    { value: 'light', label: t('customize.shape.light') },
    { value: 'triangle', label: t('customize.shape.triangle') },
    { value: 'diamond', label: t('customize.shape.diamond') },
    { value: 'cross', label: t('customize.shape.cross') },
    { value: 'sparkle', label: t('customize.shape.sparkle') },
    { value: 'heart', label: t('customize.shape.heart') },
    { value: 'flower', label: t('customize.shape.flower') },
    { value: 'circle', label: t('customize.shape.circle') },
    { value: 'perimeter', label: t('customize.shape.perimeter') },
    { value: 'horizontalLine', label: t('customize.shape.horizontal_line') },
    { value: 'verticalLine', label: t('customize.shape.vertical_line') },

    // Новые фигуры
    { value: 'moon', label: t('customize.shape.moon') },
    { value: 'cloud', label: t('customize.shape.cloud') },
    { value: 'raindrop', label: t('customize.shape.raindrop') },
    { value: 'snowflake', label: t('customize.shape.snowflake') },
    { value: 'paw', label: t('customize.shape.paw') },
    { value: 'matrix', label: t('customize.shape.matrix') },
    { value: 'stain', label: t('customize.shape.stain') },
    { value: 'klyaksa', label: t('customize.shape.klyaksa') },
    { value: 'shards', label: t('customize.shape.shards') },
  ];

  const shapeFigures = [
    { value: 'line', label: t('customize.figure.line') },
    { value: 'square', label: t('customize.figure.square') },
    { value: 'round', label: t('customize.figure.round') },
    { value: 'arrow', label: t('customize.figure.arrow') },
    { value: 'triangle', label: t('customize.figure.triangle') },
    { value: 'star', label: t('customize.figure.star') },
  ];
  const shapeBrushes = [
    { value: 'plain', label: t('brush.plain.name') },
    { value: 'pencil', label: t('brush.pencil.name') },
    { value: 'bristle', label: t('brush.bristle.name') },
    { value: 'watercolor', label: t('brush.watercolor.name') },
    { value: 'feather', label: t('brush.feather.name') },
    { value: 'ink', label: t('brush.ink.name') },
    { value: 'rembrandt', label: t('brush.rembrandt.name') },
    { value: 'outlined', label: t('brush.outlined.name') },
    { value: 'dashed', label: t('brush.dashed.name') },
  ];

  const brushParams = {
    common: [
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
      { type: 'toggle', key: 'pressureOpacity', label: t('customize.prop.pressure_opacity') },
      { type: 'slider', key: 'pressureExponent', label: t('customize.prop.pressure_exponent'), min: 1, max: 5, step: 0.01 },
      { type: 'slider', key: 'sensitivity', label: t('customize.prop.sensitivity'), min: 0.1, max: 3, step: 0.01 },
      { type: 'slider', key: 'minimalPressure', label: t('customize.prop.minimal_pressure'), min: 0, max: 1, step: 0.01 },

      { type: 'slider', key: 'smoothing', label: t('customize.prop.smoothing'), min: 0, max: 50, step: 1 },
      { type: 'toggle', key: 'squareBrush', label: t('customize.prop.square_brush') },
      { type: 'select', key: 'composition', label: t('customize.prop.composition'), options: compositionMethods },
      { type: 'toggle', key: 'magnifierButton', label: t('customize.prop.magnifier_button') },
      { type: 'toggle', key: 'brushMagnifier', label: t('customize.prop.brush_magnifier') },
      { type: 'toggle', key: 'eraserMagnifier', label: t('customize.prop.eraser_magnifier') },
    ],
    plain: [
    ],
    shape: [
      { type: 'select', key: 'figure', label: t('customize.prop.figure'), options: shapeFigures },
      { type: 'select', key: 'brush', label: t('customize.prop.brush'), options: shapeBrushes },
    ],
    spray: [
      { type: 'slider', key: 'softness', label: t('customize.prop.softness'), min: 0, max: 0.5, step: 0.01 },
      { type: 'toggle', key: 'appleSprayFix', label: t('customize.prop.apple_spray_fix') },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
    ],
    feather: [
      { type: 'toggle', key: 'speedDependence', label: t('customize.prop.speed_dependence') },

      { type: 'slider', key: 'minWidthStart', label: t('customize.prop.min_width_start'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'taperStartPoint', label: t('customize.prop.taper_start_point'), min: 0, max: 1, step: 0.01, dependency: {max: 'taperEndPoint'} },
      { type: 'slider', key: 'minWidthEnd', label: t('customize.prop.min_width_end'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'taperEndPoint', label: t('customize.prop.taper_end_point'), min: 0, max: 1, step: 0.01, dependency: {min: 'taperStartPoint'} },

      { type: 'toggle', key: 'transparentEdges', label: t('customize.prop.transparent_edges') },
      { conditions: ['transparentEdges'], type: 'slider', key: 'opacityOnStart', label: t('customize.prop.opacity_on_start'), min: 0, max: 1, step: 0.01 },
      { conditions: ['transparentEdges'], type: 'slider', key: 'fadeStartPoint', label: t('customize.prop.fade_start_length'), min: 0, max: 1, step: 0.01, dependency: {max: 'fadeEndPoint'}  },
      { conditions: ['transparentEdges'], type: 'slider', key: 'opacityOnEnd', label: t('customize.prop.opacity_on_end'), min: 0, max: 1, step: 0.01 },
      { conditions: ['transparentEdges'], type: 'slider', key: 'fadeEndPoint', label: t('customize.prop.fade_length_length'), min: 0, max: 1, step: 0.01, dependency: {min: 'fadeStartPoint'}  },

    ],
    ink: [
      { type: 'toggle', key: 'speedDependence', label: t('customize.prop.speed_dependence') },
      { type: 'slider', key: 'centralWidth', label: t('customize.prop.central_width'), min: 0.00, max: 1, step: 0.01 },

      { type: 'slider', key: 'minWidthStart', label: t('customize.prop.min_width_start'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'taperStartPoint', label: t('customize.prop.taper_start_point'), min: 0, max: 1, step: 0.01, dependency: {max: 'taperEndPoint'} },
      { type: 'slider', key: 'minWidthEnd', label: t('customize.prop.min_width_end'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'taperEndPoint', label: t('customize.prop.taper_end_point'), min: 0, max: 1, step: 0.01, dependency: {min: 'taperStartPoint'} },

      { type: 'toggle', key: 'transparentEdges', label: t('customize.prop.transparent_edges') },
      { conditions: ['transparentEdges'], type: 'slider', key: 'opacityOnStart', label: t('customize.prop.opacity_on_start'), min: 0, max: 1, step: 0.01 },
      { conditions: ['transparentEdges'], type: 'slider', key: 'fadeStartPoint', label: t('customize.prop.fade_start_length'), min: 0, max: 1, step: 0.01, dependency: {max: 'fadeEndPoint'}  },
      { conditions: ['transparentEdges'], type: 'slider', key: 'opacityOnEnd', label: t('customize.prop.opacity_on_end'), min: 0, max: 1, step: 0.01 },
      { conditions: ['transparentEdges'], type: 'slider', key: 'fadeEndPoint', label: t('customize.prop.fade_length_length'), min: 0, max: 1, step: 0.01, dependency: {min: 'fadeStartPoint'}  },

    ],
    bristle: [
      { type: 'slider', key: 'wobble', label: t('customize.prop.wobble'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0.01, max: 1, step: 0.002 },
      // { type: 'slider', key: 'ragged', label: t('customize.prop.ragged'), min: 0, max: 3, step: 0.01 },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
    ],
    pencil: [
      { type: 'toggle', key: 'outline', label: t('customize.prop.outline') },
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0, max: 1, step: 0.01 },

      { type: 'toggle', key: 'tapering', label: t('customize.prop.tapering') },

      { conditions: ['tapering'], type: 'slider', key: 'minWidthStart', label: t('customize.prop.min_width_start'), min: 0.01, max: 1, step: 0.01 },
      { conditions: ['tapering'], type: 'slider', key: 'taperStartPoint', label: t('customize.prop.taper_start_point'), min: 0, max: 1, step: 0.01, dependency: {max: 'taperEndPoint'} },
      { conditions: ['tapering'], type: 'slider', key: 'minWidthEnd', label: t('customize.prop.min_width_end'), min: 0.01, max: 1, step: 0.01 },
      { conditions: ['tapering'], type: 'slider', key: 'taperEndPoint', label: t('customize.prop.taper_end_point'), min: 0, max: 1, step: 0.01, dependency: {min: 'taperStartPoint'} },

      { type: 'toggle', key: 'transparentEdges', label: t('customize.prop.transparent_edges') },
      { conditions: ['transparentEdges'], type: 'slider', key: 'opacityOnStart', label: t('customize.prop.opacity_on_start'), min: 0, max: 1, step: 0.01 },
      { conditions: ['transparentEdges'], type: 'slider', key: 'fadeStartPoint', label: t('customize.prop.fade_start_length'), min: 0, max: 1, step: 0.01, dependency: {max: 'fadeEndPoint'}  },
      { conditions: ['transparentEdges'], type: 'slider', key: 'opacityOnEnd', label: t('customize.prop.opacity_on_end'), min: 0, max: 1, step: 0.01 },
      { conditions: ['transparentEdges'], type: 'slider', key: 'fadeEndPoint', label: t('customize.prop.fade_length_length'), min: 0, max: 1, step: 0.01, dependency: {min: 'fadeStartPoint'}  },

      { type: 'slider', key: 'textureScale', label: t('customize.prop.texture_scale'), min: 0.1, max: 2, step: 0.01 },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
    ],
    oil: [
      { type: 'toggle', key: 'speedDependence', label: t('customize.prop.speed_dependence') },

      { type: 'slider', key: 'minWidthStart', label: t('customize.prop.min_width_start'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'taperStartPoint', label: t('customize.prop.taper_start_point'), min: 0, max: 1, step: 0.01, dependency: {max: 'taperEndPoint'} },
      { type: 'slider', key: 'minWidthEnd', label: t('customize.prop.min_width_end'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'taperEndPoint', label: t('customize.prop.taper_end_point'), min: 0, max: 1, step: 0.01, dependency: {min: 'taperStartPoint'} },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
    ],
    watercolor: [
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0, max: 1, step: 0.01 },
      { type: 'toggle', key: 'spreading', label: t('customize.prop.spreading') },
      { type: 'toggle', key: 'outline', label: t('customize.prop.outline'), min: 0, max: 1, step: 0.1 },
      { conditions: ['outline'], type: 'slider', key: 'outlineSize', label: t('customize.prop.outline_size'), min: 0, max: 10, step: 0.1 },
      { conditions: ['outline'], type: 'slider', key: 'outlineOpacity', label: t('customize.prop.outline_opacity'), min: 0, max: 1, step: 0.01 },
      { type: 'slider', key: 'waterBlurSize', label: t('customize.prop.water_blur_size'), min: 0, max: 50, step: 0.5 },
      { type: 'slider', key: 'waterBlurAlpha', label: t('customize.prop.water_blur_alpha'), min: 0, max: 1, step: 0.01 },
      { type: 'toggle', key: 'textureOn', label: t('customize.prop.texture_on') },
      { conditions: ['textureOn'], type: 'slider', key: 'textureScale', label: t('customize.prop.texture_scale'), min: 0.1, max: 2, step: 0.01 },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
    ],
    texture: [
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0, max: 1, step: 0.01 },
      { type: 'slider', key: 'blending', label: t('customize.prop.blending'), min: 0, max: 1, step: 0.01 },
      { type: 'slider', key: 'textureScale', label: t('customize.prop.texture_scale'), min: 0.1, max: 2, step: 0.01 },
      { type: 'select', key: 'composition', label: t('customize.prop.composition'), options: textureCompositionMethods },
    ],
    rembrandt: [
      { type: 'toggle', key: 'cutEdges', label: t('customize.prop.cut_edges') },
      { conditionsNot: ['cutEdges'], type: 'slider', key: 'tapering', label: t('customize.prop.tapering'), min: 0, max: 1, step: 0.01 },
      { type: 'slider', key: 'density', label: t('customize.prop.density'), min: 0, max: 1, step: 0.01 },
      // { type: 'slider', key: 'ragged', label: t('customize.prop.ragged'), min: 0, max: 3, step: 0.01 },
      { type: 'slider', key: 'wobble', label: t('customize.prop.wobble'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0.01, max: 1, step: 0.002 },
      { type: 'slider', key: 'shadowStrength', label: t('customize.prop.shadow_strength'), min: 0, max: 7, step: 0.01 },
      { type: 'toggle', key: 'ropeEffect', label: t('customize.prop.rope_effect') },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
    ],
    dashed: [
      { type: 'slider', key: 'dashSize', label: t('customize.prop.dash_size'), min: 0, max: 20, step: 0.1 },
      { type: 'slider', key: 'gapSize', label: t('customize.prop.gap_size'), min: 0, max: 20, step: 0.1 },
    ],
    outlined: [
      { type: 'slider', key: 'outlineSize', label: t('customize.prop.outline_size'), min: 0.1, max: 50, step: 0.1 },
      { type: 'color', key: 'outlineColor', label: t('customize.prop.outline_color') },
      { type: 'slider', key: 'outlineOpacity', label: t('customize.prop.outline_opacity'), min: 0, max: 1, step: 0.01 },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
    ],
    neon: [
      { type: 'slider', key: 'neonSize', label: t('customize.prop.neon_size'), min: 1, max: 100, step: 1 },
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0, max: 1, step: 0.01 },
    ],
    sparkle: [
      { type: 'select', key: 'sparkleShape', label: t('customize.prop.shape'), options: sparkleShapes },

      // { type: 'slider', key: 'sparkleRadius', label: t('customize.prop.sparkle_radius'), min: 1, max: 50, step: 1 },
      { type: 'toggle', key: 'highDensity', label: t('customize.prop.high_density') },
      { conditionsNot: ['highDensity'], type: 'slider', key: 'sparkleDensity', label: t('customize.prop.sparkle_density'), min: 0.01, max: 1, step: 0.01 },
      { conditions: ['highDensity'], type: 'slider', key: 'sparkleDensity', label: t('customize.prop.sparkle_density'), min: 0.01, max: 8, step: 0.01 },
      { type: 'toggle', key: 'bigSizes', label: t('customize.prop.big_sizes') },
      { conditionsNot: ['bigSizes'], type: 'slider', key: 'sparkleSize', label: t('customize.prop.sparkle_size'), min: 0.5, max: 50, step: 0.1 },
      { conditions: ['bigSizes'], type: 'slider', key: 'sparkleSize', label: t('customize.prop.sparkle_size'), min: 0.5, max: 1000, step: 1 },
      { type: 'toggle', key: 'variableSize', label: t('customize.prop.variable_size') },
      { type: 'slider', key: 'rotate', label: t('customize.prop.rotate'), min: 0, max: 180, step: 1 },
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'sparkleDistributionPower', label: t('customize.prop.sparkle_distribution'), min: 0, max: 3, step: 0.01 },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
    ],
    filler: [
      { type: 'slider', key: 'tolerance', label: t('customize.prop.tolerance'), min: 0, max: 100, step: 1 },
      { conditionsNot: ['eatEdges'], type: 'slider', key: 'antialiasing', label: t('customize.prop.antialiasing'), min: 0.1, max: 3, step: 0.01 },
      { type: 'toggle', key: 'eatEdges', label: t('customize.prop.eat_edges') },
    ],
    pixelate: [
      { type: 'slider', key: 'size', label: t('customize.prop.size'), min: 1, max: 50, step: 1 },

      { type: 'toggle', key: 'uneven', label: t('customize.prop.uneven') },
      { conditions: ['uneven'], type: 'slider', key: 'sizeX', label: t('customize.prop.size_x'), min: 1, max: 50, step: 1 },
      { conditions: ['uneven'], type: 'slider', key: 'sizeY', label: t('customize.prop.size_y'), min: 1, max: 50, step: 1 },
    ],
    // Add more brush types and their settings as needed
  };
  
  const canvasRef = useRef(null);
  const saveTimerRef = useRef(null);
  const isUpdatingPreviewRef = useRef(false);
  
  const [userColorView, setUserColorView] = useState(false);

  const brushKey = menuParamRef.current.brush === 'plain' ? 'common' : menuParamRef.current.brush;
  const isEffect = menuParamRef.current.effect;

  const basicBrushLink = userSetsRef.current[brushKey]?.clone || brushKey;

  const currentBrushSettings = Object.assign({}, brushDefaults[basicBrushLink] || {}, userSetsRef.current[brushKey] || {});
  
  const currentBrushParams = (brushParams[basicBrushLink] || []).filter((brushParam)=>{
    if (brushParam.conditions && !brushParam.conditions.every(condition=>currentBrushSettings[condition])) { return false; }
    if (brushParam.conditionsNot && brushParam.conditionsNot.every(condition=>currentBrushSettings[condition])) { return false; }
    return true;

  });
  const currentBrushView = brushViews[basicBrushLink] || {};
  const currentBrushDefaults = Object.assign({}, brushDefaults[basicBrushLink] || {}, currentBrushSettings.default || {})

  const brushTextures = sources[basicBrushLink] || {};
  const textureNames = Object.keys(brushTextures);
  const hasTextures = currentBrushSettings.textureOn && textureNames.length > 0;

  const checkIfCustomized = (defaults, settings) =>{
    let customized = false;
    for (let param in settings) {
      if (param === 'default') { continue; }
      if (settings[param] !== defaults[param]) {customized = true;}
    }
    return customized;
  }

  const brushIsCustomized = checkIfCustomized(currentBrushDefaults, currentBrushSettings);
  // const brushIsCustomized = Object.keys(userSetsRef.current[brushKey] || {}).length;
  const isUserBrush = userSetsRef.current.savedBrushes?.includes(brushKey);
  
  const handleSettingChange = (key, value) => {

    const currentParamItem = currentBrushParams.find(paramItem => paramItem.key === key);

    const stickToDefault = false;
    
    if (stickToDefault && currentParamItem?.type === 'slider' && !isNaN(value) ) {
      const currentBrushDefaults = Object.assign({}, brushDefaults[basicBrushLink] || {}, currentBrushSettings.default || {})
      const defaultValue =  currentBrushDefaults[key] || 1;
      let difference = Math.abs(defaultValue - value) / (currentParamItem.max - currentParamItem.min)
      if (difference < 0.03) { value = defaultValue; }
    }

    let newBrushSettings = { ...currentBrushSettings, [key]: value };

    let cdMax = currentParamItem?.dependency?.max;
    let cdMin= currentParamItem?.dependency?.min;
    if (cdMax) {
      newBrushSettings[cdMax] = Math.max(newBrushSettings[cdMax], newBrushSettings[key]);
    }
    if (cdMin) {
      newBrushSettings[cdMin] = Math.min(newBrushSettings[cdMin], newBrushSettings[key]);
    }

    for (let param in newBrushSettings) {
      if (newBrushSettings[param] === brushDefaults?.[basicBrushLink]?.[param]) {
        delete newBrushSettings[param];
      }
    }

    userSetsRef.current[brushKey] = newBrushSettings;
    forceRender();

    clearTimeout(saveTimerRef.current);
    
    saveTimerRef.current = setTimeout(() => {
      saveUserSets({ [brushKey]: newBrushSettings });
    }, 1000);

  };

  const handleOverlayClick = () =>{
    if (lastActionTimeRef.current < Date.now() - 300) {
      closeMenu(); 
    }
  }

  const handleCloneBrush = () => {

    showPopupMessage({
      title: t('customize.clone_brush_title'),
      message: t('customize.clone_brush_text'),
      input: {
        placeholder: t('customize.clone_brush_placeholder'),
      },
      buttons: [
        { type: 'cancel', text: t('customize.button_cancel')},
        { id: 'submit', type: 'submit', text: t('customize.button_submit')},
      ],

      callback: (buttonId, inputValue) => {
        if (buttonId === 'submit') {
          
          window.croco?.haptic?.();

          const savedBrushes = userSetsRef.current.savedBrushes || [];
          let i = 1;
          let newBrushKey = `my_${basicBrushLink}_${i}`;
          while (savedBrushes.includes(newBrushKey)) {
            i ++;
            newBrushKey = `my_${basicBrushLink}_${i}`;
          }

          savedBrushes.push(newBrushKey);

          const settingsToSave = {
            ...userSetsRef.current[brushKey],
            clone: basicBrushLink,
            label: inputValue,
          }

          delete settingsToSave.default;
          settingsToSave.default = {...settingsToSave}

          saveUserSets({ 
            // [brushKey]: null,
            savedBrushes,
            [newBrushKey]: settingsToSave,
          })
          openMenu('customizeBrush', {brush: newBrushKey, layer: true});
          setBrushType(newBrushKey);
  
        }
      }
    });

  }

  const handleSaveUserBrush = () => {

    showPopupMessage({
      title: t('customize.save_user_brush_title'),
      message: t('customize.save_user_brush_text', {label: currentBrushSettings.label || ''}),
      buttons: [
        { type: 'cancel', text: t('customize.button_cancel')},
        { id: 'save', type: 'submit', text: t('customize.button_save')},
      ],

      callback: (buttonId, inputValue) => {
        if (buttonId === 'save') {
          
          const settingsToSave = userSetsRef.current[brushKey];
          delete settingsToSave.default;
          settingsToSave.default = { ...settingsToSave }

          saveUserSets({ 
            [brushKey]: settingsToSave,
          }, ()=>{
            window.croco?.haptic?.();
            forceRender();
          })
  
        }
      }
    });

  }

  const handleDeleteUserBrush = () => {

    const label = userSetsRef.current[brushKey]?.label || ''

    showPopupMessage({
      title: t('customize.delete_brush_title', { label }),
      message: t('customize.delete_brush_text', { label }),
      buttons: [
        { type: 'cancel', text: t('customize.button_cancel')},
        { id: 'delete', type: 'destructive', text: t('customize.button_delete_confirm')},
      ],

      callback: (buttonId) => {
        if (buttonId === 'delete') {

          let savedBrushes = userSetsRef.current.savedBrushes || [];
          savedBrushes = savedBrushes.filter(currentKey=>currentKey !== brushKey)
          saveUserSets({ 
            savedBrushes,
            [brushKey]: null,
          })
          openMenu('brush');
  
        }
      }
    });

  }

  const handleResetBrush = () => {

    const label = userSetsRef.current[brushKey]?.label || ''

    showPopupMessage({
      title: t('customize.reset_brush_title', { label }),
      message: t('customize.reset_brush_text', { label }),
      buttons: [
        { type: 'cancel', text: t('customize.button_cancel')},
        { id: 'reset', type: 'destructive', text: t('customize.button_reset_confirm')},
      ],
      callback: (buttonId) => {
        if (buttonId === 'reset') {

          const oldBrushSettings = userSetsRef.current[brushKey];

          let restoredBrushSettings = null;

          if (oldBrushSettings?.clone) {
            restoredBrushSettings = { ...(oldBrushSettings?.default || {
              clone: oldBrushSettings.clone,
              label: oldBrushSettings.label,
            }) };
            delete restoredBrushSettings.default;
            restoredBrushSettings.default = { ...restoredBrushSettings} 
          }

          saveUserSets({ 
            [brushKey]: restoredBrushSettings,
          })
          forceRender();
        }
      }
    });

  }

  function seededRandom(seed) {
    let x = Math.sin(seed) * 10000;
    return x - Math.floor(x);
  }

  const updatePreview = async (settings) => {

    if (isUpdatingPreviewRef.current) { return; }
    const canvas = canvasRef.current;

    if (canvas) {
      isUpdatingPreviewRef.current = true;

      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);

      const sets = {...currentBrushSettings};
      const commonSets = brushSetsFun('common');
      Object.assign(sets, commonSets);

      let points = [[18.8,30.1],[18.8,30.1],[19.2,30.1],[19.2,30.1],[19.6,30.1],[19.6,30.1],[20,30.1],[20,29.7],[20.8,29.7],[21.2,29.3],[21.6,29.3],[22.4,28.5],[23.2,28.5],[25.1,27.3],[25.9,26.9],[27.5,26.5],[29.1,25.4],[31.4,24.6],[32.6,24.2],[33.4,23.8],[35,23.4],[35.4,22.6],[36.9,22.2],[37.7,21.8],[38.9,21.4],[39.3,21],[40.1,20.6],[41.7,20.6],[42.5,20.2],[43.2,20.2],[44,19.8],[45.6,19.8],[46.4,19.8],[46.8,19.5],[47.2,19.5],[49.5,19.5],[50.7,19.5],[51.1,19.5],[51.9,19.1],[53.1,19.1],[53.9,19.1],[54.3,19.1],[54.7,19.1],[55,19.1],[55.4,19.5],[55.8,19.5],[56.2,19.5],[56.6,19.8],[56.6,19.8],[57.4,19.8],[57.4,19.8],[57.8,19.8],[58.2,20.2],[58.6,20.2],[59.4,20.6],[59.8,20.6],[60.2,20.6],[60.6,21],[61.3,21],[61.7,21.4],[61.7,21.4],[62.1,21.8],[62.1,21.8],[62.5,21.8],[62.5,21.8],[62.5,21.8],[62.5,22.2],[62.9,22.2],[62.9,22.2],[63.3,22.2],[63.3,22.6],[63.7,22.6],[63.7,23],[64.1,23.8],[64.9,23.8],[64.9,24.6],[65.7,25.4],[66.1,26.1],[66.5,26.5],[66.9,26.9],[68,28.1],[68,28.5],[68.8,28.9],[69.6,29.7],[70,30.1],[70.4,30.5],[71.2,30.9],[72.4,31.7],[73.5,32.4],[74.3,33.2],[74.7,33.6],[76.3,34.4],[77.1,34.8],[78.3,35.6],[79.4,36],[81.8,36.8],[82.6,37.2],[83.4,37.6],[85.4,38.3],[86.1,38.7],[87.3,39.1],[91.3,40.3],[93.6,41.1],[95.2,41.1],[96.8,41.5],[98.3,41.9],[102.7,42.7],[104.6,42.7],[106.6,42.7],[108.2,42.7],[110.9,42.7],[113.7,42.7],[115.3,42.3],[117.6,41.9],[121.6,40.7],[123.9,39.9],[127.5,38],[130.6,36],[132.6,34.4],[134.2,33.2],[136.1,31.7],[139.7,28.1],[140.5,27.3],[140.8,26.5],[141.6,26.5],[142,26.1]].map(point=>({x: point[0], y: point[1]}));

      const smooth = (!sets.speedDependence && !sets.spreading);
      if (smooth) {
        points = filterClosePoints?.(points, Math.round(commonSets.smoothing)) || points;
      }


      const currentLineWidth = Math.min(60, Math.max(36, lineWidth))

      const strokeTemplate = {
        type: 'stroke',
        color: color,
        gradientColor: gradientColorFun(),
        lineWidth: currentBrushView.lineWidth || currentLineWidth,
        softness,
        brush: isEffect ? null : basicBrushLink,
        effect: isEffect ? basicBrushLink : null,
        sets,
      }

      let strokes = [
        {
          points:  points.map(point=>({x: point.x + 38, y: point.y + 35})),
          time: 0,
        },
        {
          points:  points.map(point=>({x: point.x + 13, y: point.y + 15})),
          time: 0 + 1,
        },
      ].map(stroke=> Object.assign({}, strokeTemplate, stroke))


      if (currentBrushView?.singleStroke) { 
        strokes = [
          {
            points:  points.map(point=>({x: point.x + 38, y: point.y + 25})),
            time: 0,
          },
        ].map(stroke=> Object.assign({}, strokeTemplate, stroke));
      }

      if (sets.ragged) {
        // strokes.forEach(stroke=>{
        //   const points = stroke.points;
        //   points.forEach((point, i)=>{
        //     point.pressure = (point.pressure || 1) * Math.exp(-sets.ragged * seededRandom(i));
        //   }) 
        // })
      }

      if (brushKey === 'common') { strokes[0].color = '#AAAAAA' }

      for (let stroke of strokes) {
        context.globalCompositeOperation = stroke.sets?.composition || 'source-over';
        await drawMethodsRef.current?.drawStroke(stroke, context)
      }

      isUpdatingPreviewRef.current = false;


    }
  };

  useEffect(() => {
    updatePreview(currentBrushSettings);
  }, [currentBrushSettings, lineWidth]);


  const drawTextureCanvas = async (textureCanvas, brushKey, textureName) => {
    if (!textureCanvas) return;
  
    const texture = await getTexture(brushKey, textureName, 'stroke');
    if (!texture) { return; }
  
    const context = textureCanvas.getContext('2d');
  
    // Clear the canvas
    context.clearRect(0, 0, textureCanvas.width, textureCanvas.height);
  
    // Compute the drawing dimensions
    const drawWidth = texture.width * canvasScaleRef.current;
    const drawHeight = texture.height * canvasScaleRef.current;
  
    // Draw the texture onto the canvas
    context.drawImage(texture, 0, 0, drawWidth, drawHeight);
  
    // Apply black color using 'source-in' composite operation
    context.globalCompositeOperation = 'source-in';
    context.fillStyle = 'black';
    context.fillRect(0, 0, textureCanvas.width, textureCanvas.height);
  
    // Set composite operation to 'destination-over' to draw white background
    context.globalCompositeOperation = 'destination-over';
    context.fillStyle = 'white';
    context.fillRect(0, 0, textureCanvas.width, textureCanvas.height);
  
    // Reset composite operation to default
    context.globalCompositeOperation = 'source-over';
  };

  const renderSetting = (setting) => {
    
    switch (setting.type) {
      case 'toggle':
        return (
          <div className="settings-item" key={setting.key}>
            <label className="settings-label">
              <input
                type="checkbox"
                checked={currentBrushSettings[setting.key] || false}
                onChange={(e) => handleSettingChange(setting.key, e.target.checked)}
              />
              <span>{t(setting.label)}</span>
            </label>
          </div>
        );

      case 'slider':
        return (
          <div className="settings-item touch-active" key={setting.key}>
            <label className="settings-label touch-active">
              <span>{t(setting.label)}: {currentBrushSettings[setting.key]}</span>
            </label>
            <input
              type="range"
              className="customize-brush-slider touch-active"
              min={setting.min}
              max={setting.max}
              step={setting.step}
              value={currentBrushSettings[setting.key] || setting.min}
              onChange={(e) => handleSettingChange(setting.key, Number(e.target.value))}
              onMouseDown={(e) => { 
                if (isAppleDevice()) { e.preventDefault()  }
              }}
            />
          </div>
        );
      case 'select':
        return (
          <div className="settings-item" key={setting.key}>
            <label className="settings-label">
              <span>{t(setting.label)}</span>
            </label>
            <select
              value={currentBrushSettings[setting.key] || setting.options[0].value}
              onChange={(e) => handleSettingChange(setting.key, e.target.value)}
              className="customize-brush-select touch-active"
            >
              {setting.options.map((option) => (
                <option value={option.value} key={option.value}>
                  {t(option.label)}
                </option>
              ))}
            </select>
          </div>
        );

        case 'color':
        return (
          <div
            className="settings-item touch-active"
            key={setting.key}
            onClick={() => setColorPickerState({ isOpen: true, key: setting.key })}
          >
            <label
              className="settings-label"
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              <span
                className="settings-color-indicator"
                style={{
                  backgroundColor: currentBrushSettings[setting.key] || '#000000',
                }}
              ></span>
              <span>{t(setting.label)}</span>
            </label>
          </div>
        );
   
      default:
        return null;
    }
  };


  const TextureSelector = () => (
    <div className="texture-selection">
            <span className="section-title">{t('customize.prop.texture', { textureName: currentBrushSettings.texture })}</span>
            <div className="texture-options">
              {textureNames.map((textureName) => (
                <div
                  key={textureName}
                  className={`texture-option ${currentBrushSettings.texture === textureName ? 'active' : ''}`}
                  onClick={() => handleSettingChange('texture', textureName)}
                >
                  <canvas
                    ref={(textureCanvas) => drawTextureCanvas(textureCanvas, basicBrushLink, textureName)}
                    width={50}
                    height={50}
                    { ...createBetterClick({
                      onClick: () => handleSettingChange('texture', textureName),
                      onContextMenu: () => handleSettingChange('texture', textureName),
                    }) }
                  />
                </div>
              ))}
            </div>
          </div>
  );


  return (
    <>
      <div className="overlay-background" onClick={handleOverlayClick}></div>
      <div className="customize-brush-menu scroll-active touch-active">
        <span className="customize-brush-title">
          {currentBrushSettings.label ||  t(`brush.${brushKey}.name`)}
        </span>
        { !currentBrushView.removeBrushPreview && (
          <div className = 'customize-brush-preview-row'>
            <div 
              onClick={()=>{setUserColorView(true)}}
              style={{ 
              width: `${250 * canvasScaleRef.current}px`,
              height: `${120 * canvasScaleRef.current}px`,
              overflow: 'hidden',
            }}>
              <canvas 
                ref={canvasRef} 
                width="250" 
                height="120" 
                style={{ 
                  transform: `scale(${canvasScaleRef.current})`,
                  transformOrigin: 'top left',
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                }}
              />
            </div>
            <BrushSizeControl 
              style = {{
                right: '-50px',
              }}
              gameContext={gameContext}
              brushContext={brushContext} 
              canvasContext={drawingContext}
              icon={<FaPencilRuler />}
              defaultBrushSize = {36}
              hideButton = {true}
              hideInidicator = {true}
            />
          </div>
        )}
        
        {currentBrushParams.map(renderSetting)}
        {hasTextures && TextureSelector()}

        <button 
          className="wide-button" 
          onClick={handleResetBrush}
          style={{
            opacity: brushIsCustomized ? 1 : 0.5,
          }}
          >
            {t('customize.button_reset')}
          </button>

        { isUserBrush && (
          <div className="icon-button-row">
            <button className="icon-button" onClick={handleCloneBrush}>
              <FaClone className='buttons-icon'/>
            </button>
            <button className="icon-button" onClick={handleSaveUserBrush} style={{ opacity: brushIsCustomized ? 1 : 0.5, }}>
              <FaFloppyDisk className='buttons-icon'/>
            </button>
            <button className="icon-button" onClick={handleDeleteUserBrush}>
              <FaTrash className='buttons-icon'/>
            </button>
          </div>
          ) }

        { !isUserBrush 
        && brushKey !== 'common' 
        && !menuParamRef.current.effect 
        && !currentBrushView.removeCustomSave 
        && (
            <button 
              className="wide-button" 
              onClick={handleCloneBrush}
              style={{
                // opacity: brushIsCustomized ? 1 : 0.5,
              }}
              >
                <FaClone style={{ marginRight: '8px', marginBottom: '3px', verticalAlign: 'middle'}}/>
                {t('customize.button_clone')}
            </button>
          ) 
        }

        <div className="button-row">
          <button className="small-gray-button" onClick={()=>{openMenu(menuParamRef.current?.backMenu || 'brush')}}>
            {t('customize.button_back')}
          </button>
          <button className="small-gray-button" onClick={()=>{closeMenu()}}>
            {t('customize.button_close')}
          </button>
        </div>

        <button className="close-button" onClick={()=>{closeMenu()}}>&#10006;</button>

        { userColorView && (
          <>
            <div className="overlay-background" style={{zIndex: 1400}} onClick={()=>setUserColorView(false)}></div>
            <div className="settings-color-picker-container">
              <HexAlphaColorPicker 
                color={color} 
                onChange={(newColor)=>{ setTheColorFun(newColor); }}
              />
              <button className="wide-button" onClick={()=>setUserColorView(false)} style={{marginTop: '10px'}}>
                {t('settings.set_color')}
              </button>
            </div>
          </>
        ) }

        {colorPickerState.isOpen && (
          <>
            <div
              className="overlay-background"
              style={{ zIndex: 1400 }}
              onClick={() => setColorPickerState({ isOpen: false, key: null })}
            ></div>
            <div className="settings-color-picker-container">
              <HexAlphaColorPicker
                color={currentBrushSettings[colorPickerState.key] || '#000000'}
                onChange={(newColor) => handleSettingChange(colorPickerState.key, newColor)}
              />
              <button
                className="wide-button"
                onClick={() => setColorPickerState({ isOpen: false, key: null })}
                style={{ marginTop: '10px' }}
              >
                {t('settings.set_color')}
              </button>
            </div>
          </>
        )}
      
      </div>
    </>
  );
}

export default CustomizeBrush;