import chroma from 'chroma-js';
import { brushDefaults } from './helpers/brushLoader';
import { fillGradient } from './helpers/gradient';

const defaultBrushSettings = brushDefaults.bristle || {};

export async function drawBristleStroke({
  color,
  gradientColor,
  lineWidth,
  points,
  time,
  sets = {},
}, context) {

  if (points.length === 0) return;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);

  const {
    opacity = 0.5,
    wobble = 0.05,
  } = brushSettings;
  

  const chromaColor = chroma(color);
  const originalAlpha = chromaColor.alpha();

  let bristleColor;
  if (gradientColor) {
    bristleColor = chroma('black').alpha(opacity / 2).hex();
  } else {
    bristleColor = chromaColor.alpha(opacity / 2).hex();
  }

  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  let bristleCount = 250;
  let bristleWidth = lineWidth / 15;
  let wobbleAmount = lineWidth * wobble;

  const randomnessFactor = 0.3; // Контролирует степень случайности (0-1)
  const offsets = generateBristleOffsets(time, lineWidth, bristleCount, randomnessFactor);

  bufferCtx.lineCap = 'round';
  bufferCtx.lineJoin = 'round';

  offsets.forEach(offset => {
    drawOneBristle({
      points,
      lineWidth: bristleWidth, // Уменьшаем толщину каждой линии
      mainLineWidth: lineWidth,
      color: bristleColor,
      time,
      offset,
      wobbleAmount,
      shape: brushSettings.shape,
    }, bufferCtx);
  });

  if (gradientColor) {
    fillGradient(bufferCtx, points, color, gradientColor, lineWidth);
    context.globalAlpha = 1;
  } else {
    context.globalAlpha = originalAlpha;
  }

  // Рисуем буфер на основном холсте
  context.drawImage(bufferCanvas, 0, 0);
  context.globalAlpha = 1; // Возвращаем прозрачность
}

// Простая функция для генерации псевдослучайных чисел
function seededRandom(seed) {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

// Функция для генерации отступов ворсинок и порогов давления
function generateBristleOffsets(time, lineWidth, bristleCount, randomnessFactor) {
  const baseRadius = lineWidth * 0.5 * 0.9;
  let offsets = [];

  for (let i = 0; i < bristleCount; i++) {
    const angle = seededRandom(time + i) * Math.PI * 2;
    const distance = seededRandom(time + i + 100) * baseRadius;

    const x = Math.cos(angle) * distance;
    const y = Math.sin(angle) * distance;

    const randX = (seededRandom(time + i + 200) - 0.5) * 2 * randomnessFactor * baseRadius;
    const randY = (seededRandom(time + i + 300) - 0.5) * 2 * randomnessFactor * baseRadius;
    
    const pressureThreshold = seededRandom(time + i + 500); // Пороговое значение давления от 0 до 1

    offsets.push({
      x: x + randX,
      y: y + randY,
      distance: Math.sqrt((x + randX) ** 2 + (y + randY) ** 2),
      pressureThreshold, // Добавляем пороговое давление для ворсинки
    });
  }

  // Фильтруем ворсинки в центре
  const innerThreshold = baseRadius * 2 / 3;
  const innerBristles = offsets.filter(offset => offset.distance < innerThreshold);
  const outerBristles = offsets.filter(offset => offset.distance >= innerThreshold);

  // Оставляем только половину внутренних ворсинок
  const keptInnerBristles = innerBristles
    .sort(() => seededRandom(time + 400) - 0.5)
    .slice(0, Math.ceil(innerBristles.length * 0.5));

  // Объединяем отфильтрованные внутренние ворсинки с внешними
  offsets = [...keptInnerBristles, ...outerBristles];

  // Добавляем центральную ворсинку с нулевым порогом давления
  offsets.push({ x: 0, y: 0, distance: 0, pressureThreshold: 0 });

  return offsets;
}

function drawOneBristle(stroke, context) {

  const {
    points, 
    lineWidth, 
    mainLineWidth, 
    color, 
    offset = { x: 0, y: 0 }, 
    plusWidth, 
    wobbleAmount, 
    time, 
    shape,
  } = stroke;

  const {
    pressureThreshold,
  } = offset;

  if (points.length === 1) {
    return drawPoint(stroke, context);
  } 

  context.strokeStyle = color;
  context.lineWidth = lineWidth;

  let isDrawingSegment = false;
  const offsetSeed = offset.x * offset.y;

  for (let i = 1; i < points.length; i++) {
    const prevPoint = points[i - 1];
    const currPoint = points[i];

    const pressure = currPoint.pressure !== undefined ? currPoint.pressure : 1;

    if (pressure >= pressureThreshold) {
      const wobbleX = (seededRandom(time + offsetSeed + i + 200) - 0.5) * wobbleAmount;
      const wobbleY = (seededRandom(time + offsetSeed + i + 300) - 0.5) * wobbleAmount;

      const midPoint = {
        x: (prevPoint.x + currPoint.x + wobbleX) / 2,
        y: (prevPoint.y + currPoint.y + wobbleY) / 2
      };

      if (!isDrawingSegment) {
        context.beginPath();
        context.moveTo(prevPoint.x + offset.x, prevPoint.y + offset.y);
        isDrawingSegment = true;
      }

      if (i < points.length -1) {
        context.quadraticCurveTo(
          prevPoint.x + offset.x,
          prevPoint.y + offset.y,
          midPoint.x + offset.x,
          midPoint.y + offset.y,
        );
      } else {
        context.lineTo(
          currPoint.x + offset.x, 
          currPoint.y + offset.y, 
        );
      }

    } else {
      if (isDrawingSegment) {
        context.stroke();
        isDrawingSegment = false;
      }
    }
    
  }

  if (isDrawingSegment) {
    context.stroke();
  }
}

function drawPoint({
  points, lineWidth, color, offset, plusWidth, pressureThreshold,
}, context) {

  const point = points[0];
  const pressure = point.pressure !== undefined ? point.pressure : 1;

  if (pressure >= pressureThreshold) {
    // Для одиночной точки рисуем круг
    context.beginPath();
    context.arc(
      point.x + offset.x,
      point.y + offset.y,
      (lineWidth / 2) + plusWidth,
      0,
      Math.PI * 2
    );
    context.fillStyle = color;
    context.fill();
  }
}
