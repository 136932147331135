// shapeRecognizer.js
import { ShapeType } from './shapeTypes.js';
import { MathUtils } from './mathUtils.js';

export class ShapeRecognizer {
  constructor(points, options = {}) {
    this.points = points;
    this.options = {
      // Допускаемый процент отклонения (для линии — от длины,
      // для окружности — от среднего радиуса)
      lineDeviationFactor: 0.1,
      circleDeviationFactor: 0.5,

      closureDistanceFactor: 0.1, // Насколько близко первая и последняя точки 
                                  // должны располагаться, чтобы считать фигуру замкнутой
      // Порог для сравнения ширины/высоты при "квадратности"
      squareAspectRatioTolerance: 0.2, // 20%

      // Порог, насколько близко угол должен быть к углу bounding box
      cornerDistanceFactor: 0.1, // 0.1 от периметра

      ...options
    };
  }

  recognize() {
    if (!this.points || this.points.length < 2) {
      return { type: ShapeType.NONE, points: this.points };
    }

    // 1) Линия
    const maybeLine = this.checkLine();
    if (maybeLine.isLine) {
      return {
        type: ShapeType.LINE,
        points: maybeLine.points
      };
    }

    // 2) Окружность
    const maybeCircle = this.checkCircle();
    // 3) Многоугольник
    const maybePolygon = this.checkPolygon();

    if (maybePolygon.isRectangle) {
      return {
        type: ShapeType.POLYGON,
        corners: maybePolygon.corners,
        points: this.points
      };
    }

    if (maybeCircle.isCircle) {
      return {
        type: ShapeType.CIRCLE,
        center: maybeCircle.center,
        radius: maybeCircle.radius,
        points: this.points
      };
    }

    if (maybePolygon.isPolygon) {
      return {
        type: ShapeType.POLYGON,
        corners: maybePolygon.corners,
        points: this.points
      };
    }

    // const maybePolyline = this.checkPolyline();
    // if (maybePolyline.isPolyline) {
    //   return {
    //     type: ShapeType.POLYLINE,
    //     corners: maybePolyline.corners,
    //     points: this.points
    //   };
    // }


    // Иначе NONE
    return { type: ShapeType.NONE, points: this.points };
  }

  checkLine() {
    const first = this.points[0];
    const last = this.points[this.points.length - 1];
    const distLine = MathUtils.distance(first, last);
    if (distLine === 0) {
      return { isLine: false };
    }
    const maxAllowedDeviation = this.options.lineDeviationFactor * distLine;

    for (const p of this.points) {
      const distToLine = MathUtils.distancePointToLine(p, first, last);
      if (distToLine > maxAllowedDeviation) {
        return { isLine: false };
      }
    }

    return {
      isLine: true,
      points: this.points
    };
  }

  checkCircle() {
    if (this.points.length < 3) {
      return { isCircle: false };
    }

    const center = MathUtils.boundingCenter(this.points);
    const distances = this.points.map((p) => MathUtils.distance(center, p));
    const sum = distances.reduce((acc, val) => acc + val, 0);
    const avgRadius = sum / distances.length;
    if (avgRadius === 0) {
      return { isCircle: false };
    }

    const maxAllowedDeviation = this.options.circleDeviationFactor * avgRadius;
    for (const r of distances) {
      if (Math.abs(r - avgRadius) > maxAllowedDeviation) {
        return { isCircle: false };
      }
    }

    const first = this.points[0];
    const last = this.points[this.points.length - 1];
    const distFirstLast = MathUtils.distance(first, last);
    if (distFirstLast > avgRadius / 2) {
      return { isCircle: false };
    }

    let maxAngle = 0;
    for (
      let i = Math.floor(this.points.length * 0.1) + 1; 
      i < Math.floor(this.points.length * 0.7) - 1; 
      i++
      ) {
      const p0 = this.points[i - 4] || this.points[i - 2] || this.points[i - 1];
      const p1 = this.points[i];
      const p2 = this.points[i + 4] || this.points[i + 2] || this.points[i + 1];
      const angle = MathUtils.angleBetweenPoints(p0, p1, p2);
      maxAngle = Math.max(maxAngle, angle)
    }

    if (maxAngle > 1.5) { return { isCircle: false }; }

    return {
      isCircle: true,
      center,
      radius: avgRadius
    };
  }

  checkPolygon() {
    if (this.points.length < 3) {
      return { isPolygon: false };
    }

    const totalDist = this.getTotalLength(this.points);
    const first = this.points[0];
    const last = this.points[this.points.length - 1];
    const distFirstLast = MathUtils.distance(first, last);

    if (distFirstLast > this.options.closureDistanceFactor * totalDist) {
      return { isPolygon: false };
    }

    // Собираем «угловые» точки
    const lower = 0.5; // радианы ~ 28°, настраивайте под себя
    let corners = [this.points[0]];

    for (let i = 1; i < this.points.length - 1; i++) {
      const p0 = this.points[i - 4] || this.points[i - 2] || this.points[i - 1];
      const p1 = this.points[i];
      const p2 = this.points[i + 4] || this.points[i + 2] || this.points[i + 1];
      const angle = MathUtils.angleBetweenPoints(p0, p1, p2);

      if (angle > lower) {
        corners.push(p1);
      }
    }

    if (corners.length > 35) { return { isPolygon: false } }

    if (corners.length >= 2 && corners.length < 20) {

      // добавим логику проверки "ровного прямоугольника"
      const perimeter = this.getTotalLength(this.points);

      // Проверим, не является ли этот полигон "ровным прямоугольником" 
      // (и при необходимости, квадратом)
      const rectResult = this.checkRectangleAndSquare(corners, perimeter);

      if (rectResult.isRectangle) {
        // Применим обновлённые (ровные) углы
        corners = rectResult.newCorners;
        return {
          isPolygon: true, 
          isRectangle: true,
          corners,
        };
      }

      return { isPolygon: true, corners };

    }


    return { isPolygon: false };
  }

  checkPolyline() {
    if (this.points.length < 3) {
      return { isPolygon: false };
    }

    const lower = 0.5; // радианы ~ 28°, настраивайте под себя
    let corners = [this.points[0]];

    for (let i = 1; i < this.points.length - 1; i++) {
      const p0 = this.points[i - 4] || this.points[i - 2] || this.points[i - 1];
      const p1 = this.points[i];
      const p2 = this.points[i + 4] || this.points[i + 2] || this.points[i + 1];
      const angle = MathUtils.angleBetweenPoints(p0, p1, p2);

      if (angle > lower) {
        corners.push(p1);
      }
    }

    if (corners.length > 1) {
      corners.push(this.points[this.points.length - 1]);
      return { isPolyline: true, corners }
    }

    return { isPolyline: false };
  }

  // ---- ДОБАВЛЕННЫЙ МЕТОД ----
  /**
   * Проверка "ровного прямоугольника" и "квадратности"
   *
   * Логика:
   * 1) corners.length === 4? (если нет - не прямоугольник)
   * 2) Находим bounding box: (minX, maxX, minY, maxY)
   * 3) Смотрим расстояние от каждого из corners[] к БЛИЖАЙШЕМУ углу box'a.
   *    Если оно <= (cornerDistanceFactor * perimeter), то всё ОК.
   *    (Можно более изощрённо сопоставлять, но для простоты — берём "минимальное" расстояние 
   *     до любого угла box'а. Все 4 угла должны быть рядом с 4мя точками corners.)
   * 4) Если ок — значит "ровный" прямоугольник, corners заменяем углами bounding box (в порядке обхода).
   * 5) Проверяем квадратность: (width, height) отличаются не более чем на 20%.
   *    - Если да: берём side = (width+height)/2 / 2, делаем квадрат (minX, minY, side) ...
   *      возвращаем 4 угла нового квадрата.
   */
  checkRectangleAndSquare(corners, perimeter) {

    // Вычисляем bounding box
    const { minX, maxX, minY, maxY } = this.getBoundingBox(this.points);
    const boxCorners = [
      { x: minX, y: minY },
      { x: minX, y: maxY },
      { x: maxX, y: maxY },
      { x: maxX, y: minY }
    ];
    let antiRoundCorners = [
      { x: minX, y: minY },
      { x: minX, y: maxY },
      { x: minX, y: maxY },
      { x: maxX, y: maxY },
      { x: maxX, y: maxY },
      { x: maxX, y: minY },
      { x: maxX, y: minY },
    ];

    const cornerDistanceLimit = this.options.cornerDistanceFactor * perimeter;

    // Проверим, что каждая из corner'ов близка к КАКОМУ-ТО углу bounding box
    // В упрощённом виде: 
    //   Для corner[i] ищем min( dist(corner[i], boxCorner[j]) ), j=0..3
    //   Если minDist > cornerDistanceLimit => не подходит
    const foundInCorners = {};

    for (let i = 0; i < corners.length; i++) {
      const c = corners[i];
      let minDist = Infinity;

      for (let j = 0; j < boxCorners.length; j++) {
        const d = MathUtils.distance(c, boxCorners[j]);
        if (d < minDist) {
          minDist = d;
          if (minDist <= cornerDistanceLimit) {
            foundInCorners[j] = true;
          }
        }
      }
      if (minDist > cornerDistanceLimit) {
        return { isRectangle: false, newCorners: corners };
      }
    }

    if (Object.keys(foundInCorners).length < 4) { 
      return { isRectangle: false, newCorners: corners };
    }

    // Если дошли сюда — считаем, что это "ровный прямоугольник"
    let newCorners = boxCorners; // На место corners ставим углы bounding box

    // 5) Проверка "квадратности":
    const width = maxX - minX;
    const height = maxY - minY;
    if (width <= 0 || height <= 0) {
      return { isRectangle: true, newCorners: antiRoundCorners };
    }

    const ratio = width / height;
    // Если ratio ~1 => квадрат
    // Проверка: если 1/ratio в [0.8..1.2], то значит в пределах 20%
    // Проще: 1/1.2 ~ 0.83... => можно сравнить ratio с [1 - 0.2..1 + 0.2]
    //   => 0.8..1.2
    if (ratio > (1 - this.options.squareAspectRatioTolerance) 
      && ratio < (1 + this.options.squareAspectRatioTolerance)) {
      // Это "почти квадрат"
      const midX = (minX + maxX) / 2;
      const midY = (minY + maxY) / 2;
      const side = Math.min(width, height); 
      // halfSide
      const half = side / 2;

      // Пересобираем углы квадрата
      newCorners = [
        { x: midX - half, y: midY - half },
        { x: midX - half, y: midY + half },
        { x: midX + half, y: midY + half },
        { x: midX + half, y: midY - half }
      ];
      antiRoundCorners  = [
        { x: midX - half, y: midY - half },
        { x: midX - half, y: midY + half },
        { x: midX - half, y: midY + half },
        { x: midX + half, y: midY + half },
        { x: midX + half, y: midY + half },
        { x: midX + half, y: midY - half },
        { x: midX + half, y: midY - half },
      ];
    }

    return { isRectangle: true, newCorners: antiRoundCorners };
  }

  getBoundingBox(points) {
    let minX = Infinity, maxX = -Infinity;
    let minY = Infinity, maxY = -Infinity;
    for (const p of points) {
      if (p.x < minX) minX = p.x;
      if (p.x > maxX) maxX = p.x;
      if (p.y < minY) minY = p.y;
      if (p.y > maxY) maxY = p.y;
    }
    return { minX, maxX, minY, maxY };
  }

  getTotalLength(points) {
    let sum = 0;
    for (let i = 0; i < points.length - 1; i++) {
      sum += MathUtils.distance(points[i], points[i + 1]);
    }
    return sum;
  }
}
