// themeColors.js
import chroma from 'chroma-js';

export function applyThemeColors(themeParams = {}) {
    // Основные цвета темы
    document.documentElement.style.setProperty('--tg-theme-bg-color', themeParams.bg_color || '#FFFFFF');
    document.documentElement.style.setProperty('--tg-theme-secondary-bg-color', themeParams.secondary_bg_color || '#F2F2F2');
    document.documentElement.style.setProperty('--tg-theme-text-color', themeParams.text_color || '#000000');
    document.documentElement.style.setProperty('--tg-theme-link-color', themeParams.link_color || '#0000EE');
    document.documentElement.style.setProperty('--tg-theme-button-color', themeParams.button_color || '#40a7e3');
    document.documentElement.style.setProperty('--tg-theme-button-text-color', themeParams.button_text_color || '#FFFFFF');
    document.documentElement.style.setProperty('--tg-theme-hint-color', themeParams.hint_color || '#828282');
    document.documentElement.style.setProperty('--tg-theme-accent-text-color', themeParams.accent_text_color || '#168acd');
    document.documentElement.style.setProperty('--tg-theme-destructive-text-color', themeParams.destructive_text_color || '#d14e4e');

    // Прозрачный фон
    document.documentElement.style.setProperty('--tg-theme-bg-color-transparent', chroma(themeParams.bg_color || '#FFFFFF').alpha(0.1).css());

    // Применение цвета фона к body
    // document.body.style.backgroundColor = themeParams.bg_color || 'gray';
    document.body.style.backgroundColor = themeParams.bg_color || 'white';
}

export function applyAdditionalShades(cssVarName, intensity = 1) {
  // Получаем текущее значение CSS-переменной
  const baseColor = getComputedStyle(document.documentElement).getPropertyValue(cssVarName).trim();
  const baseChroma = chroma(baseColor);

  // Создаем красноватый вариант
  const redColor = baseChroma.set('rgb.r', Math.min(baseChroma.get('rgb.r') + 30, 255));
  document.documentElement.style.setProperty(`${cssVarName}-reddish`, redColor.css());

  // Создаем синеватый вариант
  const blueColor = baseChroma.set('rgb.b', Math.min(baseChroma.get('rgb.b') + 30, 255));
  document.documentElement.style.setProperty(`${cssVarName}-bluish`, blueColor.css());

  // Создаем зеленоватый вариант
  const greenColor = baseChroma.set('rgb.g', Math.min(baseChroma.get('rgb.g') + 30, 255));
  document.documentElement.style.setProperty(`${cssVarName}-greenish`, greenColor.css());
}

export function applyAdditionalColors(cssVarName) {
  // Получаем текущее значение CSS-переменной
  const baseColor = getComputedStyle(document.documentElement).getPropertyValue(cssVarName).trim();
  const baseChroma = chroma(baseColor);

  // Получаем насыщенность (saturation) и светлоту (lightness) исходного цвета
  const [h, s, l] = baseChroma.hsl();

  // Создаем красный вариант (тон 0)
  const redColor = chroma.hsl(0, s, l);
  document.documentElement.style.setProperty(`${cssVarName}-red`, redColor.css());

  // Создаем синий вариант (тон 240)
  const blueColor = chroma.hsl(240, s, l);
  document.documentElement.style.setProperty(`${cssVarName}-blue`, blueColor.css());

  // Создаем зеленый вариант (тон 120)
  const greenColor = chroma.hsl(120, s, l);
  document.documentElement.style.setProperty(`${cssVarName}-green`, greenColor.css());
}