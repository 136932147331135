import { useState, useRef } from 'react';

import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';

export const useHotMouse = ({
  getCanvasPoint,
}) => {

  const canvasContext = useDrawingContext ();
  const brushContext = useBrushContext();

  const {
    zoomFactorRef,
    setZoomFactor,
    setPanOffset,

    canvasDimensionsRef,
    canvasScaleRef,

    maxZoomFactorRef,
    minZoomFactorRef,
    panOffsetRef,

  } = canvasContext;

  const {
    hotMouseStartPositionRef,

    lineWidthRef, setTheSizeFun,
    softness, setSoftness,
  } = brushContext;


  const startLineWidth = useRef();
  const startSoftness = useRef();

  const startZoomFactor = useRef();
  const initialOffsetRef = useRef({x: 0, y: 0});
  const movingStartPointRef = useRef(null);

  function altRightClickStart ({ nativeEvent }) {

    hotMouseStartPositionRef.current = {
      x: nativeEvent.clientX,
      y: nativeEvent.clientY,
    }

    startLineWidth.current = lineWidthRef.current;
    startSoftness.current = softness;
    
  }

  function altRightClickMove ({ nativeEvent }) {

    const x = nativeEvent.clientX;
    const y = nativeEvent.clientY;

    const diffY = hotMouseStartPositionRef.current.y - y;
    const diffX = x - hotMouseStartPositionRef.current.x;

    let softnessDifY = 0;
    let lineWidthDifx = 0;

    if (Math.abs(diffY) - Math.abs(diffX) > 0) {
      softnessDifY = diffY > 0 ?
      (Math.abs(diffY) - Math.abs(diffX)) :
      - (Math.abs(diffY) - Math.abs(diffX)) ;
    } else {
      lineWidthDifx = diffX > 0 ?
      (Math.abs(diffX) - Math.abs(diffY)) :
      - (Math.abs(diffX) - Math.abs(diffY)) ;
    }

    let newLineWidth = startLineWidth.current + lineWidthDifx * 1.4 / zoomFactorRef.current;
    newLineWidth = Math.max(1, newLineWidth);
    setTheSizeFun(newLineWidth);


    let newSoftness = startSoftness.current + softnessDifY * 0.003;
    // newSoftness = Math.min(Math.max(0, newSoftness), 0);
    newSoftness = Math.min(Math.max(0, newSoftness), 0.3);
    setSoftness(newSoftness);
    
  }

  function startCtrlClickMove ({ nativeEvent }) {

    hotMouseStartPositionRef.current = {
      x: nativeEvent.clientX,
      y: nativeEvent.clientY,
    }

    startZoomFactor.current = zoomFactorRef.current;
    initialOffsetRef.current = panOffsetRef.current || {x: 0, y: 0};
    
  }

  function moveCtrlClickMove ({ nativeEvent }) {

    const x = nativeEvent.clientX;
    const y = nativeEvent.clientY;

    const diffY = hotMouseStartPositionRef.current.y - y;
    const diffX = x - hotMouseStartPositionRef.current.x;

    const coef = startZoomFactor + diffY < 1 ? 0.02 : 0.05;

    let newZoomFactor = startZoomFactor.current + diffY * coef;
    newZoomFactor = Math.max(newZoomFactor, minZoomFactorRef.current);
    newZoomFactor = Math.min(newZoomFactor, maxZoomFactorRef.current);

    if (newZoomFactor < 1.1 && newZoomFactor > 0.8) {newZoomFactor = 1;}

    const canvasPoint = getCanvasPoint(hotMouseStartPositionRef.current) ; 
    // const canvasPoint = getCanvasPoint({x, y}) ; // Текущая позиция мыши

    let newZoomOffset = {
      x: (canvasPoint.x * canvasScaleRef.current * (zoomFactorRef.current - newZoomFactor)) + panOffsetRef.current.x,
      y: (canvasPoint.y * canvasScaleRef.current * (zoomFactorRef.current - newZoomFactor)) + panOffsetRef.current.y,
    }

    if (newZoomFactor === 1) {
      newZoomOffset = {x: 0, y: 0}
    }
   
    // Ограничение panOffset
    setZoomFactor(newZoomFactor);

    const maxOffset = newZoomFactor < 1 ? 400 : 150;

    const max = {
      x: maxOffset,
      y: maxOffset,
    }
    const min = {
      x: -maxOffset -((canvasDimensionsRef.current.width) * (zoomFactorRef.current - 1)) * canvasScaleRef.current * 1,
      y: -maxOffset -((canvasDimensionsRef.current.height) * (zoomFactorRef.current - 1)) * canvasScaleRef.current * 1,
    }

    newZoomOffset.x = Math.max(min.x, newZoomOffset.x)
    newZoomOffset.x = Math.min(max.x, newZoomOffset.x)
    newZoomOffset.y = Math.max(min.y, newZoomOffset.y)
    newZoomOffset.y = Math.min(max.y, newZoomOffset.y)

    setPanOffset(newZoomOffset);
    setZoomFactor(newZoomFactor);
    
  }

  return {
    altRightClickStart,
    altRightClickMove,

    startCtrlClickMove,
    moveCtrlClickMove,
  };
};