import { useCallback, useEffect, useRef, useState } from 'react';

import { useDrawingContext } from '../../contexts/DrawingContext';
import { useBrushContext } from '../../contexts/BrushContext';
import { useGameContext } from '../../contexts/GameContext';

export const usePointer = (
  {

    wipeTempCanvas,
  
    startDrawing,
    draw,
    finishDrawing,
  
    startPickingColorFromCanvas,
    searchForColorFromCanvas,
    pickColorFromCanvas,
  
    fill,
  
    changeViewStart,
    changeViewMove,
    simpleZoom,
    ifViewWasChanged,
  
    // Touch
    
    touchZoomStart,
    touchZoomMove,
    touchZoomEnd,
    resetZoomStart,
    resetZoomEnd,
  
    // SHAPES
    handleShapeStart,
    handleShapeMove,
    handleShapeEnd,

    // SHAPE Transformer
    handleTransformStart,
    handleTransformMove,
    handleTransformEnd,
  
    // HotMouse
    altRightClickStart,
    altRightClickMove,
    startCtrlClickMove,
    moveCtrlClickMove,
  
    // TEST
    getEventPos,
  
    straightLine,
    applyBlurAtTouch,
  
  },
  { socket }
) => {
  const pointerListenersAreSet = useRef(false);
  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();
  const gameContext = useGameContext();

  const { 
    openMenu,
    canDraw,
    serverTimeFun,

    userSetsRef,
   } = gameContext;

  const {
    tempCanvasRef,

    setMousePosition,
    mousePositionRef,
    mouseIsPressedRef,

    setMouseDetected,
    isZoomingRef,

    isStylusHoveringRef,
    isDrawingRef,

  } = canvasContext;

  const {

    lastPenActivityRef,

    showPaletteRef,
    activeToolRef,
    effectTypeRef,

    brushTypeRef,

    controlIsDownRef,
    altIsDownRef,
    spaceIsDownRef,
    tabIsDownRef,
    shiftIsDownRef,

    drawingShapeRef,
    shapeTransformerRef,

    hotMouseActiveRef,

    magnifierOn,
  } = brushContext;

  const handlePointerDown = (nativeEvent) => {

    if (!canDraw) { return; }
    if (
      !nativeEvent.target.closest('.temp-canvas')
      && !nativeEvent.target.closest('.backing-canvas')
    ) { return; }

    if (['mouse', 'pen'].includes(nativeEvent.pointerType)) {

      if (nativeEvent.button === 1) {
        nativeEvent.preventDefault();
        return;
      }

      const position = { x: nativeEvent.clientX, y: nativeEvent.clientY };
      setMousePosition(position);
      mousePositionRef.current = position;

      mouseIsPressedRef.current = true;

      if (controlIsDownRef.current) {
        startCtrlClickMove({ nativeEvent })
      } else if (altIsDownRef.current && nativeEvent.button === 2) {
        altRightClickStart({ nativeEvent });
      } else if (tabIsDownRef.current) {
        straightLine({ nativeEvent });
      } else if (
        !tabIsDownRef.current &&
        !altIsDownRef.current &&
        (controlIsDownRef.current || spaceIsDownRef.current || nativeEvent.button === 2)
      ) {
        if (nativeEvent.target.closest('.temp-canvas')) {
          changeViewStart({ nativeEvent });
        }
      } else if (activeToolRef.current === 'pipette') {
        startPickingColorFromCanvas({ nativeEvent });
      } else if (activeToolRef.current === 'brush' && brushTypeRef.current === 'shape') {
        handleShapeStart({ nativeEvent });
      } else if (activeToolRef.current === 'effect' && effectTypeRef.current === 'filler') {
        // Do nothing
      } else {
        startDrawing({ nativeEvent });
      }

    } else if (nativeEvent.pointerType === 'touch') {

      setMouseDetected(false);

      if (activeToolRef.current === 'effect' && effectTypeRef.current === 'filler') {
        // Do nothing
      } else if (activeToolRef.current === 'pipette') {
        startPickingColorFromCanvas({ nativeEvent });
      } else if (activeToolRef.current === 'brush' && brushTypeRef.current === 'shape') {
        handleShapeStart({ nativeEvent });
      } else {
        if (
          lastPenActivityRef.current < Date.now() - 5000
          && !userSetsRef.current.fingerOff
          ) {
          startDrawing({nativeEvent});
        }
      }
   
    }
  };

  const handlePointerMove = (nativeEvent) => {
    
    const pointerType = nativeEvent.pointerType;

    if (!canDraw) { return; }

    if (pointerType === 'pen') {
      lastPenActivityRef.current = Date.now();
    }

    const position = { x: nativeEvent.clientX, y: nativeEvent.clientY };
    setMousePosition(position);
    mousePositionRef.current = position;

    if (
      !nativeEvent.target.closest('.temp-canvas')
      && !nativeEvent.target.closest('.backing-canvas')
    ) { return; }                   


    if (pointerType === 'touch') {

      setMouseDetected(false);

      if (drawingShapeRef.current) {
        handleShapeMove({ nativeEvent });
      } else if (shapeTransformerRef.current) {
        handleTransformMove({ nativeEvent });
      } else if (activeToolRef.current === 'pipette') {
        searchForColorFromCanvas({ nativeEvent });
      } else {
        draw({nativeEvent});
      }

    } else {

      setMouseDetected(true);

      if (showPaletteRef.current) {
        return;
      }
      if (!nativeEvent.buttons) {
        return;
      }

      if (drawingShapeRef.current) {
        handleShapeMove({ nativeEvent });
      } else if (shapeTransformerRef.current) {
        handleTransformMove({ nativeEvent });
      } else if (controlIsDownRef.current) {
        moveCtrlClickMove({ nativeEvent })
      } else if (altIsDownRef.current && nativeEvent.buttons === 2) {
        altRightClickMove({ nativeEvent });
        hotMouseActiveRef.current = true;
      } else if (
        !tabIsDownRef.current &&
        !altIsDownRef.current &&
        (controlIsDownRef.current || spaceIsDownRef.current || nativeEvent.buttons === 2)
      ) {
        if (tabIsDownRef.current) { 
          return; 
        }
        if (nativeEvent.target.closest('.temp-canvas')) {
          changeViewMove({ nativeEvent });
        }
      } else if (activeToolRef.current === 'pipette') {
        searchForColorFromCanvas({ nativeEvent });
      } else {
        draw({ nativeEvent });
      }
    }
  };

  
  const handlePointerUp = (nativeEvent) => {

    // if (
    //   !nativeEvent.target.closest('.temp-canvas')
    //   && !nativeEvent.target.closest('.backing-canvas')
    // ) { return; }

    if (!canDraw) { return; }

    if (nativeEvent.pointerType === 'touch') {

      if (drawingShapeRef.current) {
        handleShapeEnd({ nativeEvent });
      } else if (shapeTransformerRef.current) {
        handleTransformEnd({ nativeEvent });
      } else if (activeToolRef.current === 'pipette' && nativeEvent.target === tempCanvasRef.current) {
        pickColorFromCanvas({ nativeEvent });
      } else {
        finishDrawing({ nativeEvent });
      }

    } else {
      
      mouseIsPressedRef.current = false;
      nativeEvent.preventDefault();

      const simpleZoomWithCtrl = controlIsDownRef.current && nativeEvent.button === 0 && !altIsDownRef.current;
      const simpleZoomOut =
        !tabIsDownRef.current &&
        !altIsDownRef.current &&
        nativeEvent.button === 2 &&
        (controlIsDownRef.current || spaceIsDownRef.current);

      if (drawingShapeRef.current) {
        handleShapeEnd({ nativeEvent });
      } else if (shapeTransformerRef.current) {
        handleTransformEnd({ nativeEvent });
      } else if (simpleZoomOut) {
        simpleZoom({ nativeEvent });
      } else if (simpleZoomWithCtrl) {
        simpleZoom({ nativeEvent });
      } else if (
        activeToolRef.current === 'pipette' 
        && nativeEvent.target === tempCanvasRef.current
        && !hotMouseActiveRef.current
        ) {
        pickColorFromCanvas({ nativeEvent });
      } else {
        finishDrawing({ nativeEvent, force: true });
      }

      hotMouseActiveRef.current = false;
    }
  };


  const handleClick = (nativeEvent) => {

    if (
      activeToolRef.current === 'effect' 
      && effectTypeRef.current === 'filler'
      && nativeEvent.target === tempCanvasRef.current
      ) {
      fill({ nativeEvent });
    } 

  }

  const handleContextMenu = (nativeEvent) => {

    setMouseDetected(false)

    if (nativeEvent.target.closest('.input-field')) { return; }
    nativeEvent.preventDefault();
    if (nativeEvent.target.closest('.allow-context-menu')) { return; }
    nativeEvent.stopPropagation();

  };

  const handleTouchStart = (nativeEvent) => {

    if (nativeEvent.touches.length === 1) { return; }
    
    if (isDrawingRef.current && isDrawingRef.current.started < serverTimeFun() - 300) { return; }

    setMouseDetected(false);

    if (nativeEvent.touches.length > 3) {
      // isZoomingRef.current = true;

      // Do nothing
    } else if (nativeEvent.touches.length === 3) {
      resetZoomStart();
    } else if (nativeEvent.touches.length === 2) {
      touchZoomStart(nativeEvent)
    } 

  };

  const handleTouchMove = (nativeEvent) => {

    if (nativeEvent.touches.length === 1) { return; }
    if (!isZoomingRef.current) { return; }

    setMouseDetected(false)
    if (nativeEvent.target.closest('.touch-active')) { return; }
    if (nativeEvent.target.closest('.premium-menu')) { return; }
    if (nativeEvent.target.closest('.settings-menu')) { return; }

    if (nativeEvent.touches.length > 3) {
      drawingShapeRef.current = false;
      // Do nothing
    } else if (nativeEvent.touches.length === 3) {
      drawingShapeRef.current = false;
      wipeTempCanvas();

    } else if (nativeEvent.touches.length === 2) {

      isDrawingRef.current = false;
      drawingShapeRef.current = false;
      wipeTempCanvas();
      touchZoomMove(nativeEvent);

    } 


  };

  const handleTouchEnd = (nativeEvent) => {

    setTimeout(() => { setMouseDetected(false) }, 10);

    if (nativeEvent.touches.length === 0) {
      touchZoomEnd(nativeEvent);

      if (isDrawingRef.current?.pointerType === 'touch') {
        finishDrawing({ force: true })
      }
    }

  };

    // Handler for when the stylus hovers over the canvas
    const handlePointerOver = (nativeEvent) => {
      if (nativeEvent.pointerType === 'pen' && nativeEvent.buttons === 0) {
        isStylusHoveringRef.current = true;
        window.logPerformance(`Hover start over point: x: ${nativeEvent.clientX} y: ${nativeEvent.clientY}`)
        console.log('Stylus is near the screen (hover start)');
        // Implement any additional logic here
      }
    };
  
    // Handler for when the stylus stops hovering over the canvas
    const handlePointerOut = (nativeEvent) => {
      if (nativeEvent.pointerType === 'pen' && nativeEvent.buttons === 0) {
        isStylusHoveringRef.current = false;
        window.logPerformance(`Hover end point: x: ${nativeEvent.clientX} y: ${nativeEvent.clientY}`)
        console.log('Stylus is no longer near the screen (hover end)');
        // Implement any additional logic here
      }
    };


  useEffect(() => {
    setTimeout(() => {
      if (!pointerListenersAreSet.current) {
        pointerListenersAreSet.current = true;

        window.addEventListener('pointerdown', handlePointerDown);
        window.addEventListener('pointermove', handlePointerMove);
        window.addEventListener('pointerup', handlePointerUp);
        window.addEventListener('contextmenu', handleContextMenu);
        window.addEventListener('click', handleClick);

        // window.addEventListener('pointerover', handlePointerOver);
        // window.addEventListener('pointerout', handlePointerOver);
        // window.addEventListener('pointerenter', handlePointerDown);
        // window.addEventListener('pointerleave', handlePointerUp);
        // window.addEventListener('pointerover', handlePointerDown);
        // window.addEventListener('pointerout', handlePointerUp);

        // Cleanup event listeners on unmount
        return () => {
          window.removeEventListener('pointerdown', handlePointerDown);
          window.removeEventListener('pointermove', handlePointerMove);
          window.removeEventListener('pointerup', handlePointerUp);
          window.removeEventListener('contextmenu', handleContextMenu);
          window.removeEventListener('click', handleClick);

          // window.removeEventListener('pointerover', handlePointerOver);
          // window.removeEventListener('pointerout', handlePointerOut);
          // window.removeEventListener('pointerenter', handlePointerDown);
          // window.removeEventListener('pointerleave', handlePointerUp);
          // window.removeEventListener('pointerover', handlePointerDown);
          // window.removeEventListener('pointerout', handlePointerUp);
        };
      }
    }, 500);
  }, []); // Empty dependency array ensures this runs once on mount

  return {
    handlePointerDown,
    handlePointerUp,
    handlePointerMove,
    handleContextMenu,

    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  };
};
