// useTelegram.js
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const useInterface = ({
  openMenu,
  gameIsLoaded,
  userSetsRef,
  forceRender,
  lastTransitionTimeRef,
}) => {

  window.Telegram.WebApp.onEvent('viewportChanged', (()=>{
    lastTransitionTimeRef.current = Date.now();
    forceRender()
  }))

  useEffect(() => {
    if (!gameIsLoaded) { return; }
    if (userSetsRef.current.fullscreen)
    try { window.Telegram.WebApp.requestFullscreen(); } catch (e) {}
  }, [gameIsLoaded]); 
  

  return { 
   };
};

export default useInterface;


