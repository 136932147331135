// effect.js
import { applyPixiFilter } from './pixi/pixiFilters'; // Reusing the seededRandom function
import { brushDefaults } from './helpers/brushLoader';

export async function drawEffectStroke({
  lineWidth,
  points,
  color,
  opacity,
  effect,
  time,
  sets = {},
}, context) {

  if (points.length === 0) return;

  const defaultBrushSettings = brushDefaults[effect] || {};
  const brushSettings = Object.assign({}, defaultBrushSettings, sets);

  let filter = 'adjustment';
  let options;
  let blurCoef = 0.2;

  // Select options based on the effect
  switch (effect) {
    case 'noise':
      filter = 'crt';
      options = {
        noise: 0.05,
        noiseSize: 1,
        seed: 1 - (Math.max(1, time % 100) / 100 / 2),
        verticalLine: false,
        curvature: 0,     // Set to 0 to avoid screen curvature
        lineWidth: 0,     // Set to 0 to avoid interlaced lines
        lineContrast: 0,  // Set to 0 to avoid interlaced lines
        vignetting: 0,
      };
      break;
    case 'pixelate':
      filter = 'pixelate';
      blurCoef = 0;
      options = {
        // size: Math.max(5, Math.min(25, lineWidth / 5)),
        size: brushSettings.uneven ?  [brushSettings.sizeX, brushSettings.sizeY] : brushSettings.size,
      };
      break;
    case 'lighten':
      options = {
        brightness: 1.06,
        gamma: 1.06,
      };
      break;
    case 'darken':
      options = {
        brightness: 0.95,
        gamma: 0.95,
      };
      break;
    case 'saturate':
      options = {
        saturation: 1.1,
      };
      break;
    case 'desaturate':
      options = {
        saturation: 0.9,
      };
      break;
    case 'contrast':
      options = {
        contrast: 1.15,
      };
      break;
    case 'decontrast':
      options = {
        contrast: 0.85,
      };
      break;
    case 'zoomin':
      filter = 'bulgePinch';
      blurCoef = 0;
      options = {
        radius: lineWidth / 2,
        strength: 0.1,
        center: {
          x: points[0].x / context.canvas.width,
          y: points[0].y / context.canvas.height
        }
      };
      break;
    case 'zoomout':
      filter = 'bulgePinch';
      blurCoef = 0;
      options = {
        radius: lineWidth / 2,
        strength: -0.1,
        center: {
          x: points[0].x / context.canvas.width,
          y: points[0].y / context.canvas.height
        }
      };
      break;
    case 'outline':
      filter = 'outline';
      blurCoef = 0;
      options = {
        thickness: 4,
        color: 0x000000,
        quality: 0.1,
        alpha: 1,
        knockout: false
      };
      break;
    default:
      console.error('Unknown effect:', effect);
      return;
  }


  const levels = 1;
  for (let level = 1; level <= levels; level++)  {
    // Create a mask for the stroke
    const maskCanvas = document.createElement('canvas');
    maskCanvas.width = context.canvas.width;
    maskCanvas.height = context.canvas.height;
    const maskCtx = maskCanvas.getContext('2d');

    // Draw the stroke path
    // const currentLineWidth = lineWidth/levels * level 
    const currentLineWidth = lineWidth * (4/5) + (lineWidth/levels * level) * (1/5) 
    drawStroke(maskCtx, points, currentLineWidth, blurCoef);

    const bufferCanvas = document.createElement('canvas');
    bufferCanvas.width = context.canvas.width;
    bufferCanvas.height = context.canvas.height;
    const bufferCtx = bufferCanvas.getContext('2d');
  
    // Copy the original canvas content to the buffer
    bufferCtx.drawImage(context.canvas, 0, 0);
    await applyEffectAlongPath({
      context: bufferCtx, 
      maskCanvas, 
      lineWidth, 
      filter,
      options,
      level, 
      time,
    });

    // Draw the result on the main canvas
    context.drawImage(bufferCanvas, 0, 0);
  }
}


function drawStroke (context, points, lineWidth, blurCoef) {

  context.lineCap = 'round';
  context.lineJoin = 'round';
  context.lineWidth = lineWidth;

  const blurSize = lineWidth * blurCoef;
  context.filter = `blur(${blurSize}px)`;

  if (points.length === 1) {

    let point = points[0];
    if (Array.isArray(point)) {} else {point = [point.x, point.y]}
        
    // Для одиночной точки рисуем круг
    context.beginPath();
    context.arc(
      point.x, 
      point.y, 
      lineWidth / 2, 
      0, 
      Math.PI * 2
      );
    context.fill();

  } else {

    context.beginPath();
    context.moveTo(points[0].x, points[0].y);
  
    for (let i = 1; i < points.length; i++) {
      const nextPoint = points[i - 1];
      const currentPoint = points[i];
      const midPoint = {
        x: (nextPoint.x + currentPoint.x) / 2,
        y: (nextPoint.y + currentPoint.y) / 2
      };
      context.quadraticCurveTo(nextPoint.x, nextPoint.y, midPoint.x, midPoint.y);
    }
    const lastPoint = points[points.length - 1]
    context.lineTo(
      lastPoint.x, 
      lastPoint.y,
      );
    context.stroke();


  }
  

  
}


async function applyEffectAlongPath({
  context, 
  maskCanvas, 
  lineWidth, 
  filter,
  options,
  level, 
  time,
}) {

  const effectedCanvas = document.createElement('canvas');
  effectedCanvas.width = context.canvas.width;
  effectedCanvas.height = context.canvas.height;
  const effectedContext = effectedCanvas.getContext('2d');

  effectedContext.drawImage(context.canvas, 0, 0);
  await applyPixiFilter(effectedContext.canvas, filter, options)

  // Apply the blur using the mask
  context.save();
  context.globalCompositeOperation = 'source-over';
  context.drawImage(effectedCanvas, 0, 0);
  context.globalCompositeOperation = 'destination-in';
  context.drawImage(maskCanvas, 0, 0);
  context.restore();
}



