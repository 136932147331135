// useShapes.js

import { useCallback, useEffect, useRef, } from 'react';

import { useGameContext  } from '../../contexts/GameContext';
import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';
import { useHistoryContext  } from '../../contexts/HistoryContext';

export const useShapeDraw = ({
  saveHistory,
  getEventPos,

  touchZoomStart,
  touchZoomMove,

  wipeTempCanvas,

  addStroke,
  drawPreview,
}) => {

  const gameContext = useGameContext();
  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();
  const HistoryContext = useHistoryContext();

  const {
    forceRender,
    activeUserIdRef,
    gameInfoRef,
    serverTimeFun,
  } = gameContext;

  const {
    isDrawingRef,
  
  } = canvasContext;

  const {
    lineWidthRef,
    visibleColorFun,
    gradientColorFun,

    shapeStartPointRef,
    drawingShapeRef,
    lastShapeMadeTimeRef,

    brushSetsFun,
  } = brushContext;

  const {
    userStrokesRef,
  } = HistoryContext;

  const game = gameInfoRef.current;

  const handleShapeStart = ({ nativeEvent }) => {

    shapeStartPointRef.current = getEventPos(nativeEvent);  // Record the start point

    drawingShapeRef.current = true;
    isDrawingRef.current = false;

    window.croco?.haptic?.();

  };


  const handleShapeMove = async ({ nativeEvent }) => {

    if (!drawingShapeRef.current) return;

    wipeTempCanvas();

    const sets = brushSetsFun();
    const figure = sets.figure || 'line';

    const commonSets = brushSetsFun('common');

    const sprintForbiddenBrush = gameInfoRef.current?.mode === 'sprint' 
    && !['plain', 'dashed', 'outlined'].includes(sets.brush);

    const brushType = sprintForbiddenBrush ? 'plain' : sets.brush;
    const brushSets = brushSetsFun(brushType);
    const combinedSets = {...brushSets, ...sets, ...commonSets};

    let points = createFigurePoints(figure, shapeStartPointRef.current, getEventPos(nativeEvent), lineWidthRef.current);
    points = antiRoundEdges(points, figure);

    const now = Date.now();
    const stroke = {
      points,
      color: visibleColorFun(),
      gradientColor: gradientColorFun(),
      lineWidth: lineWidthRef.current,
      sets: combinedSets,
      time: now,
      shapeId: now,
    };

    await drawPreview(stroke, {
      brush: brushType
    });


  };

  const handleShapeEnd = ({ nativeEvent }) => {

    const now = Date.now();

    const sets = brushSetsFun();
    const figure = sets.figure || 'line';

    const commonSets = brushSetsFun('common');

    const sprintForbiddenBrush = gameInfoRef.current?.mode === 'sprint' 
    && !['plain', 'dashed', 'outlined'].includes(sets.brush);

    const brushType = sprintForbiddenBrush ? 'plain' : sets.brush;

    const brushSets = brushSetsFun(brushType);
    const combinedSets = {...brushSets, ...sets, ...commonSets};

    let points = createFigurePoints(figure, shapeStartPointRef.current, getEventPos(nativeEvent), lineWidthRef.current);
    points = antiRoundEdges(points, figure);

    const serverTime = serverTimeFun();

    addStroke(
      points,
      { 
        time: serverTime,
        shapeId: serverTime,
        sets: combinedSets,
        brush: brushType,
       }
      );
    wipeTempCanvas(); // Clear preview canvas
    lastShapeMadeTimeRef.current = Date.now();
    drawingShapeRef.current = false;
    
  }

  const createFigurePoints = (figure, startPoint, endPoint, lineWidth) => {
    const { x: x1, y: y1 } = startPoint;
    const { x: x2, y: y2 } = endPoint;
  
    const minX = Math.min(x1, x2);
    const maxX = Math.max(x1, x2);
    const minY = Math.min(y1, y2);
    const maxY = Math.max(y1, y2);
  
    const width = maxX - minX;
    const height = maxY - minY;
  
    const centerX = (x1 + x2) / 2;
    const centerY = (y1 + y2) / 2;
  
    switch (figure) {
      case 'line':
        return [
          { x: x1, y: y1 },
          { x: x2, y: y2 },
        ];
  
      case 'square': // Прямоугольник
        return [
          { x: minX, y: minY },
          { x: maxX, y: minY },
          { x: maxX, y: maxY },
          { x: minX, y: maxY },
          { x: minX, y: minY } // замкнём фигуру
        ];
  
      case 'round': // Эллипс, вписанный в прямоугольник
      {
        const points = [];
        const numSteps = 60; // кол-во точек для сглаженности эллипса
        const rx = width / 2;
        const ry = height / 2;
        for (let i = 0; i < numSteps; i++) {
          const angle = (2 * Math.PI * i) / numSteps;
          const px = centerX + rx * Math.cos(angle);
          const py = centerY + ry * Math.sin(angle);
          points.push({ x: px, y: py });
        }
        // замкнём эллипс, повторив первую точку
        points.push(points[0]);
        return points;
      }
  
      case 'triangle': 
      {
        // Равнобедренный треугольник, вписанный в прямоугольник
        // Верхняя точка - центр верхней стороны, нижние две - углы нижней стороны
        return [
          { x: (minX + maxX) / 2, y: minY }, // верхняя точка
          { x: minX, y: maxY }, // нижний левый угол
          { x: maxX, y: maxY }, // нижний правый угол
          { x: (minX + maxX) / 2, y: minY } // возвращаемся в начальную точку
        ];
      }
  
      case 'star':
      {
        // Пятилучевая звезда, вписанная в bounding box
        // Алгоритм: центр, радиус по x и y, вычисляем координаты вершин звезды
        const points = [];
        const numRays = 5;
        const rx = width / 2;
        const ry = height / 2;
        // Звезда будет выпуклой с чередованием вершин (одна вершина внешняя, одна - внутренняя)
        // Можно использовать коэффициент для "внутреннего" радиуса
        const innerRadius = 0.5; 
        for (let i = 0; i < numRays * 2; i++) {
          const angle = Math.PI / numRays * i;
          const radiusCoef = i % 2 === 0 ? 1 : innerRadius;
          const px = centerX + rx * radiusCoef * Math.sin(angle);
          const py = centerY - ry * radiusCoef * Math.cos(angle);
          points.push({ x: px, y: py });
        }
        points.push(points[0]); // замыкаем
        return points;
      }
  
      case 'arrow':
      {
        // Стрелка: линия от startPoint до endPoint + наконечник
        // Рассчитаем угол линии
        const dx = x2 - x1;
        const dy = y2 - y1;
        const angle = Math.atan2(dy, dx);
        
        // Длина стрелки (величина наконечника)

        const maxHeadSize = lineWidth > 5 ? 
        lineWidth * 5 : 
        lineWidth * (25 / lineWidth);

        const arrowHeadLength = Math.min(maxHeadSize, Math.sqrt(dx*dx + dy*dy) * 0.3); // размер наконечника (на 30% длины или макс 20px)
        const arrowAngle = Math.PI / 7; // угол расхождения наконечника
  
        const endX = x2;
        const endY = y2;
  
        const arrowPoint1 = {
          x: endX - arrowHeadLength * Math.cos(angle - arrowAngle),
          y: endY - arrowHeadLength * Math.sin(angle - arrowAngle)
        };
        const arrowPoint2 = {
          x: endX - arrowHeadLength * Math.cos(angle + arrowAngle),
          y: endY - arrowHeadLength * Math.sin(angle + arrowAngle)
        };
  
        return [
          { x: x1, y: y1 },
          { x: x2, y: y2 },
          arrowPoint1,
          { x: x2, y: y2 },
          arrowPoint2,
          { x: x2, y: y2 }
        ];
      }
  
      default:
        // Если неизвестная фигура, пусть по умолчанию будет линия
        return [
          { x: x1, y: y1 },
          { x: x2, y: y2 },
        ];
    }
  };
  
  const antiRoundEdges = (points, figure) => {
    if (['round'].includes(figure)) { return points; }
    if (points.length <= 2) { return points; } 
    const result = [points[0]]; // Начинаем с первой точки
    for (let i = 1; i < points.length - 1; i++) {
      result.push(points[i], points[i]); // Добавляем текущую точку дважды
    }
    result.push(points[points.length - 1]); // Добавляем последнюю точку
    return result;
  };



  const straightLine = ({ nativeEvent }) => {

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];
    if (myStrokes.length === 0) return;

    let myHistory = myStrokes
    .filter(stroke=> !stroke.cancelled && !stroke.hidden)
    .sort((a, b) => a.time - b.time);

    const lastStroke = myHistory[myHistory.length - 1];

    if (!lastStroke?.points?.length) return;

    const {x, y} = getEventPos(nativeEvent);

    const shapeId = lastStroke.shapeId || Date.now();
    lastStroke.shapeId = shapeId;
    lastStroke.hidden = true;
    delete lastStroke.rendered;

    if (nativeEvent.button === 2) {

      addStroke([...lastStroke.points, [x, y]], { 
        time: serverTimeFun(),
        shapeId,
       })

    } else {

      const lastStrokeLastPoint = lastStroke.points[lastStroke.points.length - 1];

      let newStrokePoints = lastStroke.points.length === 1 ? 
      [lastStrokeLastPoint, [x, y]] : 
      [...lastStroke.points, lastStrokeLastPoint, [x, y]];

      addStroke(newStrokePoints, { 
        time: serverTimeFun(),
        shapeId,
       })

    }


  };


  return {
    handleShapeStart,
    handleShapeMove,
    handleShapeEnd,
    straightLine,
  }
};

