// PopupMessage.js
import React, { useState, } from 'react';
import './PopupMessage.css'; // Подключаем стили


function PopupMessageOverlay({
  gameContext,
}) {

  const {
    popupMessage, showPopupMessage,
    userSetsRef,
    saveUserSets, 
  } = gameContext;

  const [inputValue, setInputValue] = useState('');

  const handleButtonClick = (buttonId) => {
    if (popupMessage.callback) {
      if (buttonId === 'submit') {
        popupMessage.callback('submit', inputValue);
      } else {
        popupMessage.callback(buttonId);
      }
    }
    showPopupMessage(null);
  };

  const handleUserSetCheckbox = (key) => {
    saveUserSets({ [key]: !userSetsRef.current[key] })
  };

  const input = popupMessage.input || null;
  const buttons = popupMessage.buttons || 
  (input ? 
    [
      { type: 'cancel', text: 'Cancel',},
      { id: 'submit', type: 'default', text: 'OK',},
    ] :
    [{ type: 'default', text: 'OK', }]
  )


  const renderContent = () => {
    if (popupMessage.content) {
      return popupMessage.content;
    } else {
      return (
        <span 
          className="message-popup-text"
          dangerouslySetInnerHTML={{ __html: popupMessage.message || popupMessage.text || '' }}
        />
      );
    }
  };

  return (
    <>
      <div className="overlay-background" style={{zIndex: '1149'}} onClick={()=>showPopupMessage(null)}></div>
      <div className="message-popup">
        <div className="message-popup-title">{popupMessage.title || ''}</div>
        {renderContent()}

        {popupMessage.items && popupMessage.items.map((item) => 
            item.type === 'set' ? (
              <div key={item.key} className="settings-item">
                <label className="message-popup-settings-label" style = {{
                  opacity: userSetsRef.current[item.key] ? 1 : 0.5
                }}>
                  <input
                    type="checkbox"
                    style={{  width: '20px', height: '20px'}}
                    checked={userSetsRef.current[item.key]}
                    onChange={() => handleUserSetCheckbox(item.key)}
                  />
                  <span className='message-popup-settings-text'>{item.label}</span>
                </label>
              </div>
            ) : (
              <div className="settings-item" onClick={() => handleButtonClick(item.id)}>
                <label className="message-popup-settings-label">
                { item.icon && (<item.icon className="settings-label-icon"/>)}
                <span>{item.label}</span>
                </label>
              </div>
            )
          )
        }
        {input && (
          <input
            type="text"
            value={inputValue}
            onChange={(e)=>{ setInputValue(e.target.value) }}
            className="message-popup-input-field input-field"
            placeholder={input.placeholder || ''}
            autoFocus={true}
          />
        )}

        <div className="message-popup-button-container">
          {buttons.map((button) => (
            <button
              key={button.id}
              className={`message-popup-button ${ {
                cancel: 'cancel-button',
                destructive: 'destructive-button',
              }[button.type] || ''}`}
              onClick={() => handleButtonClick(button.id)}
            >
              {button.text}
            </button>
          ))}
        </div>
      </div>
     
    </>
  );
}

export default PopupMessageOverlay;
