// useTelegram.js
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const useTelegram = ({
  openMenu,
}) => {

  const { i18n } = useTranslation();
  const [isValid, setIsValid] = useState(null);
  const [isChecked, setIsChecked] = useState(null);
  const [isHacker, setIsHacker] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  // Параметры
  const startParam = urlParams.get('tgWebAppStartParam') || '677e2b68c9c3e9365dd59afa-1001426497563-0';

  // const startParam = urlParams.get('tgWebAppStartParam') || '675b24e91d2de70e6e9078c9-1001426497563-0';

  // const startParam = urlParams.get('tgWebAppStartParam') || '6759da06c1451db43c008c39-1001426497563-0';
  const inBrowser = urlParams.get('brw');
  const gameId = startParam.split('-')[0];
  const drawingMode = startParam.split('-')[1];
  const socketPortNum = startParam.split('-')[2];
  const moderMode = startParam.split('-')[4] === 'moder';
  
  const adminUserId = 141015727;
  // const adminUserId = `1431457632`;
  const telegramUserId = `${window.Telegram?.WebApp?.initDataUnsafe?.user?.id || adminUserId}`;

  const activeUserIdRef = useRef(telegramUserId);
  const moderatorIdRef = useRef(null);
  const moderatorModeRef = useRef(moderMode);

  const languageCode = `${window.Telegram?.WebApp?.initDataUnsafe?.user?.language_code || 'ru'}`;

  // Для проверки
  const initData = window.Telegram?.WebApp?.initData || '';

  useEffect(() => {

      window.logPerformance('Telegram script load started')

      const script = document.createElement('script');
      script.src = "https://telegram.org/js/telegram-web-app.js";
      script.async = true;
      script.onload = () => {
        window.logPerformance('Telegram script load ended')

        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.expand();
        // window.Telegram.WebApp.enableClosingConfirmation()

        window.Telegram.WebApp.SettingsButton.isVisible = true;
        window.Telegram.WebApp.onEvent('settingsButtonClicked', (()=>{
          openMenu('drawing');
        }))
      };
      
      document.body.appendChild(script);
      
  }, []); 

  useEffect(() => {
      i18n.changeLanguage(languageCode);
  }, []); 


  useEffect(() => {
    if (isValid === null) {return;}
    setIsChecked(true);

    if (!isValid) {
      setIsHacker(true)
    } else {
      setIsHacker(false)
    }
  }, [isValid]); 


  return { 
    urlParams, 
    startParam, 
    gameId,
    drawingMode,
    inBrowser, 
    socketPortNum,
    telegramUserId,
    languageCode,

    activeUserIdRef,
    moderatorIdRef,
    moderatorModeRef,

    isValid, setIsValid,
    isHacker,
    isChecked,
    initData,
   };
};

export default useTelegram;


