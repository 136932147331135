// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  position: fixed;
  top: calc(47vh - 25px);;
  left: calc(50vw - 25px);;
  
    animation: rotate 1s linear infinite;
    width: 50px;
    height: 50px;

    & .path {
        stroke: var(--tg-theme-button-color, dodgerblue);
        stroke-linecap: round;
        animation: dash 3s linear infinite;
    }

}

.spinner-debug-text {
  position: fixed;
  top: calc(47vh - 70px);;
  left: calc(50vw - 70px);;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/board/spinner/Spinner.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,sBAAsB;EACtB,uBAAuB;;IAErB,oCAAoC;IACpC,WAAW;IACX,YAAY;;IAEZ;QACI,gDAAgD;QAChD,qBAAqB;QACrB,kCAAkC;IACtC;;AAEJ;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;IACI;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI;QACI,wBAAwB;QACxB,oBAAoB;IACxB;;IAEA;QACI,yBAAyB;QACzB,sBAAsB;IAC1B;;IAEA;QACI,yBAAyB;QACzB,uBAAuB;IAC3B;AACJ","sourcesContent":[".spinner {\n  position: fixed;\n  top: calc(47vh - 25px);;\n  left: calc(50vw - 25px);;\n  \n    animation: rotate 1s linear infinite;\n    width: 50px;\n    height: 50px;\n\n    & .path {\n        stroke: var(--tg-theme-button-color, dodgerblue);\n        stroke-linecap: round;\n        animation: dash 3s linear infinite;\n    }\n\n}\n\n.spinner-debug-text {\n  position: fixed;\n  top: calc(47vh - 70px);;\n  left: calc(50vw - 70px);;\n}\n\n@keyframes rotate {\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n@keyframes dash {\n    0% {\n        stroke-dasharray: 1, 150;\n        stroke-dashoffset: 0;\n    }\n\n    50% {\n        stroke-dasharray: 90, 150;\n        stroke-dashoffset: -35;\n    }\n\n    100% {\n        stroke-dasharray: 90, 150;\n        stroke-dashoffset: -124;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
