import chroma from 'chroma-js';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useGameContext  } from '../../contexts/GameContext';
import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';

export const useColor = ({
  getEventPos,
}) => {

  const { t } = useTranslation();

  const gameContext = useGameContext();
  const canvasContext = useDrawingContext ();
  const brushContext = useBrushContext();

  const { 
    appleHaptic,
    showTemporaryHint,
  } = gameContext;

  const {
    canvasRef, 
    tempCanvasRef,
  } = canvasContext;

  const {

    color, setTheColorFun, 
    prevColorRef,
    activeTool, setActiveTool,
    activeToolRef,
    previousToolRef,
    handleLastColors,

    setIsPipettePicking,
    setPickingPlace,
    pickingPlaceRef,

    altIsDownRef,

    lastActionTimeRef,

    ColorHint,
  } = brushContext;

  const pickedColorRef = useRef('#000');

  function getColorByEvent ({ nativeEvent }) {

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    let { x, y } = getEventPos(nativeEvent)
    x = Math.max(x, 1);
    y = Math.max(y, 1);
    x = Math.min(x, tempCanvasRef.current.width - 1);
    y = Math.min(y, tempCanvasRef.current.height - 1);

    const pixel = context.getImageData(x, y, 1, 1).data;
    const rgbaColor = `rgba(${pixel[0]}, ${pixel[1]}, ${pixel[2]}, ${pixel[3] / 255})`;
    
    // Конвертация из RGBA в HEX
    const hexColor = chroma(rgbaColor).hex();
    return hexColor;
  }


  const startPickingColorFromCanvas = ({ nativeEvent }) => {
    if (activeToolRef.current !== 'pipette') return;
    lastActionTimeRef.current = Date.now();
    setIsPipettePicking(true);

    pickedColorRef.current = getColorByEvent({ nativeEvent });
    setTheColorFun(pickedColorRef.current);

    const place = {
      x: nativeEvent.touches?.[0]?.clientX || nativeEvent.clientX,
      y: nativeEvent.touches?.[0]?.clientY || nativeEvent.clientY,
    };

    // place.x = Math.round(place.x * 10) / 10;
    // place.y = Math.round(place.y * 10) / 10;

    prevColorRef.current = color;
    pickingPlaceRef.current = place;
    setPickingPlace(place);

    appleHaptic('soft');

  };

  const searchForColorFromCanvas = ({ nativeEvent }) => {
    if (activeToolRef.current !== 'pipette') return;
    // if (!isPipettePicking) return;
    pickedColorRef.current = getColorByEvent({ nativeEvent });
    setTheColorFun(pickedColorRef.current);

    const place = {
      x: nativeEvent.touches?.[0]?.clientX || nativeEvent.clientX,
      y: nativeEvent.touches?.[0]?.clientY || nativeEvent.clientY,
    };

    pickingPlaceRef.current = place;
    setPickingPlace(place);
  };

  const pickColorFromCanvas = ({ nativeEvent }) => {
    if (activeToolRef.current !== 'pipette') return;

    if (!altIsDownRef.current) {
      setActiveTool(previousToolRef.current);
      lastActionTimeRef.current = Date.now();
    }

    setIsPipettePicking(false);
    handleLastColors();

    appleHaptic('soft');
    showTemporaryHint(
      <ColorHint text={t('tooltip.saved_color_taken')} hexColor={pickedColorRef.current} />,
      {force: true, duration: 1500}, {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        minWidth: '100px',
        padding: '8px',
      },
      );

    
  };

  const stopPickingColorFromCanvas = (event) => {
    setIsPipettePicking(false);
  };


  return {
    startPickingColorFromCanvas,
    searchForColorFromCanvas,
    pickColorFromCanvas,
    stopPickingColorFromCanvas,
  };
};