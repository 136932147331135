// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Для браузеров на основе WebKit (Chrome, Safari, Edge) */
input[type="range"]::-webkit-slider-thumb {
  width: 30px;
  height: 30px;
}

/* Для Firefox */
input[type="range"]::-moz-range-thumb {
  width: 30px;
  height: 30px;
}

/* Для Internet Explorer и старых версий Edge */
input[type="range"]::-ms-thumb {
  width: 30px;
  height: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/utils/custom-slider.css"],"names":[],"mappings":"AAAA,0DAA0D;AAC1D;EACE,WAAW;EACX,YAAY;AACd;;AAEA,gBAAgB;AAChB;EACE,WAAW;EACX,YAAY;AACd;;AAEA,+CAA+C;AAC/C;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":["/* Для браузеров на основе WebKit (Chrome, Safari, Edge) */\r\ninput[type=\"range\"]::-webkit-slider-thumb {\r\n  width: 30px;\r\n  height: 30px;\r\n}\r\n\r\n/* Для Firefox */\r\ninput[type=\"range\"]::-moz-range-thumb {\r\n  width: 30px;\r\n  height: 30px;\r\n}\r\n\r\n/* Для Internet Explorer и старых версий Edge */\r\ninput[type=\"range\"]::-ms-thumb {\r\n  width: 30px;\r\n  height: 30px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
