// BrushSizeIndicator
import React from 'react';

const BrushSizeIndicator = ({ 
  canvasContext,
  brushContext,
  position, 
  clickPosition, 
  visibleLineWidth, 
 }) => {
    if (!position || !visibleLineWidth) {
        return null; // Не отображаем компонент, если позиция или размер кисти не заданы
    }
    const {
      zoomFactor,
      controlPanelOffsetRef,
    } = canvasContext;

    const {
      actualSoftnessFun,
      visibleColorFun,
      lineWidth,

      brushIndicatorFrame,
    } = brushContext;

    // Вычисляем смещение для центрирования по горизонтали и позиционирования дна кружка на указателе
    const horizontalOffset = -visibleLineWidth / 2;
    const verticalOffset = -visibleLineWidth - 60;

    const blurSize = `${actualSoftnessFun() * visibleLineWidth}px`; // Пример коэффициента умножения на 3

    const maxBottomPosition = (controlPanelOffsetRef.current || 430) + 45
    const brushSizeText = lineWidth.toFixed(Math.min(1, Math.floor(zoomFactor/3)));

    return (
        <div
            style={{
                position: 'fixed',
                left: `${position.x + horizontalOffset}px`, // Центрирование по горизонтали
                top: `${Math.min(maxBottomPosition, position.y) + verticalOffset}px`, // Позиционирование дна кружка на указателе
                width: `${visibleLineWidth}px`,
                height: `${visibleLineWidth}px`,
                pointerEvents: 'none', // Исключает элемент из событий указателя
                zIndex: 200,
            }}
        >
          <div 
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '50%', // Делаем круг
              background: visibleColorFun(),
              boxShadow: actualSoftnessFun() ? 0 : brushIndicatorFrame,
              filter: `blur(${blurSize})`, 
            }}
          />
          <div style={{
              zIndex: 55, 
              color: 'grey',
              fontSize: '14px',
              opacity: 0.9,
              transform: `translate(${3 + visibleLineWidth}px, ${-visibleLineWidth/2 -10}px)`
            }}>
            {brushSizeText}px
          </div>
        </div>
    );
};

export default BrushSizeIndicator;
