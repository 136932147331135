import React from 'react';

const ColorModeSwitch = ({ premiumGrayscale, setPremiumGrayscale }) => {
  return (
    <div 
      className="premium-grayscale-switch"
      onClick={() => setPremiumGrayscale(isGrayscale => !isGrayscale)}
    >
      <svg width="34" height="34" viewBox="0 0 34 34">
        {premiumGrayscale ? (
         <>
          <rect x="3" y="4" width="7" height="28" rx="2" ry="2" fill="red" />
          <rect x="10" y="4" width="7" height="28" rx="2" ry="2" fill="#00c800" />
          <rect x="17" y="4" width="7" height="28" rx="2" ry="2" fill="blue" />
          <rect x="24" y="4" width="7" height="28" rx="2" ry="2" fill="yellow" />
          {/* <rect x="3" y="4" width="8" height="28" rx="2" ry="2" fill="red" />
          <rect x="12" y="4" width="8" height="28" rx="2" ry="2" fill="#00c800" />
          <rect x="21" y="4" width="8" height="28" rx="2" ry="2" fill="blue" /> */}
        </>
        ) : (
          <>
            <circle cx="17" cy="17" r="16" fill="white" stroke="#808080" strokeWidth="0.3" />
            <path d="M17 1 A16 16 0 0 1 17 33 L17 1 Z" fill="black" />
          </>
        )}
      </svg>
    </div>
  );
};

export default ColorModeSwitch;

