// PremiumMenu.js

import React, { useState } from 'react';
import './PremiumMenu.css'; // Подключаем стили
import { useTranslation, Trans } from 'react-i18next';

function PremiumMenu({ 
  gameContext,
  brushContext,
 }) {

  const { 
    inBrowser,

    userSetsRef, 
    groupSetsRef, 
    botSetsRef, 
    premiumSubsRef,

    showPremiumMenu, setShowPremiumMenu, delayPremiumMenu,
    askGroupPremium,
    hostAction,
   } = gameContext;

  const { 
    adjustColor, 
    blendColors, 
   } = brushContext;


  const store = {
    menu: [
      // { id: 'advantages' },
      { id: 'group', group: true, 
      // { id: 'group', enter: 'group', 
        button_style: { backgroundColor: adjustColor('--tg-theme-button-color', '4eca7d'),},
        info_style: { backgroundColor:blendColors('--tg-theme-secondary-bg-color', '4eca7d', 0.2),},
      },
      { id: 'user', enter: 'user',
        button_style: { backgroundColor: adjustColor('--tg-theme-button-color', '40a7e3'),},
        info_style: { backgroundColor:blendColors('--tg-theme-secondary-bg-color', '40a7e3', 0.2),},
      },
      { id: 'info' },
      { id: 'help',},
    ],
    user: [
      {id: 'artist', price: 49, full_price: 125},
      {id: 'master', price: 99, full_price: 250},
      {id: 'help',},
    ],
    group: [
      {id: 'group_inside'},
      {id: 'workshop', price: 149, full_price: 330},
      {id: 'artschool', price: 299, full_price: 650},
      {id: 'help',},
    ],
  }

  store.user.forEach(plan=>{
    const botUsername = botSetsRef.current.username;
    const urlSetId = `subsPm_${botUsername}`;
    const planId = `${plan.id}_month`;
    const url = userSetsRef.current[urlSetId]?.[planId] || botSetsRef.current[urlSetId]?.[planId];
    Object.assign(plan, {
      url,
      button_style: { backgroundColor: adjustColor('--tg-theme-button-color', '40a7e3'),},
      info_style: { backgroundColor:blendColors('--tg-theme-secondary-bg-color', '40a7e3', 0.2),},
      description_style: { fontSize: '14px',},
    })
  })

  // store.group.forEach(plan=>{
  //   const botUsername = botSetsRef.current.username;
  //   const urlSetId = `subsGroup_${botUsername}`;
  //   const planId = `${plan.id}_month`;
  //   const url = groupSetsRef.current[urlSetId]?.[planId];
  //   Object.assign(plan, {
  //     url,
  //     button_style: { backgroundColor: adjustColor('--tg-theme-button-color', '4eca7d'),},
  //     info_style: { backgroundColor:blendColors('--tg-theme-secondary-bg-color', '4eca7d', 0.2),},
  //     description_style: { fontSize: '14px',},
  //   })
  // })

  const plans = store[showPremiumMenu] || store.menu;

  if (premiumSubsRef.current.includes('beta')) {
    plans.unshift({id: 'beta'}) 
  } else if (premiumSubsRef.current.includes('new_year')) {
    plans.unshift({id: 'new_year'})
  } else if (premiumSubsRef.current.includes('moder')) {
    plans.unshift({id: 'moder'})
  } else if (premiumSubsRef.current.includes('guild')) {
    plans.unshift({id: 'guild'})
  }

  const { t } = useTranslation();

  function ExternalLink ({ href, children }) {
    const handleClick = (event) => {

      event.preventDefault(); 

      if (!inBrowser && window.Telegram?.WebApp?.openLink) {
        window.Telegram.WebApp.openLink(href);
      } else {
        window.open(href, '_blank');
      }

      // window.open(href, '_blank');
      // if (window.Telegram?.WebApp?.openTelegramLink) {
      //   window.Telegram.WebApp.openTelegramLink(href);
      // } else 
 
    };
  
    return (
      <a 
        href={href} 
        onClick={handleClick}
        style={{ color: 'var(--tg-theme-link-color)' }}
      >
        {children}
      </a>
    );
  }

  return (
    <>
      <div className="overlay-background" onClick={() => setShowPremiumMenu(false)}></div>
      <div className="premium-menu touch-active">
        <span className="premium-menu-title">{t(`store.title.${showPremiumMenu}`, groupSetsRef.current)}</span>
        {plans.map((plan) => (
          <div key={plan.id} className="premium-item" style={plan.info_style}>
            <div className="premium-info" >
              <div className="premium-name">{premiumSubsRef.current.includes(plan.id) ? '✅' : ''}{t(`store.${plan.id}.name`)}</div>
                <p className="premium-description" style={plan.description_style}>
                  <Trans 
                    i18nKey={`store.${plan.id}.description`} 
                    values={{
                      ...groupSetsRef.current, 
                      price: plan.price, 
                      full_price: plan.full_price 
                    }}
                    components={{ 
                      s: <s />,
                      a: <ExternalLink href={t(`store.${plan.id}.url`)} />
                     }}
                  />
                </p>
            </div>
            { plan.enter &&
              (
                <button
                className="wide-button"
                onClick={() => delayPremiumMenu(plan.enter, 10)}
                style={plan.button_style}
              >
                {t(`store.${plan.id}.button_text`, { price: plan.price })}
              </button>
              )
            }
            { plan.url &&
              (
                <button
                className="wide-button"
                onClick={() => window.open(plan.url, '_self')}
                style={plan.button_style}
              >
                <Trans
                  i18nKey={`store.${plan.id}.button_text`}
                  values={{ 
                    price: plan.price, 
                    full_price: plan.full_price 
                  }}
                  components={{ s: <s /> }}
                >
                </Trans>
              </button>
              )
            }
            {plan.group &&  (
              <button
              className="wide-button"
              style={{
                backgroundColor: 'var(--tg-theme-destructive-text-color)',
              }}
              onClick={()=>{
                askGroupPremium();
                setTimeout(() => {
                  window.Telegram.WebApp.close();
                }, 300);
              }}
            >
              <Trans
                i18nKey={`store.${plan.id}.button_text`}
                values={{ 
                  price: plan.price, 
                  full_price: plan.full_price 
                }}
                components={{ s: <s /> }}
              />
            </button>
            )}

          </div>
        ))}
        <button 
        className="gray-button" 
        onClick={() => setShowPremiumMenu(previous=>previous === 'menu' ? false : 'menu')}
        >
          {t(`store.back_button_text.${showPremiumMenu}`)}
        </button>
      </div>
    </>
  );
}

export default PremiumMenu;