import { useCallback, useEffect, useRef, useState } from 'react';

import { useDrawingContext } from '../../contexts/DrawingContext';
import { useBrushContext } from '../../contexts/BrushContext';
import { useGameContext } from '../../contexts/GameContext';

export const useSpectatorPointer = (drawMethods) => {


  const pointerListenersAreSet = useRef(false);
  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();
  const gameContext = useGameContext();

  const { 
    canDraw,
  } = gameContext;

  const {
  
    changeViewStart,
    changeViewMove,
  
    // Touch
    touchZoomStart,
    touchZoomMove,
    touchZoomEnd,
    resetZoomStart,
  
    // HotMouse
    altRightClickStart,
    startCtrlClickMove,

  } = drawMethods;

  const {

    setMousePosition,
    mousePositionRef,
    mouseIsPressedRef,

    setMouseDetected,
    isZoomingRef,


  } = canvasContext;

  const {

    controlIsDownRef,
    altIsDownRef,
    spaceIsDownRef,

  } = brushContext;

  const handlePointerDown = (nativeEvent) => {

    if (
      !nativeEvent.target.closest('.temp-canvas')
      && !nativeEvent.target.closest('.backing-canvas')
    ) { return; }

    if (['mouse', 'pen'].includes(nativeEvent.pointerType)) {

      if (nativeEvent.button === 1) {
        nativeEvent.preventDefault();
        return;
      }

      const position = { x: nativeEvent.clientX, y: nativeEvent.clientY };
      setMousePosition(position);
      mousePositionRef.current = position;
      mouseIsPressedRef.current = true;

      if (controlIsDownRef.current) {
        startCtrlClickMove({ nativeEvent })
      } else if (altIsDownRef.current && nativeEvent.button === 2) {
        altRightClickStart({ nativeEvent });
      } else if (spaceIsDownRef.current || nativeEvent.button === 2) {
        if (nativeEvent.target.closest('.temp-canvas')) {
          changeViewStart({ nativeEvent });
        }
      } 

    } else if (nativeEvent.pointerType === 'touch') {

      // Do nothing
   
    }
  };

  const handlePointerMove = (nativeEvent) => {
    
    const pointerType = nativeEvent.pointerType;

    const position = { x: nativeEvent.clientX, y: nativeEvent.clientY };
    setMousePosition(position);
    mousePositionRef.current = position;

    if (
      !nativeEvent.target.closest('.temp-canvas')
      && !nativeEvent.target.closest('.backing-canvas')
    ) { return; }

    if (pointerType === 'touch') { return;  } 
    if (!nativeEvent.buttons) { return; }

    if (spaceIsDownRef.current || nativeEvent.buttons === 2) {
      if (nativeEvent.target.closest('.temp-canvas')) {
        changeViewMove({ nativeEvent });
      }
    } 
  };

  
  const handlePointerUp = (nativeEvent) => {

    if (nativeEvent.pointerType === 'touch') { return; } 
    nativeEvent.preventDefault();
    
  };


  const handleClick = (nativeEvent) => {

  }

  const handleContextMenu = (nativeEvent) => {

    if (nativeEvent.target.closest('.input-field')) { return; }
    nativeEvent.preventDefault();
    if (nativeEvent.target.closest('.allow-context-menu')) { return; }
    nativeEvent.stopPropagation();

  };

  const handleTouchStart = (nativeEvent) => {

    if (nativeEvent.touches.length === 1) { return; }
    
    if (nativeEvent.touches.length > 3) {
      // isZoomingRef.current = true;

      // Do nothing
    } else if (nativeEvent.touches.length === 3) {
      resetZoomStart();
    } else if (nativeEvent.touches.length === 2) {
      touchZoomStart(nativeEvent)
    } 

  };

  const handleTouchMove = (nativeEvent) => {

    if (nativeEvent.touches.length === 1) { return; }
    if (!isZoomingRef.current) { return; }

    setMouseDetected(false)
    if (nativeEvent.target.closest('.touch-active')) { return; }
    if (nativeEvent.target.closest('.premium-menu')) { return; }
    if (nativeEvent.target.closest('.settings-menu')) { return; }

    if (nativeEvent.touches.length === 2) {
      touchZoomMove(nativeEvent);
    } 


  };

  const handleTouchEnd = (nativeEvent) => {

    setTimeout(() => { setMouseDetected(false) }, 10);
    if (nativeEvent.touches.length === 0) {
      touchZoomEnd(nativeEvent);
    }

  };


  useEffect(() => {
    setTimeout(() => {
      if (!pointerListenersAreSet.current) {
        pointerListenersAreSet.current = true;

        window.addEventListener('pointerdown', handlePointerDown);
        window.addEventListener('pointermove', handlePointerMove);
        window.addEventListener('pointerup', handlePointerUp);
        window.addEventListener('contextmenu', handleContextMenu);
        window.addEventListener('click', handleClick);

        // Cleanup event listeners on unmount
        return () => {
          window.removeEventListener('pointerdown', handlePointerDown);
          window.removeEventListener('pointermove', handlePointerMove);
          window.removeEventListener('pointerup', handlePointerUp);
          window.removeEventListener('contextmenu', handleContextMenu);
          window.removeEventListener('click', handleClick);
        };
      }
    }, 500);
  }, []); // Empty dependency array ensures this runs once on mount

  return {
    handlePointerDown,
    handlePointerUp,
    handlePointerMove,
    handleContextMenu,

    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  };
};
