// dashed.js
import { brushDefaults } from './helpers/brushLoader';
import { drawStrokeWithGradient } from './marker';

const defaultBrushSettings = brushDefaults.dashed || {};

export async function drawDashedStroke(stroke, context) {

  const {
    lineWidth,
    sets = {},
  } = stroke;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  const {
    dashSize,
    gapSize,
  } = brushSettings;

  context.save();

  stroke = {...stroke};
  stroke.lineDash = [lineWidth * dashSize, lineWidth * gapSize];
  drawStrokeWithGradient(stroke, context, brushSettings);
  context.restore();


}
