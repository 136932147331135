// BrushMenu
import React from 'react';
import { 
  FaBrush, FaSprayCan, FaPencilAlt, FaFeatherAlt, FaPenFancy, FaPaintBrush,
  FaDotCircle,
  FaPaw,
  FaCog, 
  FaDrawPolygon,
 } from 'react-icons/fa';
import { 
  FaMarker,
  FaPenToSquare,
  FaBroomBall,
  FaRegLightbulb,
 } from 'react-icons/fa6';
import { 
  TbPencilDown, TbLineDashed, TbBrush, TbSparkles, TbTexture,
 } from "react-icons/tb";

import './BrushMenu.css'; // Подключаем стили
import { useTranslation } from 'react-i18next';
import { createBetterClick } from '../../../utils/createBetterClick'

const brushIcons = {
  'plain': FaMarker,
  'stylus': FaPenToSquare,
  'spray': FaSprayCan,
  'bristle': FaPaintBrush,
  'feather': FaFeatherAlt,
  'ink': FaPenFancy,
  'pencil': FaPencilAlt,
  'oil': FaBrush,
  'rembrandt': FaBroomBall,
  'watercolor': TbBrush,
  'outlined': FaDotCircle,
  'dashed': TbLineDashed,
  'neon': FaRegLightbulb,
  'sparkle': TbSparkles,
  'shape': FaDrawPolygon ,
  'test': FaPaw,
  'texture': TbTexture, // Удалить потом
}

function BrushMenu({
  gameContext,
  brushContext,
}) {

  const { t } = useTranslation();

  const {
    gameInfoRef,
    openMenu, closeMenu,

    premiumFeaturesRef,
    userSetsRef,

    delayPremiumMenu,

    saveUserSets,

    appleHaptic,
    androidHaptic,
  } = gameContext;

  const { 
    setBrushType,
    brushTypeRef,

    brushHistoryRef,
    setTheColorFun,

    colorRef,
    setSoftness,

    lastActionTimeRef,
   } = brushContext;


  const game = gameInfoRef.current;

  let brushes;
  
  if (game.mode === 'sprint') {

    brushes = [
      'plain',
      'shape',
      'outlined',
      'dashed',
    ].map(key=>{
      return {
        key,
        label: t(`brush.${key}.name`),
        icon: brushIcons[key],
      }
    });
  
  } else {

    const basicBrushes = [
      'plain',
      'shape',
      'spray',
      'bristle',
      'feather',
      'ink',
      'pencil',
      'watercolor',
      'oil',
      'rembrandt',
      'dashed',
      'outlined',
      'neon',
      'sparkle',
      // 'stylus',
      // 'test',
    ].map(key=>{
      return {
        key,
        label: t(`brush.${key}.name`),
        icon: brushIcons[key],
      }
    });
  
    const savedBrushes = userSetsRef.current.savedBrushes || []
    const userBrushes = savedBrushes.map(key=>{
      const brushSettings = userSetsRef.current[key] || {};
      const basicBrushLink = brushSettings.clone;
      return {
        key,
        label: brushSettings.label,
        icon: brushIcons[basicBrushLink],
      }
    })
  
    brushes = [
      ...basicBrushes,
      ...userBrushes,
    ]

  }

  const handleOverlayClick = () =>{
    if (lastActionTimeRef.current < Date.now() - 300) {
      closeMenu(); 
    }
  }

  const handleSwitchBrush = (newBrushType) => {

    if (lastActionTimeRef.current > Date.now() - 300) { return; }

    if (newBrushType !== 'plain' && 
      (!premiumFeaturesRef.current.includes('brushes') 
      || userSetsRef.current.premiumOff)
      ) {
      closeMenu(); 
      delayPremiumMenu('menu');
    } else {

      const previousBrush = brushTypeRef.current;
      
 
      if (previousBrush !== newBrushType) {
        appleHaptic('medium');
        androidHaptic();
      }
      brushTypeRef.current = newBrushType;
      setBrushType(newBrushType);

      if (newBrushType === 'spray') {

      } else {
        setSoftness(0)
      }

      brushHistoryRef.current.push(newBrushType);
      closeMenu(); 
      saveUserSets({ brushType: newBrushType })

    }

  };

  const handleOpenBrushSettings = (newBrushType, e) => {

    e?.preventDefault(); e?.stopPropagation(); 
    
    openMenu('customizeBrush', {brush: newBrushType, layer: true});

    if (newBrushType !== 'plain' && 
      (!premiumFeaturesRef.current.includes('brushes') 
      || userSetsRef.current.premiumOff)
      ) {
      return;
    } 



    if (newBrushType === 'spray') {
      
    } else {
      setSoftness(0)
    }

    if (newBrushType !== 'common') { 
      brushTypeRef.current = newBrushType;
      setBrushType(newBrushType);
      brushHistoryRef.current.push(newBrushType);
      saveUserSets({ brushType: newBrushType })
    }


  }


  return (
    <>
      <div className="overlay-background" onClick={handleOverlayClick}></div>
      <div className="brush-menu scroll-active touch-active">
      
        <span 
          className="brush-menu-title"
          { ...createBetterClick({
            onClick: (e) => {handleOpenBrushSettings('common', e)},
            onContextMenu: (e) => {handleOpenBrushSettings('common', e)},
          }) }
        >
          {t('brush.title')}
          <FaCog
            style={{paddingLeft: '15px'}}
          />
        </span>
        
          {brushes.map((brush) => (
            <div key={brush.key} 
            className={`brush-option-row ${brushTypeRef.current === brush.key ? 'active' : ''}`}
            // onClick={()=>handleSwitchBrush(brush.key)}
            // onContextMenu={(e)=>{handleOpenBrushSettings(brush.key, e)}}
            { ...createBetterClick({
              onClick: (e) => {handleSwitchBrush(brush.key)},
              onContextMenu: (e) => {handleOpenBrushSettings(brush.key, e)},
            }) }
            >
              <label className="brush-label">
                <brush.icon className='brush-icon'/>
                <span> {brush.label}</span>
                {brushTypeRef.current === brush.key && (
                <FaCog
                  className="brush-settings-icon"
                  { ...createBetterClick({
                    onClick: (e) => {handleOpenBrushSettings(brush.key, e);},
                    onContextMenu: (e) => {handleOpenBrushSettings(brush.key, e);},
                  }) }
                  // onClick={(e) => {
                  //   e.stopPropagation(); handleOpenBrushSettings(brush.key);
                  // }}
                />
              )}
              </label>
            </div>
            
        ))}
        
      </div>
    </>
  );
}

export default BrushMenu;
