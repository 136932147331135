const positions = {}; // Объект для хранения данных о всех позициях

export function filterPosition(position, name = 'common', threshold) {
  const now = Date.now();

  // Инициализируем данные для текущего name, если их нет
  if (!positions[name]) {
    positions[name] = {
      prevPosition: position,
      lastUpdateTime: now,
      filteredPosition: position
    };
  }

  const { prevPosition, lastUpdateTime } = positions[name];
  const timeSinceLastUpdate = now - lastUpdateTime;

  if (timeSinceLastUpdate > 500) {
    // Если прошло больше 500 мс, обновляем позицию без фильтрации
    positions[name] = {
      prevPosition: position,
      lastUpdateTime: now,
      filteredPosition: position
    };
  } else {
    // Считаем расстояние между текущей и предыдущей позицией
    const dx = position.x - prevPosition.x;
    const dy = position.y - prevPosition.y;
    const distanceSquared = dx * dx + dy * dy;
    const thresholdSquared = threshold * threshold;

    if (distanceSquared <= thresholdSquared) {
      // Позиция изменилась незначительно, обновляем отфильтрованную позицию
      positions[name] = {
        prevPosition: position,
        lastUpdateTime: now,
        filteredPosition: position
      };
    }
    // Если скачок слишком велик, оставляем предыдущую отфильтрованную позицию
  }

  return positions[name].filteredPosition;
}
