import React, { useRef } from 'react';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';


function SavedColorsPalette({ gameContext, brushContext }) {

  const { t } = useTranslation();

  const {
    userSetsRef,
    saveUserSets,
    forceRender,

    showTemporaryHint,
  } = gameContext;

  const {
    setTheColorFun,
    premiumGrayscale,

    lastActionTimeRef,
    ColorHint,

  } = brushContext;

  function getBoxShadow(color) {
    const chromaColor = chroma(color);
    const lightness = chromaColor.luminance();
    return (lightness > 0.9 || chromaColor.alpha() < 0.1) ? '0 0 0 1px rgba(30, 30, 30, 0.3)' : '';
  }

  const convertColorToGrayscale = (savedColor) => {

    const alpha = chroma(savedColor).alpha();
    let rgb = chroma(savedColor).rgb();
    let average = (rgb[0] + rgb[1] + rgb[2]) / 3;
    savedColor = chroma([average, average, average]).alpha(alpha).hex();
    return savedColor;

  }

  const selectSavedCOlor = (savedColor) => {

    if (Date.now() - lastActionTimeRef.current < 300) { return; }
    if (premiumGrayscale) { savedColor = convertColorToGrayscale(savedColor) }
    setTheColorFun(savedColor);

    showTemporaryHint(
      <ColorHint text={t('tooltip.saved_color_taken')} hexColor={savedColor} />,
      {force: true, duration: 1500}, {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        minWidth: '100px',
        padding: '8px',
      },
      );

  }

  const removeSavedColor = (index) => {
    const newColors = [...userSetsRef.current.colors.slice(0, index), ...userSetsRef.current.colors.slice(index + 1)];

    lastActionTimeRef.current = Date.now();
    saveUserSets({ colors: newColors });
    forceRender();
  };

  const rows = [[], [], [], []];
  const showColors = userSetsRef.current.colors.slice(0, 20);
  showColors.forEach((savedColor, i) => {
    const rowIndex = Math.floor(i / 5);
    if (rows[rowIndex]) {
      rows[rowIndex].unshift({ savedColor, i });
    }
  });

  return (
    <div className="saved-colors-palette-container">
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} className="saved-colors-palette-row" style={{
          filter: premiumGrayscale ? 'grayscale(100%)' : null,
        }}
        >
          {row.map(({ savedColor, i }) => (
            <div key={i}
              onClick={(nativeEvent) => {
                if (nativeEvent.button === 2) {
                  removeSavedColor(i);
                } else {
                  selectSavedCOlor(savedColor);
                  // startPress({ savedColor, i });
                }
              }}
              // onMouseDown={(nativeEvent) => {
              //   selectSavedCOlor(savedColor);
              // }}
              // onMouseUp={stopPress}
              // onTouchStart={() => startPress({ savedColor, i })}
              // onTouchEnd={stopPress}
              onContextMenu={() => removeSavedColor(i)}
              style={{ backgroundColor: savedColor, boxShadow: getBoxShadow(savedColor) }}
              className="saved-colors-palette-color" />
          ))}
        </div>
      ))}
    </div>
  );
}

export default SavedColorsPalette;