// feather.js
import chroma from 'chroma-js';
import { createTaperingStroke, calculateDistance, interpolatePoints } from './helpers/tapering';
import { fillGradient, calculateGradientPoints } from './helpers/gradient'; 

import { brushDefaults } from './helpers/brushLoader';
import { drawStrokeWithGradient } from './marker';


export async function drawFeatherStroke(stroke, context) {

  let {
    color,
    gradientColor,
    lineWidth,
    points,
    shapeId,
    sets = {},
    brush,
  } = stroke;

  const defaultBrushSettings = brushDefaults[brush] || brushDefaults.feather;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);

  if (points.length === 0) return;
  if (points.length === 1) {
    return drawStrokeWithGradient(stroke, context, brushSettings)
  }

  let { 

    transparentEdges,
    opacityOnStart = 0,
    fadeStartPoint = 0.3,
    opacityOnEnd = 0,
    fadeEndPoint = 0.7,

  } = brushSettings;

  if (fadeStartPoint > fadeEndPoint) {
    const midpoint = (fadeStartPoint + fadeEndPoint) / 2;
    fadeStartPoint = midpoint;
    fadeEndPoint = Math.min(midpoint + 0.05, 1);
  }

  // Создаем отдельный буфер
  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  bufferCtx.lineCap = brushSettings.squareBrush ? 'square' : 'round';
  bufferCtx.lineJoin = brushSettings.squareBrush ? 'bevel' : 'round';

  const smoothPoints = interpolatePoints(points);
  const totalPoints = smoothPoints.length;

  createTaperingStroke({
    lineWidth,
    points,
    shapeId,
  }, bufferCtx, brushSettings);

  // Применяем цвет и прозрачность к буферу
  bufferCtx.globalCompositeOperation = 'source-in';

  if (transparentEdges 
    && (opacityOnStart < 1 || opacityOnEnd < 1)
    // && smoothPoints.length > 1
    && calculateDistance(smoothPoints[0], smoothPoints[smoothPoints.length - 1]) > 15
    ) {

    const chromaStartColor = chroma(color);
    const chromaEndColor = chroma(gradientColor || color);

    const originalStartColorlAlpha = chromaStartColor.alpha();
    const originalEndColorlAlpha = chromaEndColor.alpha();

    const colorOnStart = chromaStartColor.alpha(opacityOnStart * originalStartColorlAlpha).hex();
    const colorOnEnd = chromaEndColor.alpha(opacityOnEnd * originalEndColorlAlpha).hex();

    const gradientPoints = calculateGradientPoints(smoothPoints[0], smoothPoints[totalPoints-1]);

    const gradient = bufferCtx.createLinearGradient(
      gradientPoints.start.x, gradientPoints.start.y,
      gradientPoints.end.x, gradientPoints.end.y
      );
      
    gradient.addColorStop(0, colorOnStart);
    gradient.addColorStop(fadeStartPoint, color);
    gradient.addColorStop(fadeEndPoint, gradientColor || color);
    gradient.addColorStop(1, colorOnEnd);
    bufferCtx.fillStyle = gradient;
    bufferCtx.fillRect(0, 0, bufferCanvas.width, bufferCanvas.height);
  
  } else if (gradientColor) {

    fillGradient(bufferCtx, points, color, gradientColor, lineWidth)

  } else {

    bufferCtx.fillStyle = color;
    bufferCtx.fillRect(0, 0, bufferCanvas.width, bufferCanvas.height);

  }

  // Рисуем буфер на основном холсте
  context.drawImage(bufferCanvas, 0, 0);

}




