import { filterClosePoints, interpolatePointsUniform } from './points';


export function createTaperingStroke (stroke, context, brushSettings) {

  let {
    lineWidth,
    points,
    shapeId,
  } = stroke;

  let { 
    speedDependence,
    edgeWidening,
    centralWidth = 0.4,

    minWidthStart = 0.01, // Мин. толщина начала, 1 = lineWidth
    taperStartPoint = 0.5, // Длина сужения начала, 0.5 = целая половина штриха
    minWidthEnd = 0.01, // Мин. толщина конца, 1 = lineWidth
    taperEndPoint = 0.5, // Длина сужения конца

  } = brushSettings;

  if (taperStartPoint > taperEndPoint) {
    const midpoint = (taperStartPoint + taperEndPoint) / 2;
    taperStartPoint = midpoint;
    taperEndPoint = midpoint;
  }

  if (!speedDependence && !shapeId) {
    points = filterClosePoints(points, Math.min(lineWidth, 20));
    points = interpolatePointsUniform(points, Math.min(lineWidth, 50))
  }

  context.lineCap = brushSettings.squareBrush ? 'square' : 'round';
  context.lineJoin = brushSettings.squareBrush ? 'bevel' : 'round';

  const smoothPoints = interpolatePoints(points);
  const totalPoints = smoothPoints.length;

  // Вычисление центральной части без сужения
  let centerPartStart = Math.floor(totalPoints * taperStartPoint);
  let centerPartEnd = Math.floor(totalPoints * taperEndPoint);

  centerPartStart = Math.max(0, Math.min(centerPartStart, totalPoints - 1));
  centerPartEnd = Math.max(0, Math.min(centerPartEnd, totalPoints - 1));

  context.strokeStyle = 'rgba(255, 255, 255, 1)';
  context.beginPath();

  for (let i = 0; i < totalPoints - 1; i++) {

    const startPoint = smoothPoints[i];
    const endPoint = smoothPoints[i + 1];
    const segmentWidth = computeLineWidth({
      index: i,
      totalPoints,
      lineWidth,
      centerPartStart,
      centerPartEnd,
      minWidthStart,
      minWidthEnd,
      edgeWidening,
      centralWidth,
    });

    if (segmentWidth === 0) { continue; }
    // const segmentWidth1 = computeLineWidth(i, totalPoints, lineWidth, centerPartStart, centerPartEnd, minWidthStart, minWidthEnd);

    context.beginPath();
    context.moveTo(startPoint.x, startPoint.y);
    context.lineTo(endPoint.x, endPoint.y);
    context.lineWidth = segmentWidth;
    context.stroke();

  }

  
}



export function interpolatePoints(points, interpolationCoef = 1) {
  if (points.length < 2) return points;
  if (points.length === 2) { return linearInterpolate(points); }

  function distance(p1, p2) {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    return Math.sqrt(dx * dx + dy * dy);
  }

  let interpolatedPoints = [];

  // Start with the first point
  interpolatedPoints.push(points[0]);

  for (let i = 1; i < points.length; i++) {
    const prevPoint = points[i - 1];
    const currentPoint = points[i];

    // Calculate the distance between prevPoint and currentPoint
    const dist = distance(prevPoint, currentPoint);

    // Calculate the number of interpolations based on the distance
    const numInterpolations = Math.min(20, Math.round(interpolationCoef * dist));

    // Calculate the midpoint between prevPoint and currentPoint
    const midPoint = {
      x: (prevPoint.x + currentPoint.x) / 2,
      y: (prevPoint.y + currentPoint.y) / 2
    };

    const controlPoint = prevPoint;
    const startPoint = interpolatedPoints[interpolatedPoints.length - 1];
    const endPoint = midPoint;

    // Sample points along the quadratic Bézier curve
    for (let j = 1; j <= numInterpolations; j++) {
      const t = j / (numInterpolations + 1);

      const x =
        (1 - t) * (1 - t) * startPoint.x +
        2 * (1 - t) * t * controlPoint.x +
        t * t * endPoint.x;
      const y =
        (1 - t) * (1 - t) * startPoint.y +
        2 * (1 - t) * t * controlPoint.y +
        t * t * endPoint.y;

      interpolatedPoints.push({x, y});
    }

    // Add the end point of the segment
    interpolatedPoints.push(endPoint);
  }

  // Add the last point
  // interpolatedPoints.push(points[points.length - 1]);
  return interpolatedPoints;
}


export function linearInterpolate(points, interpolationCoef = 1) {
  if (points.length < 2) { return points; }

  const result = [points[0]];

  for (let i = 1; i < points.length; i++) {
    const start = points[i - 1];
    const end = points[i];

    const distance = Math.sqrt(
      Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2)
    );

    const numInterpolations = Math.max(1, Math.round(interpolationCoef * distance));

    for (let j = 1; j <= numInterpolations; j++) {
      const t = j / (numInterpolations + 1);
      const x = start.x + t * (end.x - start.x);
      const y = start.y + t * (end.y - start.y);
      result.push({x, y});
    }
  }

  result.push(points[points.length - 1]);
  return result;

}

export function computeLineWidth({
  index,
  totalPoints,
  lineWidth,
  centerPartStart,
  centerPartEnd,
  minWidthStart = 0.01,
  minWidthEnd = 0.01,
  edgeWidening = false,
  centralWidth = 0.2,
  smoothingFactor = 0.5
}) {

  function easeInOutQuad(t) {
    return t < 0.5
      ? 2 * t * t
      : 1 - Math.pow(-2 * t + 2, 2) / 2;
  }
  
  function interpolate(start, end, t, edgeWidening = false, power = 4) {
    let smoothT;
    if (edgeWidening) {
      smoothT = 1 - Math.pow(1 - t, power);
    } else {
      smoothT = easeInOutQuad(t);
    }
    return start + (end - start) * (smoothingFactor * smoothT + (1 - smoothingFactor) * t);
  }

  const minWidthStartPx = minWidthStart * lineWidth;
  const minWidthEndPx = minWidthEnd * lineWidth;
  const centerWidth = edgeWidening ? lineWidth * centralWidth : lineWidth;

  if (index <= centerPartStart) {
    const t = index / (centerPartStart || 1); // Avoid division by zero
    return interpolate(minWidthStartPx, centerWidth, t);
  } else if (index >= centerPartEnd) {
    const t = (index - centerPartEnd) / ((totalPoints - 1 - centerPartEnd) || 1); // Avoid division by zero
    return interpolate(centerWidth, minWidthEndPx, t);
  } else {
    return centerWidth;
  }
}

export function calculateDistance(point1, point2) {
  const dx = point2.x - point1.x;
  const dy = point2.y - point1.y;
  return Math.sqrt(dx * dx + dy * dy);
}

