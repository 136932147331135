import React from 'react';
import './LogOverlay.css';

function LogOverlay({ gameContext }) {

  const logs = window.lagLog;

  const { 
    closeMenu,
    showTemporaryHint,
  } = gameContext;

  const handleItemClick = (log)=>{
    navigator.clipboard.writeText(log.title);
    showTemporaryHint(log.title, {duration: 1000, force: true});
  }


  const handleCopyLog = ()=>{
    let text = logs.map((log, index) => {
      if (log.value) {
        return `${log.title} — ${log.value} `
      } else {
        return `${(log.timeFromStart/1000).toFixed(4)} ${(log.timeSinceLast/1000).toFixed(4)} — ${log.title} `
      }
    }).join('\n')
    
    navigator.clipboard.writeText(text);
    showTemporaryHint('Log copied to clipboard!', {duration: 1500, force: true});
  }
  
  return (
    <>
      <div className="overlay-background" onClick={()=>{closeMenu('lag')}}></div>
      <div className="log-menu scroll-active touch-active">
        <span className="log-title">Performance Log</span>
        <div className="log-list">
          {logs.map((log, index) => (
            <div key={index} 
            className="log-item"
            onClick={()=>{handleItemClick(log)}}
            >
              <span className="log-item-time">{log.title} {
                log.value ? (
                  <span className='semi-bold'>{log.value}</span>
                ) : (<>
                  <span className='semi-bold'>{(log.timeFromStart/1000).toFixed(4)}</span> s <span className='semi-bold'>+{(log.timeSinceLast/1000).toFixed(4)}</span> s
                  </>)
              }
              </span>
            </div>
          ))}
        </div>
        <button className="wide-button" onClick={handleCopyLog}>
          COPY to clibpoard
        </button>
        <button className="wide-button" onClick={()=>{closeMenu('lag')}}>
          Close
        </button>
      </div>
    </>
  );
}

export default LogOverlay;