// sprayNoBlur.js
import chroma from 'chroma-js';
import { brushDefaults } from './helpers/brushLoader';
import { drawPlainStroke } from './marker';

const defaultBrushSettings = brushDefaults.spray || {};

export async function drawSprayNoBlurStroke(stroke, context, softContext) {

  let {
    lineWidth,
    color,
    sets = {},
  } = stroke;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  const softness = brushSettings.softness || stroke.softness || 0;

  const numStrokes = 100;

  // Извлекаем исходную непрозрачность цвета
  const originalColor = chroma(color);
  const originalAlpha = originalColor.alpha();

  // Устанавливаем диапазон толщины линии
  const deltaLineWidth = lineWidth * softness * 3.5;
  const adjustedLineWidth = lineWidth * 1.05;
  const minLineWidth = adjustedLineWidth - deltaLineWidth * 1.5;
  const maxLineWidth = adjustedLineWidth + deltaLineWidth;

  const softCanvas = softContext.canvas;

  // Очищаем временный контекст
  softContext.clearRect(0, 0, softCanvas.width, softCanvas.height);

  // Параметр экспоненты для усиления уменьшения непрозрачности
  const exponent = 1.5; // Попробуйте 3 или 4 для более сильного эффекта

  // Вычисляем базовую непрозрачность для каждого штриха
  // Это значение можно скорректировать в зависимости от желаемой яркости
  const perStrokeAlpha = originalAlpha * 0.2;

  for (let i = 0; i < numStrokes; i++) {
    const t = i / (numStrokes - 1); // t от 0 до 1

    // Толщина линии увеличивается от minLineWidth до maxLineWidth
    const lineWidth_i = minLineWidth + t * (maxLineWidth - minLineWidth);

    // Непрозрачность уменьшается экспоненциально
    let opacity_i = perStrokeAlpha * Math.pow(1 - t, exponent);

    if (lineWidth_i <= 0) { continue; }

    if (lineWidth_i <= lineWidth / 2) { 
      opacity_i = opacity_i * (lineWidth_i / lineWidth)
    }

    // Устанавливаем непрозрачность цвета
    const color_i = originalColor.alpha(opacity_i).hex();

    const stroke_i = Object.assign({}, stroke, {
      lineWidth: lineWidth_i,
      color: color_i,
    });

    // Рисуем штрих на временном контексте
    await drawPlainStroke(stroke_i, softContext);
  }

  // Копируем содержимое временного контекста на основной контекст
  if (context) {
    context.drawImage(softCanvas, 0, 0);
    // Очищаем временный контекст для будущего использования
    softContext.clearRect(0, 0, softCanvas.width, softCanvas.height);
  }

}
