let touchStartTarget = null;

let contextMenuOpened = false;
let isTouched = false;

let hasMoved = false;

function createBetterClick({onClick, onContextMenu}) {

  function handleClick(e) {

    isTouched = false;
    onClick?.(e);

  }

  function handleTouchStart(e) {
    touchStartTarget = e.target;
    isTouched = true;

    contextMenuOpened = false;
    hasMoved = false;
  }

  function handleTouchMove() {
    hasMoved = true;
  }

  function handleContextMenu(e) {
    contextMenuOpened = true;
    onContextMenu?.(e)
  }

  async function handleTouchEnd(e) {

    const touch = e.changedTouches[0];
    const element = touchStartTarget;
    
    // Проверяем, находится ли точка отпускания над начальным элементом
    const rect = element.getBoundingClientRect();
    const isWithinBounds = 
      touch.clientX >= rect.left && 
      touch.clientX <= rect.right &&
      touch.clientY >= rect.top && 
      touch.clientY <= rect.bottom;

    await new Promise(resolve=>setTimeout(resolve, 100));

    if (!contextMenuOpened && isWithinBounds && isTouched) {
      isTouched = false;
      onClick?.(e);
    } 
  }

  return {
    onClick: handleClick,
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    onTouchEnd: handleTouchEnd,
    onContextMenu: handleContextMenu,
  };
}

export { createBetterClick };