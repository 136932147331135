import React, { useState, useEffect } from 'react';

const ConvyBrushIndicator = ({
  gameContext,
  canvasContext,
  brushContext,
}) => {

  const {
    gameInfoRef,
    userSetsRef,
    getUserGameSets,
  } = gameContext;

  const {
    canvasStyleDimensionsRef,
    canvasRef,
    isDrawingRef,
    isTouchDevice,
    mouseDetected,
    mousePositionRef,
    panOffset,
    rotation,
  } = canvasContext;

  const {
    actualSoftnessFun,
    gradientColor,
    visibleColorFun,
    visibleLineWidth,
    showPalette,
    isResizingBrush,
    isChangingBrightness,
    isChangingOpacity,
    isChangingSoftness,
    brushIndicatorFrame,
    activeTool,
    hotMouseActiveRef,
    brushSetsFun,
    brushLinkFun,

    brushTypeRef,

    hotMouseStartPositionRef,
  } = brushContext;


  const commonSets = brushSetsFun('common');
  const brushSets = brushSetsFun();
  const squareIndicator = commonSets.squareBrush && ['plain', 'dashed', 'outlined', 'pencil', 'feather'].includes(brushLinkFun());

  function positionInCanvas({ x, y }) {
    if (!canvasRef.current) { return { x: 0, y: 0 }; }
    const canvasRect = canvasRef.current.getBoundingClientRect();
    return {
      x: x - canvasRect.left,
      y: y - canvasRect.top,
    };
  }


  const canvasRect = canvasRef.current.getBoundingClientRect();

  const isShowingMenu = showPalette
  || isResizingBrush
  || isChangingBrightness
  || isChangingOpacity
  || isChangingSoftness;

  const pointerType = userSetsRef.current.pointerType;
  const fromOutsideWidth = visibleLineWidth / 2;
  const isInsideCanvas = (
    mousePositionRef.current.x >= canvasRect.left - fromOutsideWidth
    && mousePositionRef.current.x <= canvasRect.right + fromOutsideWidth
    && mousePositionRef.current.y >= canvasRect.top -fromOutsideWidth
    && mousePositionRef.current.y <= canvasRect.bottom + fromOutsideWidth
  );

  const pcPenDevice = pointerType === 'pen' && !isTouchDevice;
  const tabPenDevice = pointerType === 'pen' && isTouchDevice;

  const visible = !isShowingMenu
  && isInsideCanvas
  && (
    pointerType === 'mouse' 
    || (userSetsRef.current.solidBrushIndicator 
      && (pcPenDevice || (tabPenDevice && isDrawingRef.current)))
    )

  const indicatorPosition = hotMouseActiveRef.current ? {
    x: (hotMouseStartPositionRef.current.x - canvasRect.left),
    y: (hotMouseStartPositionRef.current.y - canvasRect.top)
  } : {
    x: (mousePositionRef.current.x - canvasRect.left),
    y: (mousePositionRef.current.y - canvasRect.top)
  }
  
  const actualSoftness = actualSoftnessFun();
  const blurSize = `${actualSoftness * visibleLineWidth}px`; // Пример коэффициента умножения на 3

  if (!canvasRef.current) { return null; }

  const gradientVisible = activeTool === 'brush' && getUserGameSets().gradientOn;

  const incicatorStyle = (userSetsRef.current.solidBrushIndicator && visibleLineWidth >= 3 && actualSoftness === 0) ? {
    background: 'transparent',
    border: '0.5px solid black',
    boxShadow: '0 0 0 0.5px grey',
  } : {
    background: isDrawingRef.current ?  '#00000000' : 
    gradientVisible ? `linear-gradient(to bottom, ${visibleColorFun()}, ${gradientColor || '#0000'})` :  visibleColorFun(), 

    boxShadow: actualSoftness ? 0 : brushIndicatorFrame,
    filter: `blur(${blurSize})`, 

  }

  const indicateCenter = 
  (['feather'].includes(brushTypeRef.current) 
  || brushSetsFun().tapering
  || (userSetsRef.current.pointerType === 'pen' 
  && (commonSets.pressureOn || commonSets.pressureOpacity)
  && brushSets.pressureAvailable
  )) 
  && !isDrawingRef.current

  return visible ? (
    <div className='temp-canvas' style={{
      overflow: 'hidden',
      transform: `translate(${panOffset.x - canvasStyleDimensionsRef.current.width / 2}px, ${panOffset.y}px)`,
      width: canvasRect.width,
      height: canvasRect.height,
      zIndex: 120,
      pointerEvents: 'none',
    }}>
      <div
        style={{
          position: 'absolute',
          left: `${indicatorPosition.x - visibleLineWidth / 2}px`,
          top: `${indicatorPosition.y - visibleLineWidth / 2}px`,
          width: `${visibleLineWidth}px`,
          height: `${visibleLineWidth}px`,
          borderRadius: squareIndicator ? '' : '50%',
          pointerEvents: 'none',
          cursor: 'none',
          zIndex: 150,
          ...incicatorStyle,
        }}
      >
        { indicateCenter && (
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              width: '1px',
              height: '1px',
              background: 'black',
              borderRadius: '50%',
              transform: 'translate(-50%, -50%)',
              boxShadow: '0 0 0 0.5px grey',
            }}
          />
        )}
      </div>
    </div>
  ) : null;
};

export default ConvyBrushIndicator;
