export function fillGradient(context, points, color, gradientColor, lineWidth) {

  if (gradientColor) {
    let startPoint, endPoint;

    if (points.length === 1) {
      startPoint = points[0];
      endPoint = createPointForDirection(startPoint);
    } else {
      startPoint = points[0];
      endPoint = points[points.length - 1];
    }

    const gradientPoints = calculateGradientPoints(startPoint, endPoint, lineWidth);

    try {

      const gradient = context.createLinearGradient(
        gradientPoints.start.x, gradientPoints.start.y,
        gradientPoints.end.x, gradientPoints.end.y
      );
          
      gradient.addColorStop(0, color);
      gradient.addColorStop(1, gradientColor);

      context.globalCompositeOperation = 'source-in';
      context.fillStyle = gradient;
      context.fillRect(0, 0, context.canvas.width, context.canvas.height);
        
    } catch (e) {
      context.globalCompositeOperation = 'source-in';
      context.fillStyle = color;
      context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    }

  } else {
    context.globalCompositeOperation = 'source-in';
    context.fillStyle = color;
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
  }

}


export function calculateGradientPoints(startPoint, endPoint, lineWidth = 0) {
  const dx = endPoint.x - startPoint.x;
  const dy = endPoint.y - startPoint.y;
  const length = Math.sqrt(dx * dx + dy * dy);

  // Проверяем, не слишком ли близко точки друг к другу
  if (length < 0.001) {
    // Если точки слишком близко, создаем новую конечную точку
    endPoint = createPointForDirection(startPoint);
    return calculateGradientPoints(startPoint, endPoint, lineWidth);
  }

  const unitX = dx / length;
  const unitY = dy / length;

  const halfLineWidth = lineWidth / 2;
  const gradientStartX = startPoint.x - unitX * halfLineWidth;
  const gradientStartY = startPoint.y - unitY * halfLineWidth;
  const gradientEndX = endPoint.x + unitX * halfLineWidth;
  const gradientEndY = endPoint.y + unitY * halfLineWidth;

  return {
    start: { x: gradientStartX, y: gradientStartY },
    end: { x: gradientEndX, y: gradientEndY }
  };
}


function createPointForDirection(point) {
  return { x: point.x, y: point.y + 3 };
}
