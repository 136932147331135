
function newCanvas (context) {

  const newCanvas = document.createElement('canvas');
  newCanvas.width = context.canvas.width;
  newCanvas.height = context.canvas.height;
  const newCtx = newCanvas.getContext('2d');

  return newCtx;
  
}

function cloneCanvas (context) {

  const newCanvas = document.createElement('canvas');
  newCanvas.width = context.canvas.width;
  newCanvas.height = context.canvas.height;
  const newCtx = newCanvas.getContext('2d');

  newCtx.drawImage(newCanvas.canvas, 0, 0);

  
}


module.exports = {
  newCanvas,
  cloneCanvas,
};