// useMountHistory.js
import { useCallback, useEffect, useRef, useState } from 'react';

import { useGameContext } from '../../contexts/GameContext';
import { useHistoryContext  } from '../../contexts/HistoryContext';
import { useBrushContext  } from '../../contexts/BrushContext';

export const useMountHistory = ({
  render,
}) => {

  const strokesUpdatedRef = useRef(false);

  const gameContext = useGameContext();
  const historyContext = useHistoryContext();
  const brushContext = useBrushContext();

  const { 
    gameInfo, 
    gameInfoRef, 
    gameIsLoaded, 
    premiumIsLoaded,
    strokesAreLoaded, setStrokesAreLoaded,
    strokesLoadTimeRef,

    serverTimeFun,

    moderatorModeRef,
    socketRef,
    socketStatus,
    
    activeUserIdRef,
    userSetsRef,
    getUserGameSets,

    playerCountRef,
   } = gameContext;
  
  const {
    userStrokesRef,
    setRedrawer,
  } = historyContext;

  const {
    setTheColorFun,
    setTheSizeFun,
    setSoftness,
    setBrushType,
    setEffectType,
    lastColorsRef,

    brushColorRef,
    eraserColorRef,
  } = brushContext;


  useEffect(() => {
    const socket = socketRef.current;

    if (socket) {

      const handleRender = async (pref, callback) => {
        callback?.(await render(pref));
      };

      // Обработчик чужих штрихов
      const handleStrokes = ({userId: companionUserId, strokes, length, author }) => {

        length = length || strokes.length;
        let companionStrokes = userStrokesRef.current[companionUserId] || [];

        strokes.forEach(stroke=>{ delete stroke.rendered; })

        const cutStrokes = companionStrokes.slice(length - strokes.length);
        cutStrokes.forEach(cutStroke=>{
          strokes.forEach(stroke=>{
            if (stroke.time === cutStroke.time) { stroke.rendered = cutStroke.rendered; }
          })
        })
        
        const updatedStrokes = companionStrokes.slice(0, length - strokes.length).concat(strokes);
        userStrokesRef.current[companionUserId] = updatedStrokes;

        setRedrawer(i => i + 1);

        const game = gameInfoRef.current;
        const lastStroke = strokes[strokes.length - 1];

        if (game?.board && !lastStroke?.cancelled) {
          const brushName =  lastStroke.effect || lastStroke.brush || lastStroke.type;
          const color =  lastStroke.effect ? '#FFFFFF77' : (lastStroke.color || '#FFFFFF77');
          game.chat.push({
            author,
            color,
            text: brushName || '?',
          })
        }
        // gameInfoRef.current

      };

      // Установка слушателей
      socket.on('strokes', handleStrokes);
      socket.on('render', handleRender);

      // Очистка слушателей при размонтировании или изменении статуса сокета
      return () => {
        socket.off('strokes', handleStrokes);
        socket.off('render', handleRender);
      };
    }
  }, [socketStatus]);


  // Загрузка штрихов из истории
  useEffect(() => {

    if (!premiumIsLoaded) { return; }

    function setBrushes() {
      if (userSetsRef.current.brushType) { setBrushType(userSetsRef.current.brushType); }
      if (userSetsRef.current.effectType) { setEffectType(userSetsRef.current.effectType); }
    }
    setBrushes();

    function startBackground (localHistory, myStrokes) {

      let userBackgroundColor = userSetsRef.current.startBgColor;
      if (
        !moderatorModeRef.current
        && !gameInfo.accessory 
        && !gameInfo.settings?.transparent 
        && `${game?.user_key}` === `${activeUserIdRef.current}` 
        && userBackgroundColor
        && userBackgroundColor !== '#FFFFFFFF'
        && localHistory.length === 0 
        && myStrokes.length === 0
        ) {
          myStrokes.push({
            time: serverTimeFun(),
            userId: activeUserIdRef.current,
            type: 'background',
            color: userBackgroundColor,
          });
          eraserColorRef.current = userBackgroundColor;
      }
  
    }

    if (strokesUpdatedRef.current) {return;}

    window.logPerformance('Started updating strokes')

    const game = gameInfo;
    if (!game || game?.error) {return;} 

    const localStrokes = userStrokesRef.current;
    const localHistory = localStrokes?.[activeUserIdRef.current] || [];

    const strokes = game.strokes || {};
    strokes[activeUserIdRef.current] = strokes[activeUserIdRef.current] || []; // Создаем историю для текущего пользователя
    const myStrokes = strokes[activeUserIdRef.current];

    startBackground (localHistory, myStrokes);

    if (localHistory.length > myStrokes.length) {
      strokes[activeUserIdRef.current] = localHistory;
    } else {

      const lastStroke = myStrokes[myStrokes.length - 1];
      if (lastStroke?.lineWidth) {setTheSizeFun(lastStroke.lineWidth);}
      if (lastStroke?.softness) {setSoftness(lastStroke.softness);}
    }

    {
      const userGameSets = getUserGameSets();

      if (userGameSets.color) {
        setTheColorFun(userGameSets.color);
      } else if (userSetsRef.current.startColor) {
        setTheColorFun(userSetsRef.current.startColor);
      }
      if (userGameSets.gradientColor) {
        setTheColorFun(userGameSets.gradientColor, 'gradientColor');
      }
      if (userGameSets.eraserColor) {
        eraserColorRef.current = userGameSets.eraserColor
      }
      lastColorsRef.current = game.colors?.[activeUserIdRef.current] || [];
    }

    userStrokesRef.current = strokes;
    playerCountRef.current = Object.keys(strokes).length;

    setRedrawer(i => i + 1); 
    strokesUpdatedRef.current = true;
    window.logPerformance('Strokes are updated')

    setStrokesAreLoaded(true);
    strokesLoadTimeRef.current = serverTimeFun();

    // setTimeout(() => {
    //   setStrokesAreLoaded(true);
    //   strokesLoadTimeRef.current = serverTimeFun();
    // }, 50);

  
  }, [premiumIsLoaded]);

};


