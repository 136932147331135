// contextMenuEmulator.js
export class ContextMenuEmulator {
  constructor(options = {}) {
    this.longPressDelay = options.delay || 500; // Delay in ms
    this.moveThreshold = options.threshold || 10; // Movement threshold in pixels
    this.pressTimer = null;
    this.startX = 0;
    this.startY = 0;
    this.targetElement = null;
    this.isAppleDevice = this.checkIfAppleDevice();

    // Binding methods to the context
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.preventDefaultContextMenu = this.preventDefaultContextMenu.bind(this);
  }

  checkIfAppleDevice() {
    const userAgent = navigator.userAgent.toLowerCase();
    const iosDevices = /iphone|ipad|ipod|macintosh|mac os/;
    return iosDevices.test(userAgent);
  }

  init() {
    if (!this.isAppleDevice) return; // Only initialize on Apple devices

    document.addEventListener('touchstart', this.handleTouchStart, false);
    document.addEventListener('touchend', this.handleTouchEnd, false);
    document.addEventListener('touchmove', this.handleTouchMove, false);
    document.addEventListener('contextmenu', this.preventDefaultContextMenu, false);
  }

  destroy() {
    if (!this.isAppleDevice) return;

    document.removeEventListener('touchstart', this.handleTouchStart);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('contextmenu', this.preventDefaultContextMenu);
  }

  handleTouchStart(e) {
    if (e.touches.length !== 1) return; // Ignore multitouch

    // Ignore events on the canvas element to avoid interference with drawing
    if (e.target.tagName.toLowerCase() === 'canvas') return;

    const touch = e.touches[0];
    this.startX = touch.clientX;
    this.startY = touch.clientY;
    this.targetElement = e.target;

    this.pressTimer = setTimeout(() => {
      this.triggerContextMenu(e);
    }, this.longPressDelay);
  }

  handleTouchEnd() {
    clearTimeout(this.pressTimer);
    this.targetElement = null;
  }

  handleTouchMove(e) {
    if (!this.targetElement) return;

    const touch = e.touches[0];
    const moveX = Math.abs(touch.clientX - this.startX);
    const moveY = Math.abs(touch.clientY - this.startY);

    if (moveX > this.moveThreshold || moveY > this.moveThreshold) {
      clearTimeout(this.pressTimer);
      this.targetElement = null;
    }
  }

  triggerContextMenu(originalEvent) {
    if (!this.targetElement) return;

    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    }
    

    // Create and dispatch a contextmenu event
    const contextMenuEvent = new MouseEvent('contextmenu', {
      bubbles: true,
      cancelable: true,
      view: window,
      button: 2,
      buttons: 2,
      clientX: this.startX,
      clientY: this.startY,
      screenX: originalEvent.touches[0].screenX,
      screenY: originalEvent.touches[0].screenY
    });

    this.targetElement.dispatchEvent(contextMenuEvent);
  }

  preventDefaultContextMenu(e) {
    e.preventDefault();
    return false;
  }
}
