// PaletteInstruments.js

import React, { useState, useRef } from 'react';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import './PaletteInstruments.css';  // Импортируем CSS файл
import SavedColorsPalette from './SavedColorsPalette';
import ColorModeSwitch from './ColorModeSwitch';

function PaletteInstruments({ 
  gameContext,
  brushContext,
  onClose,
 }) {

  const { t } = useTranslation();

  const {
    gameInfoRef,
    saveUserGameSets,
    getUserGameSets,

    userSetsRef,
    saveUserSets,
    forceRender,
    delayPremiumMenu,
    premiumFeaturesRef,

    showTemporaryHint,
    appleHaptic,

  } = gameContext;
  
  const {
    color, 
    setTheColorFun, 
    premiumGrayscale,
    setPremiumGrayscale,

    prevColorRef,

    ColorHint,

    gradientColor,
    gradientPalette, setGradientPalette,

    lastActionTimeRef,

  } = brushContext;

  const addCurrentColor = () => {

    if (!premiumFeaturesRef.current.includes('saveColors') 
    || userSetsRef.current.premiumOff) {
      delayPremiumMenu('menu');
      return;
    }
    const newColors = [color, ...userSetsRef.current.colors];
    saveUserSets({colors: newColors});
    forceRender();

    appleHaptic('soft');

  };

  const handleGradient = () => {

    if (Date.now() - lastActionTimeRef.current < 500) {return;}
    if (!getUserGameSets().gradientOn) {
      return showTemporaryHint(t('tooltip.gradient'),  {duration: 6000})
    }
    setGradientPalette(true);

  }

  const toggleGradient = () => {
    let newValue = !getUserGameSets().gradientOn;
    lastActionTimeRef.current = Date.now();
    if (!newValue) { setGradientPalette(false) }
    saveUserGameSets({gradientOn: newValue})

    return showTemporaryHint(t(`tooltip.${newValue ? 'gradient_on' : 'gradient_off'}`),  {duration: 1500, force: true}, {minWidth: '200px'})

  }

  const rows = [[], [], [], []];
  const showColors = userSetsRef.current.colors.slice(0, 20);
  showColors.forEach((savedColor, i) => {
    const rowIndex = Math.floor(i / 5);
    if (rows[rowIndex]) {
      rows[rowIndex].unshift({savedColor, i});
    }
  });

  return (
    <div className="palette-instruments-container">
    <SavedColorsPalette
      gameContext={gameContext}
      brushContext={brushContext}
    />
    <div className="controls-container">
      <div 
        className='top-controls'
        onClick={ handleGradient }
        onContextMenu={ toggleGradient }
      >
        <div
          className={`gradient-color-switch ${getUserGameSets().gradientOn ? 'gradient-on' : ''}`}
          style={{ background: getUserGameSets().gradientOn ? 
            `linear-gradient(to right, ${color}, ${gradientColor || '#0000'})` :
            `linear-gradient(to right, white, black)`
           }}
        />
      </div>

      <div className="bottom-controls">
        <div onClick={addCurrentColor} className="saved-add-color">
          <svg className="saved-icon" viewBox="0 0 24 24">
            <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
          </svg>
        </div>
        {premiumFeaturesRef.current.includes('colors') && !userSetsRef.current.premiumOff && (
          <ColorModeSwitch 
            premiumGrayscale={premiumGrayscale} 
            setPremiumGrayscale={setPremiumGrayscale} 
          />
        )}
      </div>
    </div>
  </div>
  );
}

export default PaletteInstruments;
