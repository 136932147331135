import { useRef, useEffect, useState, useCallback, } from 'react';
import { useTranslation } from 'react-i18next';

import { useGameContext } from '../../contexts/GameContext';
import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';
import { useHistoryContext  } from '../../contexts/HistoryContext';

import { drawPlainMarkerStroke } from './brushes/marker';
import { drawDashedStroke } from './brushes/dashed';
import { drawOutlinedStroke } from './brushes/outlined';
import { drawSprayStroke } from './brushes/spray';
import { drawSprayNoBlurStroke } from './brushes/sprayNoBlur';
import { drawFeatherStroke } from './brushes/feather';
import { filterClosePoints } from './brushes/helpers/points';
import { drawBristleStroke } from './brushes/bristle';
import { drawRembrandtStroke } from './brushes/rembrandt';
import { drawSparkleStroke } from './brushes/sparkle';
import { drawNeonStroke } from './brushes/neon';

export const useSoftDraw = ({

  wipeTempCanvas,

  saveHistory,
  getEventPos,
  redrawCanvas,
  
}) => {

  const { t } = useTranslation();

  const gameContext = useGameContext();
  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();
  const HistoryContext = useHistoryContext();



  const {
    activeUserIdRef,
    gameInfoRef,

    serverTimeFun,
    lastStrokeTimeRef,

    isAppleDevice,
    userSetsRef,
    forceRender,

    saveUserSets, 

    drawMethodsRef,
    showTemporaryHint,
  } = gameContext;

  const {
    tempContextRef,
    contextRef,

    zoomFactorRef,
    isDrawingRef,
    setDrawingPosition,
    strokeTimeRef,

    isZoomingRef,

    isRenderingStrokesRef,

  } = canvasContext;

  const {
    colorRef,
    visibleColorFun,
    gradientColorFun,
    actualSoftnessFun,
    brushSetsFun,
    combinedSetsFun,
    brushLinkFun,

    lineWidthRef,
    showPalette, setShowPalette,

    softnessRef,

    brushTypeRef,
    effectTypeRef,
    activeToolRef,

    lastActionTimeRef,

  } = brushContext;

  const {
    userStrokesRef,
    setRedrawer,
  } = HistoryContext;

  const lastPointRef = useRef([]);
  const currentStrokeRef = useRef([]);

  const animationFrameIdRef = useRef(null);
  const isDrawingQueuedRef = useRef(false);


  const addStroke = (currentPoints, stroke = {}) => {

    if (isRenderingStrokesRef.current) { return; }

    let points = [...currentPoints];

    const combinedSets = combinedSetsFun()
    const basicBrushLink = brushLinkFun();

    const smooth = (!combinedSets.speedDependence && !combinedSets.spreading && !stroke.shapeId && !stroke.disableSmoothing);
    if (smooth) {
      points = filterClosePoints(points, Math.round(combinedSets.smoothing / zoomFactorRef.current))
    }

    const effect = activeToolRef.current === 'effect' ? effectTypeRef.current : null;

    const newStroke = {
      points,
      color: colorRef.current, 
      gradientColor: gradientColorFun(),
      lineWidth: lineWidthRef.current, 
      softness: softnessRef.current, 
      time: isDrawingRef.current?.started,
      // time: strokeTimeRef.current,
      endTime: serverTimeFun(),
      type: 'stroke', // Указываем тип записи как штрих
      userId: activeUserIdRef.current,
      tool: activeToolRef.current,
      brush: basicBrushLink,
      effect,
      sets: combinedSets,
      ...stroke,
    };

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];
    myStrokes.push(newStroke);
    myStrokes = myStrokes.filter(stroke => !stroke.cancelled);

    userStrokesRef.current[activeUserIdRef.current] = myStrokes;
    setRedrawer(i => i + 1);
    saveHistory();

  };

  const countPointPressure = ({point ,nativeEvent,}) =>{

    const combinedSets = combinedSetsFun();

    if (
      nativeEvent.pointerType === 'pen' 
      && combinedSets.pressureAvailable
      && (combinedSets.pressureOn || combinedSets.pressureOpacity)
      && activeToolRef.current !== 'eraser'
      ) {
        point.pressure = nativeEvent.pressure * (combinedSets.sensitivity || 1);

        if (combinedSets.minimalPressure) {
          point.pressure = Math.max(point.pressure, combinedSets.minimalPressure)
        }
        if (combinedSets.pressureExponent > 1) {
          point.pressure = Math.pow(point.pressure, combinedSets.pressureExponent);
        }

        point.pressure  = Math.ceil(point.pressure * 1000) / 1000;

      }


    // if (!point.pressure && combinedSets.ragged) {
    //   point.pressure = (point.pressure || 1) * Math.exp(-combinedSets.ragged * Math.random());
    // }

    if (
      userSetsRef.current.testPressure
      && combinedSets.pressureAvailable
      && (combinedSets.pressureOn || combinedSets.pressureOpacity)
      && activeToolRef.current !== 'eraser'
      ) {
      point.pressure = (point.pressure || 1) * Math.exp(-3 * Math.random());
    }


  }

  const startDrawing = ({ nativeEvent }) => {

    if (isDrawingRef.current && isDrawingRef.current?.pointerId !== nativeEvent.pointerId) { return; }
    if (isZoomingRef.current) { return; }
    if (Date.now() - lastActionTimeRef.current < 100) {return;}

    if (userSetsRef.current.pointerType !== nativeEvent.pointerType) {
      saveUserSets({ pointerType: nativeEvent.pointerType})
    }

    const position = { x: nativeEvent.clientX, y: nativeEvent.clientY };
    setDrawingPosition(position);

    const point = getEventPos(nativeEvent);

    isDrawingRef.current = { 
      pointerId: nativeEvent.pointerId,
      pointerType: nativeEvent.pointerType,
      started: serverTimeFun(),
      lastMovement: serverTimeFun(),
      lastPoint: point,
    };

    const strokeFixStartTime = Math.max(serverTimeFun(), lastStrokeTimeRef.current + Math.round(Math.random() * 50))

    strokeTimeRef.current = strokeFixStartTime;
    if (gameInfoRef.current?.mode === 'line') { redrawCanvas(); }

    tempContextRef.current.save();

    const basicBrushLink = brushLinkFun();

    if (['pencil'].includes(basicBrushLink)) {tempContextRef.current.globalAlpha = 0.7;} 
    else if (['watercolor'].includes(basicBrushLink)) {tempContextRef.current.globalAlpha = 0.5;} 
    else { tempContextRef.current.globalAlpha = 1; }

    countPointPressure({point, nativeEvent})

    lastPointRef.current = point;
    currentStrokeRef.current = [point];

    forceRender();

  };

  const draw = async ({ nativeEvent }) => {
    // return await drawDirect({ nativeEvent });
    return await drawOptimized({ nativeEvent });
  };

  const drawDirect = async ({ nativeEvent }) => {

    if (isDrawingRef.current?.pointerId !== nativeEvent.pointerId) { return; }

    const position = { x: nativeEvent.clientX, y: nativeEvent.clientY };
    setDrawingPosition(position)

    const point = getEventPos(nativeEvent)
    
    const sets = brushSetsFun();
    const combinedSets = combinedSetsFun();
    countPointPressure({point, nativeEvent})
 
    lastPointRef.current = point;
    currentStrokeRef.current.push(point);
    let points = currentStrokeRef.current;
    if (!combinedSets?.speedDependence) {
      points = filterClosePoints(points, Math.round(combinedSets.smoothing / zoomFactorRef.current))
    }

    const stroke = {
      color: visibleColorFun(),
      gradientColor: gradientColorFun(),
      lineWidth: lineWidthRef.current,
      points,
      sets: combinedSets,
      time: isDrawingRef.current.started,
      // time: strokeTimeRef.current,
      isEffect: activeToolRef.current === 'effect',
    }
    
    scheduleShape({point, nativeEvent});
    drawPreview(stroke, { sets });


  };

  const drawOptimized = async ({ nativeEvent }) => {

    if (isDrawingRef.current?.pointerId !== nativeEvent.pointerId) { return; }

    const position = { x: nativeEvent.clientX, y: nativeEvent.clientY };
    setDrawingPosition(position)

    const point = getEventPos(nativeEvent)
    
    const sets = brushSetsFun();
    const combinedSets = combinedSetsFun();
    countPointPressure({point, nativeEvent})
 
    lastPointRef.current = point;
    currentStrokeRef.current.push(point);
    let points = currentStrokeRef.current;
    if (!combinedSets?.speedDependence) {
      points = filterClosePoints(points, Math.round(combinedSets.smoothing / zoomFactorRef.current))
    }

    const stroke = {
      color: visibleColorFun(),
      gradientColor: gradientColorFun(),
      lineWidth: lineWidthRef.current,
      points,
      sets: combinedSets,
      time: isDrawingRef.current.started,
      // time: strokeTimeRef.current,
      isEffect: activeToolRef.current === 'effect',
    }

    scheduleShape({point, nativeEvent});

    // Если анимация уже запланирована, выходим
    if (isDrawingQueuedRef.current) return;
    isDrawingQueuedRef.current = true;

    // Запрашиваем анимацию
    animationFrameIdRef.current = requestAnimationFrame(() => {
      isDrawingQueuedRef.current = false;
      drawPreview(stroke, { sets });
    });

  };


  const drawPreview = async (stroke, more = {})=>{

    wipeTempCanvas();
    const context = tempContextRef.current;

    const {
      brush = brushLinkFun(),
      softness = actualSoftnessFun(),
      sets = brushSetsFun(),
      params =  { 
        isApple: isAppleDevice(),
       },
    } = more;

    const previewComplexBrushes = !userSetsRef.current.moreCache;

    if (['feather', 'ink'].includes(brush)) {
      await drawFeatherStroke(stroke, context);
    } else if (brush === 'dashed') {
      await drawDashedStroke(stroke, context);
    } else if (brush === 'outlined') {
      await drawOutlinedStroke(stroke, context);
    } else if (brush === 'bristle' && previewComplexBrushes) {
      await drawBristleStroke(stroke, context);
    } else if (brush === 'rembrandt' && previewComplexBrushes) {
      await drawRembrandtStroke(stroke, context);
    } else if (brush === 'neon' && previewComplexBrushes) {
      await drawNeonStroke(stroke, context);
    } else if (brush === 'sparkle' && previewComplexBrushes) {
      await drawSparkleStroke(stroke, context);
    } else if (brush === 'test' && previewComplexBrushes) {
    } else if (
      (brush === 'spray' || softness > 0)
      // && (previewComplexBrushes || !isAppleDevice())
      ) {
      stroke.softness = softness;
      if (isAppleDevice() && sets.appleSprayFix) {
        await drawSprayNoBlurStroke(stroke, null, context)
      } else {
        await drawSprayStroke(stroke, null, context, params)
      }
    } else {
      await drawPlainMarkerStroke(stroke, context)
    }

  }

  const finishDrawing = ({ nativeEvent, force }) => {

    if (!isDrawingRef.current) { return; }
    if (!force && isDrawingRef.current?.pointerId !== nativeEvent.pointerId) { return };

    addStroke(currentStrokeRef.current);
    isDrawingRef.current = null;

    currentStrokeRef.current = [];
    tempContextRef.current.restore();

    // Отменяем запланированный кадр анимации, если он есть
    if (animationFrameIdRef.current) {
      cancelAnimationFrame(animationFrameIdRef.current);
      animationFrameIdRef.current = null;
      isDrawingQueuedRef.current = false;
    }
    wipeTempCanvas();

  };

  const scheduleShape = ({ point, nativeEvent }) => {

    if (!userSetsRef.current.smartShape) { return; }

    function countDistance(p1, p2) {
      const dx = p2.x - p1.x;
      const dy = p2.y - p1.y;
      return Math.sqrt(dx * dx + dy * dy);
    }

    const distance = countDistance(isDrawingRef.current.lastPoint, point) * zoomFactorRef.current;

    if (distance > 3) {

      clearTimeout(isDrawingRef.current.shapeTimeout)

      isDrawingRef.current.lastMovement = serverTimeFun();
      isDrawingRef.current.lastPoint = point;

      const started = isDrawingRef.current.started;
      isDrawingRef.current.shapeTimeout = setTimeout(() => {
        createShape({ started, nativeEvent })
      }, 1000);

    } 
  

  }

  const createShape = ({ started, nativeEvent }) => {

    if (!isDrawingRef.current) { return; }
    if (isDrawingRef.current.started !== started) { return; }

    const passed = serverTimeFun() - isDrawingRef.current.lastMovement;
    if (passed < 700) { return; }

    isDrawingRef.current = null;

    const { points, center } = require('./autoshape/autoshape').processPoints(currentStrokeRef.current);

    window.croco?.haptic?.();
    showTemporaryHint(t('tooltip.shape_transfer_started'), {force: true, duration: 2000});

    drawMethodsRef.current.handleTransformStart({
      nativeEvent,
      points,
      center,
    })
    currentStrokeRef.current = [];
    tempContextRef.current.restore();

    
    // addStroke(points);
    // currentStrokeRef.current = [];
    // tempContextRef.current.restore();

    // // Отменяем запланированный кадр анимации, если он есть
    // if (animationFrameIdRef.current) {
    //   cancelAnimationFrame(animationFrameIdRef.current);
    //   animationFrameIdRef.current = null;
    //   isDrawingQueuedRef.current = false;
    // }
    // wipeTempCanvas();

  };
   

  const fill = ({nativeEvent}) => {

    if (isRenderingStrokesRef.current) { return; }

    if (Date.now() - lastActionTimeRef.current < 100) {return;}
    lastActionTimeRef.current = Date.now();

    const { x, y } = getEventPos(nativeEvent);

    let fillStroke;

    if (ifBackgroundFill()) {
      
      fillStroke = {
        time: serverTimeFun(),
        userId: activeUserIdRef.current,
        type: 'background',
        color: colorRef.current,
      }

    } else {

      const sets = brushSetsFun('filler');

      fillStroke = {
        time: serverTimeFun(),
        userId: activeUserIdRef.current,
        type: 'fill',
        x: x,
        y: y,
        color: colorRef.current,
        sets,
      };
    }

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];

    myStrokes.push(fillStroke);
    myStrokes = myStrokes.filter(stroke=> !stroke.cancelled);

    userStrokesRef.current[activeUserIdRef.current] = myStrokes;
    setRedrawer(i => i + 1);
    saveHistory ()

  }

  function ifBackgroundFill () {

    const allStrokes = Object.values(userStrokesRef.current).flat();
    const combinedStrokes = allStrokes.filter(stroke => !stroke.cancelled).sort((a, b) => a.time - b.time);
    const lastClearIndex = combinedStrokes.map(stroke => stroke.type).lastIndexOf('clear');

    const strokesToRender = combinedStrokes.slice(lastClearIndex + 1);
    const hasUsualStrokes = strokesToRender.find(stroke=>stroke.type !== 'background');
    return !hasUsualStrokes;
    
  }

  return {

    addStroke,
    drawPreview,

    startDrawing,
    draw,
    finishDrawing,

    fill,
  }
};

