// texture.js
import chroma from 'chroma-js';
import { drawPlainStroke } from './marker'; // Reusing the seededRandom function
import { 
  duplicateCanvasContext,
  applyTextureByStrokeMask,
} from './helpers/texturize';

import { getTexture, brushDefaults } from './helpers/brushLoader';
const defaultBrushSettings = brushDefaults.texture;

export async function drawTextureStroke({
  color,
  gradientColor,
  lineWidth,
  points,
  softness,
  time,
  sets = {},
}, context, params) {

  if (points.length === 0) return;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  const {
    texture,
    textureScale = 1,
    opacity,
    blending,
  } = brushSettings;

  const textureName = texture || 'waterAi';
  let theTexture = await getTexture('texture', textureName, 'light');

  const cloneContext = duplicateCanvasContext(context);
  const chromaColor = chroma(color);
  const originalAlpha = chromaColor.alpha();

  const newAlpha = originalAlpha * brushSettings.opacity;

  color = chromaColor.alpha(1).hex();

  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = cloneContext.canvas.width;
  bufferCanvas.height = cloneContext.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  bufferCtx.lineCap = 'round';
  bufferCtx.lineJoin = 'round';

  // Возвращаем режим наложения в нормальное состояние
  bufferCtx.globalCompositeOperation = 'source-over';

  // Применяем текстуру к основному мазку
  if (theTexture) {

    let strokeMaskForBlurCtx = createStrokeMask({
      points,
      lineWidth,
      color,
      time,
    }, cloneContext, brushSettings);

    applyTextureByStrokeMask({
      context: cloneContext,
      bufferCtx,
      strokeMaskCtx: strokeMaskForBlurCtx,
      texture: theTexture,
      color: 'white',
      opacity,
      textureScale,
    })

  }

  // Рисуем буфер на основном холсте
  cloneContext.globalAlpha = newAlpha;
  cloneContext.drawImage(bufferCanvas, 0, 0);
  cloneContext.globalAlpha = 1; // Возвращаем значение по умолчанию

  context.drawImage(cloneContext.canvas, 0, 0)

}



export function createStrokeMask (stroke, context, brushSettings) {

  const canvas = context.canvas;

  const maskCanvas = document.createElement('canvas');
  maskCanvas.width = canvas.width;
  maskCanvas.height = canvas.height;
  const maskCtx = maskCanvas.getContext('2d');

  maskCtx.lineCap = 'round';
  maskCtx.lineJoin = 'round';

  const { lineWidth } = stroke;
  const { blending = 0.2 } = brushSettings;

  if (blending) {
    const blurSize = lineWidth * blending * 0.5;
    maskCtx.filter = `blur(${blurSize}px)`;
  }

  drawPlainStroke(stroke, maskCtx, brushSettings)
  return maskCtx;
  
}



