// neon.js
import chroma from 'chroma-js';
import { brushDefaults } from './helpers/brushLoader';
import { drawStrokeWithGradient, drawPlainStroke } from './marker';

const defaultBrushSettings = brushDefaults.neon || {};

export async function drawNeonStroke(stroke, context, params) {
  const {
    lineWidth,
    color,
    sets = {},
  } = stroke;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  const {
    neonSize = 20,
    opacity = 1,
  } = brushSettings;

  // const neonColor = color;
  const chromaColor = chroma(color);
  const originalAlpha = chromaColor.alpha();
  const middleAlphaColor = chromaColor.alpha(0.5);
  const neonColor = chromaColor.alpha(opacity).hex();
  // const neonColor = chromaColor.alpha(opacity).hex();

  // Создаем буферный канвас
  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  // Рисуем черную тень
  bufferCtx.save();
  bufferCtx.globalCompositeOperation = 'source-over';
  bufferCtx.shadowColor = neonColor;
  bufferCtx.shadowBlur = neonSize * 2;
  for (let i = 0; i < 3; i++) {
    await drawStrokeWithGradient({
      ...stroke, 
      lineWidth: lineWidth,
      color: originalAlpha < 0.5 ? middleAlphaColor : color,
    }, bufferCtx, brushSettings);
  }
  bufferCtx.restore();

  if (originalAlpha < 0.95) {

    // Рисуем внутреннюю часть, чтобы вычесть ее из обводки
    bufferCtx.globalCompositeOperation = 'destination-out';
    drawPlainStroke({
      ...stroke,
      lineWidth: lineWidth + 1,
      color: 'black',
    }, bufferCtx, brushSettings);

    // Рисуем основной штрих
    bufferCtx.globalCompositeOperation = 'source-over';
    drawStrokeWithGradient(stroke, bufferCtx, brushSettings)

  } 


  context.drawImage(bufferCanvas, 0, 0);

}
