// shapeSimplifier.js
import { ShapeType } from './shapeTypes.js';

export class ShapeSimplifier {
  static simplify(shapeData) {
    const { type } = shapeData;

    switch (type) {

      case ShapeType.LINE:
        return this.simplifyLine(shapeData);

      case ShapeType.CIRCLE:
        return this.simplifyCircle(shapeData);

      case ShapeType.POLYGON:
        return this.simplifyPolygon(shapeData);

      // case ShapeType.POLYLINE:
      //   return this.simplifyPolyline(shapeData);
        
      default:

        // Если не распознана ни как линия, ни как окружность, 
        // просто возвращаем, что есть
        return shapeData.points;
    }
  }

  /**
   * Упрощение "линии": оставляем только первую и последнюю точки.
   */
  static simplifyLine(shapeData) {
    if (!shapeData.points || shapeData.points.length < 2) {
      return shapeData; // нечего упрощать
    }
    return [shapeData.points[0], shapeData.points[shapeData.points.length - 1]];
  }

  /**
   * Упрощение "окружности": возвращаем центр и радиус.
   */
  static simplifyCircle(shapeData) {
    if (!('center' in shapeData) || !('radius' in shapeData)) {
      return shapeData; // нет данных для круга
    }


    const points = [];
    const numSteps = 60; // кол-во точек для сглаженности эллипса
    for (let i = 0; i < numSteps; i++) {
      const angle = (2 * Math.PI * i) / numSteps;
      const px = shapeData.center.x + shapeData.radius * Math.cos(angle);
      const py = shapeData.center.y + shapeData.radius * Math.sin(angle);
      points.push({ x: px, y: py });
    }
    // замкнём эллипс, повторив первую точку
    points.push(points[0]);
    return points;

  }


  // Многоугольник: возвращаем «угловые» точки (shapeData.corners)
  static simplifyPolygon(shapeData) {
    // В shapeData мы сохранили corners
    if (!shapeData.corners || shapeData.corners.length < 3) {
      return shapeData.points || [];
    }

    const corners = shapeData.corners.slice();
    if (corners.length > 0) { corners.push(corners[0]); }

    return corners;
  }

  // Многоугольник: возвращаем «угловые» точки (shapeData.corners)
  static simplifyPolyline(shapeData) {
    // В shapeData мы сохранили corners
    if (!shapeData.corners || shapeData.corners.length < 3) {
      return shapeData.points || [];
    }
    const corners = shapeData.corners.slice();
    return corners;
  }
}
