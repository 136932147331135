// spray.js
import { applyPixiBlur } from './pixi/pixiBlur';
import { brushDefaults } from './helpers/brushLoader';
import { drawStrokeWithGradient } from './marker';

const defaultBrushSettings = brushDefaults.spray || {};

export async function drawSprayStroke(stroke, context, softContext, { isApple }) {

  let {
    lineWidth,
    sets = {},
  } = stroke;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  const softness = brushSettings.softness || stroke.softness || 0;

  const softCanvas = softContext.canvas;
  const blurSize = lineWidth * softness;

  softContext.save();

  if (isApple) {

    softContext.fillStyle = "transparent";
    drawStrokeWithGradient(stroke, softContext, brushSettings);
    await applyPixiBlur(softCanvas, blurSize);

  } else {

    softContext.filter = `blur(${blurSize}px)`;
    // Рисуем штрих на временном канвасе
    drawStrokeWithGradient(stroke, softContext, brushSettings);

  }

  if (context) {
    context.drawImage(softCanvas, 0, 0);
    softContext.clearRect(0, 0, softCanvas.width, softCanvas.height);
  }
  softContext.restore();



}