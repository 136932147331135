// mathUtils.js
export const MathUtils = {
  // Расстояние между двумя точками
  distance(p1, p2) {
    return Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
  },

  // Расстояние от точки `p` до прямой, проходящей через точки `a` и `b`
  distancePointToLine(p, a, b) {
    // Формула площади треугольника: Area = 1/2 * |AB| * расстояние,
    // где Area = векторное произведение (AB x AP) / 2
    // => расстояние = 2*Area / |AB|
    const cross = Math.abs((b.x - a.x) * (a.y - p.y) - (a.x - p.x) * (b.y - a.y));
    const distAB = MathUtils.distance(a, b);
    return distAB === 0 ? 0 : cross / distAB;
  },

  // Возвращает центр по крайним точкам (minX, maxX, minY, maxY)
  // Это условный «центр», который будем считать центром окружности
  boundingCenter(points) {
    if (!points || points.length === 0) {
      return { x: 0, y: 0 };
    }
    
    let minX = Infinity, maxX = -Infinity;
    let minY = Infinity, maxY = -Infinity;

    for (const p of points) {
      if (p.x < minX) minX = p.x;
      if (p.x > maxX) maxX = p.x;
      if (p.y < minY) minY = p.y;
      if (p.y > maxY) maxY = p.y;
    }

    return {
      x: (minX + maxX) / 2,
      y: (minY + maxY) / 2
    };
  },

  // Находит угол (в радианах) между векторами p0->p1 и p1->p2
  angleBetweenPoints(p0, p1, p2) {
    // Вектор v1 = (p1 - p0)
    const v1x = p1.x - p0.x;
    const v1y = p1.y - p0.y;

    // Вектор v2 = (p2 - p1)
    const v2x = p2.x - p1.x;
    const v2y = p2.y - p1.y;

    // Скалярное произведение: v1•v2 = |v1| * |v2| * cos(θ)
    const dot = v1x * v2x + v1y * v2y;

    // Модуль (длина) вектора
    const mag1 = Math.sqrt(v1x * v1x + v1y * v1y);
    const mag2 = Math.sqrt(v2x * v2x + v2y * v2y);

    if (mag1 === 0 || mag2 === 0) {
      return 0; // Выраженный угол найти нельзя (точки совпадают)
    }

    let cosTheta = dot / (mag1 * mag2);
    // Чуть-чуть подстрахуемся от выхода за границы, 
    // если из-за округлений cosTheta вышел за [-1, 1].
    if (cosTheta > 1) cosTheta = 1;
    if (cosTheta < -1) cosTheta = -1;

    // Угол θ
    return Math.acos(cosTheta);
  },
};
