import './utils/custom-slider.css';
import './utils/add-scroll.css';
import './locales/i18n'
import React, { useEffect } from 'react';
import BoardProviders from './components/board/BoardProviders';
import Lobby from './components/lobby/Lobby';

import { applyThemeColors, applyAdditionalColors, applyAdditionalShades } from './utils/themeColors';

import { ContextMenuEmulator } from './utils/contextMenuEmulator';

const RootComponent = () => {

  useEffect(() => {
    const preventTouch = (e) => {
      if (e.target.closest('.touch-active')) { return; }
      if (e.target.matches('input[type="range"]')) { return; }
      if (e.target.closest('.premium-menu')) { return; }
      if (e.target.closest('.settings-menu')) { return; }
      e.preventDefault();
    };

    // const handleContextMenu = (event) => {
    //   event.preventDefault();
    // };

    document.addEventListener('touchmove', preventTouch, { passive: false });
    // document.addEventListener('contextmenu', handleContextMenu);

    // Очистка обработчика
    return () => {
      document.removeEventListener('touchmove', preventTouch)
      // document.removeEventListener('contextmenu', handleContextMenu);
    };

  }, []);

  // Initialize the ContextMenuEmulator
  useEffect(() => {
    const contextMenuEmulator = new ContextMenuEmulator({
      delay: 500,    // Adjust the delay as needed
      threshold: 10  // Adjust the threshold as needed
    });
    contextMenuEmulator.init();
    // Cleanup on unmount
    return () => {
      contextMenuEmulator.destroy();
    };
  }, []);

  return (
    <BoardProviders />
  );
};



function App() {

  // Применяем основные цвета темы
  applyThemeColors(window.Telegram?.WebApp?.themeParams || {});
  // Применяем дополнительные цвета для нужных CSS-переменных
  applyAdditionalShades('--tg-theme-secondary-bg-color', 1.5);
  applyAdditionalShades('--tg-theme-button-color', 1.2);

  const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
  const testing = REACT_APP_VERSION === 'dev' && true;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const startParam = urlParams.get('tgWebAppStartParam');

  return (
    <>
     {(startParam || testing) ?  (<RootComponent />)  : (<Lobby />) }
    </>
  );
}

export default App;
