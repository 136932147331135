// EraserMagnifier.js
import React, { useEffect, useRef } from 'react';
import chroma from 'chroma-js';
import { filterPosition } from '../Throttler/filterPosition.js'; // path to your hook

const EraserMagnifier = ({
  gameContext,
  brushContext,
  canvasContext,
  drawMethods,
}) => {
  const {
    canvasRef,
    tempCanvasRef,
    zoomFactor,

    isTouchDevice,
    mouseDetected,

    drawingPosition,

  } = canvasContext;

  const { color, lineWidth, activeTool } = brushContext;
  const { getCanvasPoint } = drawMethods;

  const position = filterPosition(drawingPosition, 'pickingPlace', 100);
  const canvasPosition = filterPosition(
    getCanvasPoint(position),
    'pickingCanvasPosition',
    100
  );

  const params = {
    magnifierOn: true,
    offsetY: -120,
    magnificationFactor: Math.max(1, zoomFactor < 4 ? zoomFactor : zoomFactor / 2),

    magnifierDiameter: 170,
    containerDiameter: 170, // magnifierDiameter + 2 * borderWidth
  };

  const magnifierCanvasRef = useRef(null);

  // Dimensions
  const magnifierDiameter = params.magnifierDiameter;
  const magnifierRadius = magnifierDiameter / 2;

  const borderWidth = 0.5; // Border width for the outer circle
  const containerDiameter = params.containerDiameter; // magnifierDiameter + 2 * borderWidth
  const containerRadius = containerDiameter / 2;

  useEffect(() => {
    if (
      !canvasRef.current ||
      !tempCanvasRef.current ||
      !magnifierCanvasRef.current
    )
      return;

    const mainCanvas = canvasRef.current;
    const tempCanvas = tempCanvasRef.current;
    const ctx = magnifierCanvasRef.current.getContext('2d');

    const sWidth = magnifierDiameter / params.magnificationFactor;
    const sHeight = magnifierDiameter / params.magnificationFactor;

    let sx = canvasPosition.x - sWidth / 2;
    let sy = canvasPosition.y - sHeight / 2;

    ctx.clearRect(0, 0, magnifierDiameter, magnifierDiameter);

    // Draw the magnified area from mainCanvas
    ctx.drawImage(
      mainCanvas,
      sx,
      sy,
      sWidth,
      sHeight,
      0,
      0,
      magnifierDiameter,
      magnifierDiameter
    );

    // Draw the magnified area from tempCanvas
    ctx.drawImage(
      tempCanvas,
      sx,
      sy,
      sWidth,
      sHeight,
      0,
      0,
      magnifierDiameter,
      magnifierDiameter
    );

    // Create a circular clipping path to ensure the magnifier is circular
    ctx.globalCompositeOperation = 'destination-in';
    ctx.beginPath();
    ctx.arc(
      magnifierRadius,
      magnifierRadius,
      magnifierRadius,
      0,
      Math.PI * 2,
      false
    );
    ctx.fill();
    ctx.globalCompositeOperation = 'source-over';

    if (activeTool === 'eraser') {
      // Draw the eraser circle
      const adjustedLineWidth = lineWidth * params.magnificationFactor;
      ctx.beginPath();
      ctx.arc(
        magnifierRadius,
        magnifierRadius,
        adjustedLineWidth / 2,
        0,
        Math.PI * 2
      );
      ctx.fillStyle = color;
      ctx.fill();

      // Determine the contrasting color for the border
      const brightness = chroma(color).get('lab.l'); // L component
      const borderColor = brightness > 50 ? 'black' : 'white';

      ctx.lineWidth = 0.3;
      ctx.strokeStyle = borderColor;
      ctx.stroke();
    }


  }, [
    canvasRef,
    tempCanvasRef,
    canvasPosition,
    lineWidth,
    color,
    params.magnificationFactor,
    magnifierDiameter,
    magnifierRadius,
  ]);

  // Style for the container div (shifted up by offsetY pixels)
  const containerStyle = {
    position: 'fixed',
    left: `${position.x - containerRadius}px`,
    top: `${position.y - containerRadius + params.offsetY}px`, // Shift up
    width: `${containerDiameter}px`,
    height: `${containerDiameter}px`,
    pointerEvents: 'none',
    zIndex: 200,
  };


  // Positioning the magnifier canvas inside the outer circle
  const magnifierCanvasStyle = {
    position: 'absolute',
    width: `${magnifierDiameter}px`,
    height: `${magnifierDiameter}px`,
    borderRadius: '50%',
    overflow: 'hidden',
    border: `${borderWidth}px solid rgba(0, 0, 0, 0.2)`,
  };

  return (
    <div style={containerStyle}>
      {params.magnifierOn ? (
        <canvas
          ref={magnifierCanvasRef}
          width={magnifierDiameter}
          height={magnifierDiameter}
          style={magnifierCanvasStyle}
        />
      ) : null}
    </div>
  );
};

export default EraserMagnifier;
