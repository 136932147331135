import React from 'react';
import { 
  FaFillDrip, FaTint, 
  FaSun, FaMoon,
  FaHotjar, FaToiletPaper,
  FaCog,
 } from 'react-icons/fa';
import {
  TbGrain, 
  TbContrast2Filled, TbContrast2Off,
  TbZoomIn, TbZoomOut,
  TbLineScan,
  TbTexture,
 } from 'react-icons/tb';
import { MdGridOn } from "react-icons/md";

import './BrushMenu.css';
import { useTranslation } from 'react-i18next';
import { createBetterClick } from '../../../utils/createBetterClick'

const effectIcons = {
  'filler': FaFillDrip,
  'blur': FaTint,
  'noise': TbGrain,
  'texture': TbTexture,
  'pixelate': MdGridOn,
  'lighten': FaSun,
  'darken': FaMoon,
  'saturate': FaHotjar,
  'desaturate': FaToiletPaper,
  'contrast': TbContrast2Filled,
  'decontrast': TbContrast2Off,
  'zoomin': TbZoomIn,
  'zoomout': TbZoomOut,
  // 'outline': TbLineScan,
};

function EffectMenu({
  gameContext,
  brushContext,
}) {
  const { t } = useTranslation();

  const {
    gameInfoRef,
    openMenu, closeMenu,
    premiumFeaturesRef,
    userSetsRef,
    delayPremiumMenu,
    saveUserSets,
    
    appleHaptic,
    androidHaptic,
  } = gameContext;

  const { 
    setEffectType,
    effectTypeRef,
    effectHistoryRef,
    setTheColorFun,
    colorRef,
    setSoftness,
    lastActionTimeRef,
   } = brushContext;

  const effects = [
    'filler',
    'blur',
    'noise',
    'texture',
    'pixelate',
    'lighten',
    'darken',
    'saturate',
    'desaturate',
    'contrast',
    'decontrast',
    'zoomin',
    'zoomout',
    // 'outline',
  ].map(key => ({
    key,
    label: t(`effect.${key}.name`),
    icon: effectIcons[key],
  }));

  const haveParameters = ['filler', 'pixelate', 'texture'];

  const handleOverlayClick = () => {
    if (lastActionTimeRef.current < Date.now() - 300) {
      closeMenu(); 
    }
  };

  const handleSwitchEffect = (newEffectType) => {
    if (lastActionTimeRef.current > Date.now() - 300) { return; }

    if (newEffectType !== 'filler' && 
      (!premiumFeaturesRef.current.includes('brushes') 
      || userSetsRef.current.premiumOff)
    ) {
      closeMenu(); 
      delayPremiumMenu('menu');
    } else {
      const previousEffect = effectTypeRef.current;


      
      if (previousEffect !== newEffectType) {
        appleHaptic('medium');
        androidHaptic();
      }
      effectTypeRef.current = newEffectType;
      setEffectType(newEffectType);

      effectHistoryRef.current.push(newEffectType);
      closeMenu(); 
      saveUserSets({ effectType: newEffectType });
    }
  };

  const handleOpenEffectSettings = (newEffectType, e) => {
    e?.preventDefault();
    e?.stopPropagation();
    openMenu('customizeBrush', { brush: newEffectType, effect: true, layer: true, backMenu: 'effect' });

    if (newEffectType !== 'filler' && 
      (!premiumFeaturesRef.current.includes('effects') 
      || userSetsRef.current.premiumOff)
    ) {
      return;
    }

    if (newEffectType !== 'common') {
      effectTypeRef.current = newEffectType;
      setEffectType(newEffectType);
      effectHistoryRef.current.push(newEffectType);
      saveUserSets({ effectType: newEffectType });
    }
  };

  return (
    <>
      <div className="overlay-background" onClick={handleOverlayClick}></div>
      <div className="effect-menu scroll-active touch-active">
        <span 
          className="brush-menu-title"
        >
          {t('effect.title')}
        </span>
        
        {effects.map((effect) => (
          <div 
            key={effect.key} 
            className={`brush-option-row ${effectTypeRef.current === effect.key ? 'active' : ''}`}

            // onClick={() => handleSwitchEffect(effect.key)}
            // onContextMenu={haveParameters.includes(effect.key) ? (e) => handleOpenEffectSettings(effect.key, e) : null}

            { ...createBetterClick({
              onClick: (e) => {handleSwitchEffect(effect.key)},
              onContextMenu: haveParameters.includes(effect.key) ? (e) => handleOpenEffectSettings(effect.key, e) : null,
            }) }
            
          >
            <label className="brush-label">
              <effect.icon className='brush-icon'/>
              <span> {effect.label}</span>
              {haveParameters.includes(effect.key) && effectTypeRef.current === effect.key && (
                <FaCog
                  className="brush-settings-icon"
                  { ...createBetterClick({
                    onClick: (e) => {handleOpenEffectSettings(effect.key, e);},
                    onContextMenu: (e) => {handleOpenEffectSettings(effect.key, e);},
                  }) }
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  //   handleOpenEffectSettings(effect.key);
                  // }}
                />
              )}
            </label>
          </div>
        ))}
      </div>
    </>
  );
}

export default EffectMenu;